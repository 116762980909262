import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

import { isUserAdmin, isUserRoot } from '../../utils/permissionGroups';
import { CloudFunctionsService } from './cloud-functions.service';
import { Observable, Subject } from 'rxjs';
import { User } from 'firebase';
import { merge } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';




@Injectable({
    providedIn: 'root'
})
export class UserService {


    private subjectUser: Subject<User | null> = new Subject();

    constructor(private cloud: CloudFunctionsService,
        private fAuth: AngularFireAuth,
        private storage: AngularFireStorage) {

    }

    public async signIn(email: string, password: string) {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const response = await firebase.auth().signInWithEmailAndPassword(email, password);
        return response.user;
    }

    public async signInAnonymously() {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
        const response = await firebase.auth().signInAnonymously();
        return response.user;
    }

    public async signInFacebook() {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.setCustomParameters({
            'display': 'popup'
        });
        const response = await firebase.auth().signInWithPopup(provider);
        await this.createDBUserModel(response.user.uid, response.user, true, false);
        return response.user;
    }
    public async signInGoogle() {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const provider = new firebase.auth.GoogleAuthProvider();
        const response = await firebase.auth().signInWithPopup(provider);
        await this.createDBUserModel(response.user.uid, response.user, true, false);
        return response.user;
    }
    public async addUserName(username: string) {
        const user = this.getCurrentUser();
        await user.updateProfile({ displayName: username, photoURL: user.photoURL });
        await this.updateUsername(user.uid, username);
        return user;
    }

    public async changePassword(currentPassword: string, newPassword: string) {
        let user = this.getCurrentUser();
        const cred = await firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        await user.reauthenticateAndRetrieveDataWithCredential(cred);
        user = this.getCurrentUser();
        await user.updatePassword(newPassword);
    }

    public async signInArag(tokenArag: string) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const token = await this.cloud.loginArag(tokenArag);
        const credentials = await firebase.auth().signInWithCustomToken(token);
        await this.createDBUserModel(credentials.user.uid, credentials.user, true, false);
        return credentials.user;
    }

    public async signInAlmudena(tokenArag: string) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const token = await this.cloud.loginAlmudena(tokenArag);
        const credentials = await firebase.auth().signInWithCustomToken(token);
        await this.createDBUserModel(credentials.user.uid, credentials.user, true, false);
        return credentials.user;
    }

    public async signInPimec(tokenUrl: string, email: string, nif: string) {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        const token = await this.cloud.loginPimec(tokenUrl, email, nif);
        const credentials = await firebase.auth().signInWithCustomToken(token);
        await this.createDBUserModel(credentials.user.uid, credentials.user, true, false);
        return credentials.user;
    }



    public async getPlatformIdByName(platformName: string) {
        const platformId = await this.cloud.getPlatformIdByName(platformName);
        return platformId;
    }

    /**
     * @return email
     */
    public async verifyPasswordResetCode(actionCode: string): Promise<string> {
        const auth = firebase.auth();
        return await auth.verifyPasswordResetCode(actionCode);
    }

    public async confirmPasswordReset(actionCode: string, newPassword: string): Promise<void> {
        const auth = firebase.auth();
        return await auth.confirmPasswordReset(actionCode, newPassword);
    }

    public async applyActionCode(actionCode: string): Promise<void> {
        const auth = firebase.auth();
        return await auth.applyActionCode(actionCode);
    }

    public async verifyEmail(actionCode: string): Promise<void> {
        await firebase.auth().applyActionCode(actionCode);
        // const user = this.getCurrentUser();
        // this.subjectUser.next(user);
    }

    public async sendEmailVerification(): Promise<void> {
        return await this.cloud.sendEmailVerification();
    }

    public async signUp(nickname: string, email: string, password: string, acceptsPrivacyTerms: boolean, acceptsAdvertise: boolean) {
        if (!acceptsPrivacyTerms) {
            throw {
                errors: ['Accept terms of service.']
            };
        }
        const user = await firebase.auth().createUserWithEmailAndPassword(email, password);
        const user2 = await this.addUserName(nickname);
        await this.createDBUserModel(user2.uid, user2, acceptsPrivacyTerms, acceptsAdvertise);
        return user2;
    }

    private async updateUsername(uid: string, username: string) {
        firebase.database().ref('users/' + uid + '/info/username').set(username);
    }

    private async createDBUserModel(uid: string, user, acceptsPrivacyTerms: boolean, acceptsAdvertise: boolean) {
        let info;
        const body = {
            accepts_advertise: acceptsAdvertise,
            accepts_terms: acceptsPrivacyTerms
        };
        if (user.displayName) {
            info = {};
            info['username'] = user.displayName;
        }
        if (user.email) {
            info = info ? info : {};
            info['email'] = user.email;
        }
        if (info) {
            body['info'] = info;
        }
        const keys = Object.keys(body);
        for (let i = 0; i < keys.length; i++) {
            firebase.database().ref('users/' + uid + '/' + keys[i]).set(body[keys[i]]);
        }

    }

    public async signOut() {
        return await firebase.auth().signOut();
    }

    public async resetPassword(email: string) {
        await firebase.auth().sendPasswordResetEmail(email);
    }

    public getCurrentUser() {
        return firebase.auth().currentUser;
    }

    public getCurrentUserObs(): Observable<User | null> {
        return this.fAuth.authState.pipe(merge(this.subjectUser.asObservable()));
    }

    public async activeAdminCredentials() {
        const token = await this.cloud.getAdminToken();
        await firebase.auth().signInWithCustomToken(token);
    }

    public async activePlatformManagerCredentials() {
        const token = await this.cloud.getPlatformManagerToken();
        await firebase.auth().signInWithCustomToken(token);
    }

    public async isAdminCurrentUser(): Promise<boolean> {
        const user = this.getCurrentUser();
        if (user) {
            return await isUserAdmin(user.uid, false);
        } else {
            return false;
        }
    }
    public async isRootCurrentUser(): Promise<boolean> {
        const user = this.getCurrentUser();
        if (user) {
            return await isUserRoot(user.uid, false);
        } else {
            return false;
        }
    }

    public async updateProfile(name: string, url: string, blob?: Blob) {
        const user = this.getCurrentUser();
        if (blob) {
            await this.storage.ref(`/frontend/users/${user.uid}/profilePicture.jpg`).put(blob, {
                contentType: 'image/jpeg'
            });
            url = await firebase.storage().ref(`/frontend/users/${user.uid}/profilePicture.jpg`).getDownloadURL();
        }

        await user.updateProfile({
            displayName: name,
            photoURL: url
        });
    }
    public async startRemoveAccount(email?: string) {
        await this.cloud.startRemoveAccount(email);
    }

    public async completeRemoveAccount(emailCode: string) {
        await this.cloud.removeAccount(emailCode);
    }

    public async setExternalToken(token: string) {
        await firebase.auth().signInWithCustomToken(token);
    }

    // region platform login
    public async platformLogin(email: string, password: string) {
        const token = await this.cloud.loginPlatform(email, password);
        await firebase.auth().signInWithCustomToken(token);
    }

    public async sendResetPasswordPlatform(email: string) {
        await this.cloud.sendRecoveryPassword(email);
    }

    public async completeResetPasswordPlatform(emailCode: string, newPassword: string) {
        await this.cloud.completeRecoveryPassword(emailCode, newPassword);
    }

    public async changePasswordPlatform(currentPassword: string, newPassword: string) {
        await this.cloud.changePassword(currentPassword, newPassword);
    }

    public async sendEmailVerificationPlatform() {
        await this.cloud.sendEmailVerification();
    }

    public async completeEmailVerificationPlatform(emailCode: string) {
        await this.cloud.completeEmailVerification(emailCode);
    }
    // endregion

}
