import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {UserService} from '../shared/user.service';
import {ChatMessage} from 'milcontratos-database';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ChatInterface, GroupMessage} from 'milcontratos-database';
import {AngularFireDatabase} from '@angular/fire/database';




@Injectable({
    providedIn: 'root'
})
export class ChatAdminService {

    private adminChatGeneralSuscriber;

    constructor(private cloud: CloudFunctionsService,
                private auth: UserService,
                private dbObs: AngularFireDatabase) {
    }

    public async startAdminChat() {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('chat/admin_assignation/' + user.uid + '/' + user.uid).set(true);
    }

    public async setFCMToken(token: string) {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('chat/admin_fcm/' + user.uid ).set(token);
    }

    public async endAdminChat() {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('chat/admin_assignation/' + user.uid ).set(null);
        await firebase.database().ref('chat/admin_fcm/' + user.uid ).set(null);
    }

    public getIsClientTypingObservable(userId: string): Observable<boolean> {
        const res: Observable<any> = this.dbObs.object('chat/room/' + userId + '/isClientTyping' ).snapshotChanges();
        return res;
    }

    public async setIsAdminTyping(typing: boolean, userId: string) {
        await firebase.database().ref('chat/room/' + userId + '/isAdminTyping' ).set(typing);
    }

    public getAssignedObservable(): Observable<string[]> {
        const user = this.auth.getCurrentUser();
        return this.dbObs.object('chat/admin_assignation/' + user.uid ).snapshotChanges()
            .pipe(map(value => {
                return Object.keys(value.payload.val());
                }),
                map(array => {
                    const index = array.indexOf(user.uid);
                    if (index > -1) {
                        array.splice(index, 1);
                    }
                    return array;
                } ));
    }

    public getChatObservable(userId: string): Observable<GroupMessage> {
        return this.dbObs.list('chat/room/' + userId + '/messages', ref => ref.limitToLast(1)).stateChanges()
            .pipe(filter( value => value.type === 'child_added'),
                map(value => {
                    const msg = ChatMessage.fromJSON(value.payload.val());
                    if (msg.isFromClient) {
                        firebase.database().ref('chat/room/' + userId + '/messages/' + value.key + '/receivedMessage').set(true);
                    }
                    // FIXME in a future to do it right
                    const grMsg = new GroupMessage();
                    grMsg.timestampStart = msg.timestamp;
                    grMsg.timestampEnd = msg.timestamp;
                    grMsg.isFromClient = msg.isFromClient;
                    grMsg.text = [msg.text];
                    return grMsg;
            }));

    }
    public async getPaginateChat(userId: string, numMessages: number, cursor?: any): Promise<ChatInterface> {
        const ref = firebase.database().ref('chat/room/' + userId + '/messages');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByChild('timestamp').endAt(cursor).limitToLast(numMessages + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('timestamp').limitToLast(numMessages).once('value');
        }

        const feed: GroupMessage[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = newCursor === cursor ? snpsht.val()['timestamp'] : newCursor;
            const msg = ChatMessage.fromJSON(snpsht.val());
            // FIXME in a future do it right
            const grMsg = new GroupMessage();
            grMsg.timestampStart = msg.timestamp;
            grMsg.timestampEnd = msg.timestamp;
            grMsg.isFromClient = msg.isFromClient;
            grMsg.text = [msg.text];
            feed.push(grMsg);
        });
        return {
            cursor: newCursor,
            messages: cursor ?  feed.slice(0, feed.length - 1) : feed
        };
    }

    public async sendMessageChat(userId: string, message: string) {
        const user = this.auth.getCurrentUser();
        const msg = new ChatMessage();
        msg.text = message;
        msg.isFromClient = false;
        msg.receivedMessage = true;
        msg.timestamp = new Date();
        msg.senderId = user.uid;
        const msg_json = msg.toJSON();
        await firebase.database().ref('chat/room/' + userId + '/messages' ).push(msg_json);
        await firebase.database().ref('chat/room/' + userId + '/lastUpdate' ).set(msg_json['timestamp']);
    }

    public async endChat(userId: string) {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('chat/admin_assignation/' + user.uid + '/' + userId).set(null);
    }


}
