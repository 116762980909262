import {Injectable} from '@angular/core';
import {Deal, DocType, getDealPlatfrom, hasUserAppliedDeal, ItemType} from 'milcontratos-database';
import {UserService} from '../shared/user.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import {listsHasOneCoincidentType} from 'milcontratos-database';


@Injectable({
    providedIn: 'root'
})
export class DealService {

    constructor(
        private env: EnvironmentBaseService,
        private userService: UserService) {
    }

    async getDeal(text: string): Promise<Deal> {
        return await getDealPlatfrom(this.env.platformId(), text);
    }

    async isDealUsed(text: string): Promise<boolean> {
        const user = this.userService.getCurrentUser();
        return await hasUserAppliedDeal(user.uid, text);
    }

    async getUserDocumentDeal(text: string): Promise<Deal>  {
        const deal = await getDealPlatfrom(this.env.platformId(), text);
        if (!deal) {
            throw {
                errors: ['Not existent deal']
            };
        }
        if (deal.itemType !== ItemType.document) {
            throw {
                errors: ['Not valid deal']
            };
        }
        if (deal.expires && deal.expireDate.getTime()  < (new Date()).getTime()) {
            throw {
                errors: ['Deal expired']
            };
        }
        const used = await this.isDealUsed(text);
        if (used) {
            throw {
                errors: ['Used yet']
            };
        }
        return deal;
    }

    async getUserPackDeal(text: string): Promise<Deal> {
        const deal = await getDealPlatfrom(this.env.platformId(), text);
        if (!deal) {
            throw {
                errors: ['Not existent deal']
            };
        }
        if (deal.itemType !== ItemType.pack) {
            throw {
                errors: ['Not valid deal']
            };
        }

        if (deal.expires && deal.expireDate.getTime()  < (new Date()).getTime()) {
            throw {
                errors: ['Deal expired']
            };
        }

        const used = await this.isDealUsed(text);
        if (used) {
            throw {
                errors: ['Used yet']
            };
        }
        return deal;
    }

    isValidDealForTypes(deal: Deal, documentTypes: DocType[]): boolean {
        if (deal.includeAll) {
            return true;
        }
        return listsHasOneCoincidentType(documentTypes, deal.typesIncluded ? deal.typesIncluded : []);

    }

    isValidDealForPackId(deal: Deal, packId: string): boolean {
        if (deal.includeAll) {
            return true;
        }
        return deal.packsId ? deal.packsId.indexOf(packId) >= 0 : false;

    }

}
