import {Injectable} from '@angular/core';
import paypal from 'paypal-checkout';
import {UserService} from '../shared/user.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import * as qs from 'querystring';
import * as firebase from 'firebase';
import {DocumentPack, PurchasedSubscription, SubscriptionPlan, SubscriptionState} from 'milcontratos-database';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {AngularFireDatabase} from '@angular/fire/database';

export interface RedsysParameters {
    Ds_SignatureVersion: string;
    Ds_MerchantParameters: string;
    Ds_Signature: string;
}

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {

    constructor(private auth: UserService,
                private dbObs: AngularFireDatabase,
                private env: EnvironmentBaseService,
                private cloud: CloudFunctionsService) {
    }

    public async getSubscriptions(): Promise<SubscriptionPlan[]> {
        const snapshot = await firebase.database().ref('subscriptions')
            .orderByChild('order_key').once('value');
        const result: SubscriptionPlan[] = [];
        const json = snapshot.forEach(snpsht => {
            result.push(SubscriptionPlan.fromJSON(snpsht.val()));
        });
       return result;
    }

    public getAllSubscriptionsObs(): Observable<SubscriptionPlan[]> {
        return this.dbObs.object('subscriptions').snapshotChanges().pipe(map((snap) => {
            const p = snap.payload.val();
            if (!p) {
                return [];
            }
            const subscriptions = Object.keys(p).map((id) => {
                return SubscriptionPlan.fromJSON(p[id]);
            });
            return subscriptions.sort((a: SubscriptionPlan, b: SubscriptionPlan) =>
                (a.orderKey > b.orderKey) ? 1 : ((a.orderKey < b.orderKey) ? -1 : 0));
        }));
    }

    public async getSingleSubscription(subscriptionId: string): Promise<SubscriptionPlan> {
        const snapshot = await firebase.database().ref(`subscriptions/${subscriptionId}`).once('value');
        if (!snapshot.val()) {
            return snapshot.val();
        }
        return SubscriptionPlan.fromJSON(snapshot.val());
    }

    public async getPurchasedSubscription(): Promise<PurchasedSubscription> {
        const userObj = await this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref(`users/${userObj.uid}/subscription_plan`).once('value');
        if (!snapshot.val()) {
            return snapshot.val();
        }
        return PurchasedSubscription.fromJSON(snapshot.val());
    }

    public async getSubscriptionState(): Promise<SubscriptionState> {
        const userObj = await this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref(`subscriptions_state/${userObj.uid}`).once('value');
        if (!snapshot.val()) {
            return snapshot.val();
        }
        return SubscriptionState.fromJSON(snapshot.val());
    }

    async getStartPaypalInfo(subscriptionId: string, isYearly: boolean, callback: (err: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientSubscriptionAddToSubscription`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function () {

            // Set up a url on your server to create the payment
            const CREATE_URL = url;

            const body = {
                subscription_id: subscriptionId,
                is_yearly: !!isYearly,
                token: token,
                platform_id: platformId
            };
            // Make a call to your server to set up the payment
            return paypal.request.post(CREATE_URL, body)
                .then(function (res) {
                    // const paymentToken = qs.parse(res['links'][0]['href'].split('?')[1])['token'];
                    return res['payment_token'];
                }).catch(callback);
        };
    }



    public async getCardSubscription(subscriptionId: string, isYearly: boolean): Promise<RedsysParameters> {
        return await this.cloud.redsysSubscription(subscriptionId, isYearly);
    }

    public async getIVA(): Promise<number> {
        const snapshot = await firebase.database().ref('iva/subscription').once('value');
        const iva = snapshot.val();
        return iva;
    }

    public async cancelSubscription() {
        await this.cloud.cancelSubscription();
    }

    async getCompletePaypal(callback: (err: any, response: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientSubscriptionExecutePaypal`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function (data, actions) {
            // Set up a url on your server to create the payment
            const EXECUTE_URL = url;

            // Make a call to your server to set up the payment
            const body: any = {
                payment_token: data.paymentToken,
                token: token,
                platform_id: platformId
            };


            // Make a call to your server to execute the payment
            return paypal.request.post(EXECUTE_URL, body)
                .then((res) => {
                    actions.close();
                    callback(undefined, res);
                }).catch((res) => {
                    // actions.close();
                    callback(res, undefined);
                });
        };
    }
}
