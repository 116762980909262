import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FieldErrorService} from '../../../services/client/field-error.service';

@Component({
    selector: 'app-number-field',
    template: `<label class="input-label">{{field.caption}} <span *ngIf="field.description" class="info-icon"><i class="material-icons">info</i></span>
    <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
</label>
<input autocomplete="off" type="text" class="input-text" [placeholder]="''"
       [(ngModel)]="number" (blur)="sendInput()" (change)="sendInput()"/>
<small class="input-error" *ngIf="error">Ãs necessari omplir aquest camp</small>
<small class="input-error" *ngIf="errorNumber">El camp ha de contenir un nÃºmero</small>
`,
    styles: [`.info-icon{position:relative;top:8px}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}.input-label:hover .hover-field{display:block;min-width:300px}.input-label{position:relative}.input-error{font-weight:700;font-size:10px;letter-spacing:.1vw;line-height:1.70758vh;color:red!important}@media (max-width:767.98px){.input-label{font-size:14px}}`]
})
export class NumberFieldComponent implements OnInit, OnDestroy, OnChanges {

    @Input() field: any;
    @Input() docField: string;
    @Output() sendText: EventEmitter<string> = new EventEmitter();

    text: string = '';
    number: string = '';
    error: boolean = false;
    errorNumber: boolean = false;

    private fieldErrorSubscription: Subscription;

    constructor(private fieldError: FieldErrorService) {
    }

    ngOnInit() {
        this.fieldErrorSubscription = this.fieldError.errorIdsObservable().subscribe(res => {
            this.error = res.indexOf(this.field.id, 0) !== -1;
        });
        this.number = this.docField;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('docField' in changes) {
            this.number = this.docField.toString();
        }
    }

    ngOnDestroy() {
        if (this.fieldErrorSubscription) {
            this.fieldErrorSubscription.unsubscribe();
        }
    }

    sendInput() {
        const regexNumber = /^(\+|-)?[0-9]+$/;
        if (!regexNumber.test(this.number)) {
            this.errorNumber = true;
        } else {
            this.errorNumber = false;
            this.sendText.emit(this.number);
        }


        // if (isNaN(parseInt(this.number, 10))) {
        //     this.errorNumber = true;
        // } else {
        //     this.errorNumber = false;
        //     this.sendText.emit(parseInt(this.number, 10));
        // }
    }

}
