import {Injectable} from '@angular/core';
import * as firebase from 'firebase';

import {FilledDocument, FilledFeedInterface, RenderStatus} from 'milcontratos-database';
import {CompletedTransaction, CompletedTransactionFeed, InvoiceDetails} from 'milcontratos-database';
import {UserService} from '../shared/user.service';
import {removeEmpty} from '../../utils/utils';
import {InvoiceJob} from 'milcontratos-database/dist/models/invoices/InvoiceJob';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {AngularFireDatabase} from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class BillingUserService {

    constructor(
        private auth: UserService,
        private cloud: CloudFunctionsService,
        private dbObs: AngularFireDatabase,
    ) {}

    public async getPaginateBillableTransactions( numTransactions: number, cursor?: any): Promise<CompletedTransactionFeed> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('billable_transactions/' + user.uid );
        return await this.getTransactionGeneric(ref, numTransactions, cursor);
    }
    public async getAllPaginateTransactions( numTransactions: number, cursor?: any): Promise<CompletedTransactionFeed> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('completed_transactions/' + user.uid );
        return await this.getTransactionGeneric(ref, numTransactions, cursor);
    }

    public async getInvoiceDetails(): Promise<InvoiceDetails> {
        const user = this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref('invoice_details/' + user.uid ).once('value');
        return InvoiceDetails.fromJSON(snapshot.val());
    }

    public async setInvoiceDetails(details: InvoiceDetails): Promise<void> {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('invoice_details/' + user.uid ).set(removeEmpty(details.toJSON()));
    }

    public async generateInvoice(transactionId: string, details: InvoiceDetails): Promise<void> {
        await this.setInvoiceDetails(details);
        await this.cloud.setTransactionInvoiceDetails(transactionId, details.toJSON());
    }


    private async getTransactionGeneric(ref: any, numTransactions: number, cursor?: any): Promise<CompletedTransactionFeed> {
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByChild('date').startAt(cursor).limitToFirst(numTransactions + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('date').limitToFirst(numTransactions).once('value');
        }
        let newCursor = cursor;
        const feed: CompletedTransaction[] = [];
        snapshot.forEach(snpsht => {
            newCursor = snpsht.val()['date'];
            feed.push(CompletedTransaction.fromJSON(snpsht.val()));
        });

        return {
            cursor: newCursor,
            transactions: cursor ? feed.slice(1, numTransactions + 1) : feed
        };
    }

    /**
     * Returns Path of the redered invoice in storage
     */
    public async renderInvoice(transactionId: string, userId: string): Promise<string> {

        return new Promise<string>(async (resolve, reject) => {
            let jobId;
            try {
                jobId = await this.cloud.clientRenderInvoice(transactionId);
            } catch (e) {
                reject(e);
            }
            const itemRef = this.dbObs.object('invoice_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        resolve(InvoiceJob.fromJSON(item).invoicePath);
                        break;

                    case RenderStatus.Error:
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }
}
