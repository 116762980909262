import * as firebase from 'firebase';


export async function isUserAdmin(uid: string, throwForbiddenError?): Promise<boolean> {
    const snapshot = await firebase.database().ref('users/' + uid + '/groups').once('value');
    const isAdmin = !!snapshot.val() || isUserRoot(uid, false);
    if (throwForbiddenError && !isAdmin) {
        throw {
            code: 403,
            reason: 'forbidden',
            message: 'Token not valid'
        };
    }
    return isAdmin;
}

export async function isUserRoot(uid: string, throwForbiddenError?): Promise<boolean> {
    const snapshot = await firebase.database().ref('users/' + uid + '/is_root').once('value');
    const isAdmin = !!snapshot.val();
    if (throwForbiddenError && !isAdmin) {
        throw {
            code: 403,
            reason: 'forbidden',
            message: 'Token not valid'
        };
    }
    return isAdmin;
}


