import * as firebase from 'firebase';


/**
 * Generic get call to Google Cloud Functions.
 */
export async function getCall(url, platformId?: string, notLoginNeeded?) {
    let headers;
    if (notLoginNeeded) {
        headers = new Headers({
            'Content-Type': 'application/json'
        });
    } else {
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken()}`
        });
    }
    const httpOptions: any = {
        method: 'GET',
        mode: 'cors',
        headers: headers
    };

    const response = await fetch(url, httpOptions);
    if (!response.ok) {
        throw (await response.json())['message'];
    }
    return await response.json();
}


/**
 * Generic post call to Google Cloud Functions.
 */
export async function postCall(url, body?, platformId?: string, notLoginNeeded?) {
    if (!body) {
        body = {};
    }
    let headers;
    if (notLoginNeeded) {
        headers = new Headers({
            'Content-Type': 'application/json'
        });
    } else {
        headers = new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken()}`
        });
    }
    body['platform_id'] = platformId;
    const httpOptions: any = {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(body),
        headers: headers
    };

    const response = await fetch(url, httpOptions);
    if (!response.ok) {
        const json = await response.json();
        if (json.code === 401) {
            firebase.auth().signOut();
        }
        throw (json)['message'];
    }
    return await response.json();
}
