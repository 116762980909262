import {ExpertQuestionNode, ExpertResponseNode} from 'milcontratos-database';
import * as fastXmlParser from 'fast-xml-parser';
import jsontoxml from 'jsontoxml';
import {hasDatabaseForbbidenCharacter} from './utils';
import {DirectoryJSON, DirectoryNode} from 'milcontratos-database';

export async function fromSpanishXMLToJSONDirectory(xml: string): Promise<DirectoryNode> {
    const options = {
        attributeNamePrefix: '',
        attrNodeName: 'attr', // default is 'false'
        textNodeName: '#text',
        ignoreAttributes: false,
        ignoreNameSpace: false,
        allowBooleanAttributes: false,
        parseNodeValue: true,
        parseAttributeValue: false,
        trimValues: true,
        cdataTagName: '__cdata',
        cdataPositionChar: '\\c',
        localeRange: ''
    };
    const isValid = fastXmlParser.validate(xml);
    if (isValid === true) {
        const errors: string[] = [];
        const objParsed = fastXmlParser.parse(xml, options);
        const result = await recursiveDirectoryXMLParsedToObj(objParsed['directorio'], errors);
        if (errors.length > 0) {
            throw {
                errors: errors
            };
        }
        return result;
    } else {
        throw {
            errors: [isValid.err.msg]
        };
    }
}

export async function fromJSONToSpanishXML(directory: DirectoryNode): Promise<string> {
    const toXml = await recursiveDirectoryJSONToXMLJSON(await directory.toJSON());
    return jsontoxml([toXml], {prettyPrint: true, indent: ' '});
}

async function recursiveDirectoryJSONToXMLJSON(json: DirectoryJSON) {
    const newJson = {};
    newJson['name'] = 'directorio';
    newJson['attrs'] = {'nombre': json.name};
    if (json.directories && json.directories.length > 0) {
        newJson['children'] = {
            'directorios': await Promise.all(json.directories.map(async (resp) => {
                return await recursiveDirectoryJSONToXMLJSON(resp);
            }))
        };
    }
    if (json['documents'] && json['documents'].length > 0) {
        newJson['children'] = [{
            'documentos': await Promise.all(json['documents'].map(async (doc) => {
                return {'name': 'documento', 'text': doc};
            }))
        }];
    }
    return newJson;

}


async function recursiveDirectoryXMLParsedToObj(json, errors: string[]): Promise<DirectoryNode> {
    const directory = new DirectoryNode();
    if (hasDatabaseForbbidenCharacter(json['attr']['nombre'])) {
        errors.push(`Caracter no permitido ".", "#", "$", "/", "[", or "]" en: ${json['attr']['nombre']}`);
    }
    directory.name = json['attr']['nombre'];
    if (( json['attr']['icono'] && !json['attr']['nombre_icono'] ) || ( !json['attr']['icono'] && json['attr']['nombre_icono'] )) {
        errors.push(`Deben existri un icono con un nombre de icono en ${directory.name}`);
    } else if (json['attr']['icono'] && json['attr']['nombre_icono']) {
        if (hasDatabaseForbbidenCharacter(json['attr']['icono'])) {
            errors.push(`Caracter no permitido ".", "#", "$", "/", "[", or "]" en: ${json['attr']['icono']}`);
        } else if (hasDatabaseForbbidenCharacter(json['attr']['nombre_icono'])) {
            errors.push(`Caracter no permitido ".", "#", "$", "/", "[", or "]" en: ${json['attr']['nombre_icono']}`);
        } else {
            directory.iconName = json['attr']['nombre_icono'];
            directory.iconReference = json['attr']['icono'];
        }
    }
    if (json['directorios'] && json['directorios']['directorio']) {
        if (json['directorios']['directorio']['attr']) {
            directory.directories = [ await recursiveDirectoryXMLParsedToObj(json['directorios']['directorio'], errors)];
        } else {
            const result: any = await Promise.all( json['directorios']['directorio'].map( async (res) => {
                return await recursiveDirectoryXMLParsedToObj(res, errors);
            }));
            directory.directories = result;
        }
    }
    if (json['documentos'] && json['documentos']['documento']) {
        if (Array.isArray(json['documentos']['documento'])) {
            directory.documents = json['documentos']['documento'];
        } else {
            directory.documents = [json['documentos']['documento']];
        }
    }
    return directory;
}

