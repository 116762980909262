import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DocumentTreeNode, FillDocumentService} from '../../../../services/client/fill-document.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'mc-fill-repeatable',
    template: `<div *ngFor="let repeatIndex of repetitions; let lastBlock = last"
     [ngClass]="{'block-style': !lastBlock, 'block-no-style': lastBlock}">
    <div *ngFor="let child of node.children; trackBy:trackByFn">
        <mc-fill-field *ngIf="child.isField()" [node]="child" [repeatIndex]="repeatIndex"></mc-fill-field>
        <mc-fill-repeatable *ngIf="child.isRepeatable()" [node]="child"></mc-fill-repeatable>
    </div>
</div>
`,
    styles: [`.block-style{border-bottom:3px dotted #4c52c1;margin-bottom:40px;padding-bottom:20px}.block-no-style{border-bottom:none;margin-bottom:10px;padding-bottom:0}`]
})
export class FillRepeatableComponent implements OnInit, OnChanges, OnDestroy {

    @Input() node: DocumentTreeNode;

    repetitions: number[];

    fillDocumentServiceSubscription: Subscription;

    constructor(private fillDocumentService: FillDocumentService) {
        this.fillDocumentServiceSubscription = this.fillDocumentService.filledDocumentObservable().subscribe(() => {
            this.recomputeState();
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('node' in changes) {
            this.recomputeState();
        }
    }

    ngOnDestroy() {
        if (this.fillDocumentServiceSubscription) {
            this.fillDocumentServiceSubscription.unsubscribe();
        }
    }

    trackByFn(index: number, node: DocumentTreeNode): string {
        return node.id;
    }

    private recomputeState() {
        this.repetitions = [...new Array(this.fillDocumentService.getBlockRepetitions(this.node.id))].map((_, i) => i);
    }

}
