import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

@Component({
    selector: 'mcs-doc-page',
    template: `<div class="page">
    <div #pageHeader class="page-header"></div>
    <div #pageBody class="page-body"></div>
    <div #pageFooter class="page-footer"></div>
</div>
`,
    styles: [`@import url(https://fonts.googleapis.com/css?family=Roboto);.page{width:210mm;height:297mm;padding:1.7cm 1.5cm;margin-bottom:20px;background-color:#fff;font-family:Roboto,sans-serif;box-shadow:0 0 20px 0 #777}`]
})
export class DocPageComponent implements OnInit, OnChanges {
    @Input() headerHtml: string;
    @Input() bodyHtml: string;
    @Input() footerHtml: string;

    @ViewChild('pageHeader') pageHeader: ElementRef;
    @ViewChild('pageBody') pageBody: ElementRef;
    @ViewChild('pageFooter') pageFooter: ElementRef;

    constructor() {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('headerHtml' in changes) {
            this.pageHeader.nativeElement.innerHTML = this.headerHtml;
        }
        if ('bodyHtml' in changes) {
            this.pageBody.nativeElement.innerHTML = this.bodyHtml;
        }
        if ('footerHtml' in changes) {
            this.pageFooter.nativeElement.innerHTML = this.footerHtml;
        }
    }
}
