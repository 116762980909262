import {Injectable} from '@angular/core';

import * as firebase from 'firebase';
import {Observable} from 'rxjs';
import paypal from 'paypal-checkout';
import {map} from 'rxjs/operators';

import {DocumentsService} from './documents.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import {UserService} from '../shared/user.service';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {OwnedDocument, Render, RenderJob, RenderStatus} from 'milcontratos-database';
import {FeedInterface, Languages, languageToString, PublicLegalDocument} from 'milcontratos-database';
import {FillDocumentState, FilledDocument, FilledFeedInterface} from 'milcontratos-database';
import {SubscriptionService} from './subscription.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireDatabase} from '@angular/fire/database';


@Injectable({
    providedIn: 'root'
})
export class OwnedDocumentsService {

    constructor(
        private angularFireStorage: AngularFireStorage,
        private auth: UserService,
        private subscriptionService: SubscriptionService,
        private cloud: CloudFunctionsService,
        private docService: DocumentsService,
        private dbObs: AngularFireDatabase,
        private env: EnvironmentBaseService
    ) {}

    async newFilledDocument(documentId: string): Promise<string> {
        const id =  await this.cloud.createFillDocument(documentId);
        return id;
    }

    async removeFilledDocument(filledDocumentId: string, documentId: string): Promise<void> {
        this.cloud.removeFillingDocument(documentId, filledDocumentId);
    }

    async setValuesFilled(filledDocumentId: string, values: {[id: string]: any}) {
        const user = this.auth.getCurrentUser();
        await this.isFilledDocumentEditable(user.uid, filledDocumentId);
        await firebase.database().ref('filled_documents/' + user.uid + '/' + filledDocumentId + '/values')
            .set(values);
        await firebase.database().ref('filling_filled_documents/' + user.uid + '/' + filledDocumentId + '/values')
            .set(values);
    }

    async setTranslatedValuesFilled(filledDocumentId: string, values: {[id: string]: any}) {
        const user = this.auth.getCurrentUser();
        await this.isFilledDocumentEditable(user.uid, filledDocumentId);
        await firebase.database().ref('filled_documents/' + user.uid + '/' + filledDocumentId + '/translatedValues')
            .set(values);
        await firebase.database().ref('filling_filled_documents/' + user.uid + '/' + filledDocumentId + '/translatedValues')
            .set(values);
    }

    async setCustomTitleFilledDocument(filledDocumentId: string, title: string) {
        const user = this.auth.getCurrentUser();
        await this.isFilledDocumentEditable(user.uid, filledDocumentId);
        await firebase.database().ref('filled_documents/' + user.uid + '/' + filledDocumentId + '/custom_title')
            .set(title);
        await firebase.database().ref('filling_filled_documents/' + user.uid + '/' + filledDocumentId + '/custom_title')
            .set(title);
    }

    async verifySigningEmailCode(signId: string, ownerId: string, filledDocumentId: string, emailCode: string) {
        await this.cloud.verifySigningEmailCode(signId, ownerId, filledDocumentId, emailCode);
    }

    async isFilledDocumentEditable(userId: string, filledDocumentId: string) {
        const filledDocument = FilledDocument.fromJSON((await firebase.database()
            .ref('filled_documents/' + userId + '/' + filledDocumentId)
            .once('value')).val());
        if (filledDocument.locked) {
            throw {
                errors: ['Can\'t edit a locked document']
            };
        }
        if (filledDocument.state === FillDocumentState.signing) {
            this.cloud.undoSigningState(filledDocument.document_id, filledDocumentId);
        }
    }

    async getFilledDocument(filledDocumentId: string, ownerId?: string): Promise<FilledDocument> {
        let userId = ownerId;
        let isTheOwner = false;
        if (!userId) {
            isTheOwner = true;
            const user = this.auth.getCurrentUser();
            userId = user.uid;
        }

        const snapshot =  await firebase.database().ref('filled_documents/' + userId + '/' + filledDocumentId).once('value');
        if (!snapshot.val()) {
            throw {
                errors: ['Document not found']
            };
        }
        const filledDocument = FilledDocument.fromJSON(snapshot.val());
        if (isTheOwner) {
            const snapshotDocument = await firebase.database().ref('documents_owned/' + userId + '/' + filledDocument.document_id )
                .once('value');
            const ownedDocument = OwnedDocument.fromJSON(snapshotDocument.val());
            const subscriptionState = await this.subscriptionService.getSubscriptionState();
            if (subscriptionState) {

                filledDocument.remainingDocumentReviews = ownedDocument.amountReviews < 0 || subscriptionState.amountOfReviewsLeft < 0 ?
                    -1 : ownedDocument.amountReviews + subscriptionState.amountOfReviewsLeft;
            } else {
                filledDocument.remainingDocumentReviews = ownedDocument.amountReviews < -1 ?
                    -1 : ownedDocument.amountReviews;
            }
        }

        return filledDocument;
    }

    async getCompletedFilledDocument(filledDocumentId: string): Promise<FilledDocument> {
        const user = this.auth.getCurrentUser();
        const snapshot =  await firebase.database().ref('completed_filled_documents/' + user.uid + '/' + filledDocumentId).once('value');
        if (!snapshot.val()) {
            throw {
                errors: ['Document not found']
            };
        }
        const filledDocument = FilledDocument.fromJSON(snapshot.val());
        return filledDocument;
    }

    getValuesFilled(filledDocumentId: string): Observable<{[id: string]: any}> {
        const user = this.auth.getCurrentUser();
        return this.dbObs.object('filled_documents/' + user.uid + '/' + filledDocumentId + '/values').snapshotChanges()
            .pipe(map(value => {
                const val: any = value.payload.val();
                if (!val) {
                    return {};
                }
                return val;
            }));
    }

    async uploadAttachmentForReview(filledDocumentId: string, file: File): Promise<string> {
        // Get permission to write in storage
        const response = await this.cloud.uploadAttachmentForReview(filledDocumentId, file.type, file.name);
        await firebase.auth().signInWithCustomToken(response.token);
        await firebase.auth().currentUser.getIdToken(true);

        // Upload document
        const ref = this.angularFireStorage.ref(response.path);
        const task = await ref.put(file, {contentType: file.type, customMetadata: {'uploader': firebase.auth().currentUser.uid}});

        return response.path;
    }

    /**
     *
     * @param filledDocumentId
     * @param file
     *
     * @return new filepath
     */
    async uploadFileForAnnex(filledDocumentId: string, file: File): Promise<string> {
        // Get permission to write in storage
        const response = await this.cloud.uploadAttachmentForAnnex(filledDocumentId, file.type, file.name);
        await firebase.auth().signInWithCustomToken(response.token);
        await firebase.auth().currentUser.getIdToken(true);

        // Upload document
        const ref = this.angularFireStorage.ref(response.path);
        const task = await ref.put(file, {contentType: file.type, customMetadata: {'uploader': firebase.auth().currentUser.uid}});

        return response.path;
    }

    async updateAnnexes(filledDocumentId: string, annexes: string[]) {
        // Get permission to write in storage
        await this.cloud.updateAnnex(filledDocumentId, annexes);
    }
    async overwriteFilledDocument(filledDocumentId: string, path: string) {
        await this.cloud.updateOverwrite(filledDocumentId, true, path);
    }
    async undoOverwriteFilledDocument(filledDocumentId: string) {
        // Get permission to write in storage
        await this.cloud.updateOverwrite(filledDocumentId, false);
    }

    async sendDocumentToReview(filledDocumentId: string, title: string, body: string, email: string, phone?: string, paths?: string[]) {
        if (paths && paths.length > 3) {
            throw {errors: ['Too many paths. The maximum is 3 paths.']};
        }
        const render = await this.renderInProgressFilledDocuments(filledDocumentId);
        await this.cloud.sendDocumentToReview(filledDocumentId, title, body, email, render.pdfPath, phone, paths);
    }

    async completeFillDocument(documentId: string, filledDocumentId: string, noSign?: boolean): Promise<boolean> {
        return await this.cloud.completeFillDocument(documentId, filledDocumentId, !!noSign);
    }

    async renderInProgressFilledDocuments(filledDocumentId: string): Promise<Render> {

        return new Promise<Render>(async (resolve, reject) => {
            // TODO REnder Temporal
            const jobId = await this.cloud.renderFillDocument(filledDocumentId, false);
            const itemRef = this.dbObs.object('rendering_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        const render = RenderJob.fromJSON(item).render;
                        resolve(render);
                        break;

                    case RenderStatus.Error:
                        console.log('Error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        console.log('Server error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }

    async renderSignerFilledDocuments(filledDocumentId: string, ownerId: string): Promise<any> {

        return new Promise(async (resolve, reject) => {
            // TODO REnder Temporal
            const jobId = await this.cloud.renderFillDocument(filledDocumentId,false, ownerId);
            const itemRef = this.dbObs.object('rendering_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        const render = RenderJob.fromJSON(item).render;
                        resolve(render);
                        break;

                    case RenderStatus.Error:
                        console.log('Error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        console.log('Server error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }

    async renderCompletedFilledDocuments(filledDocumentId: string): Promise<any> {

        return new Promise(async (resolve, reject) => {
            const jobId = await this.cloud.renderFillDocument(filledDocumentId, true);
            const itemRef = this.dbObs.object('rendering_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        const render = RenderJob.fromJSON(item).render;
                        resolve(render);
                        break;

                    case RenderStatus.Error:
                        console.log('Error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        console.log('Server error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }

    private async getRenderObject(filledDocumentId: string): Promise<Render> {
        const user = this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref(`rendering/${user.uid}/${filledDocumentId}/${9}`).once('value');
        let render;
        if (snapshot.val()) {
            render = Render.fromJSON(snapshot.val());
        }
        return render;
    }

    private async getRenderOrGenerateIt(filledDocumentId: string): Promise<Render> {
        let render = await this.getRenderObject(filledDocumentId);
        if (!render) {
            await this.renderCompletedFilledDocuments(filledDocumentId);
            render = await this.getRenderObject(filledDocumentId);
        }
        return render;
    }
    async getUrlDocumentDocx(filledDocumentId: string, language: string): Promise<Observable<string>> {
        const render = await this.getRenderOrGenerateIt(filledDocumentId);

        return this.docService.getUrlResource(render.docxPath);
    }

    async getUrlDocumentPdf(filledDocumentId: string, language: string): Promise<Observable<string>> {
        const render = await this.getRenderOrGenerateIt(filledDocumentId);
        return this.docService.getUrlResource(render.pdfPath);
    }

    public async getPaginateOwnedDocuments( numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('public_documents_owned/' + user.uid );
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        const feedPromise: Promise<PublicLegalDocument>[] = [];
        let newCursor = cursor;

        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feedPromise.push(this.docService.getCompletDocumentById(snpsht.val()['id']));
        });
        let feed: PublicLegalDocument[] = await Promise.all(feedPromise);
        feed = feed ? feed.filter( (el) => !!el) : [];
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    public async getPaginateFillingFilledDocuments( numDocuments: number, cursor?: any): Promise<FilledFeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('filling_filled_documents/' + user.uid );
        return await this.getFilledDocumentsGeneric(ref, numDocuments, cursor);
    }

    public async getPaginateSigningFilledDocumentsNotByMe( numDocuments: number, cursor?: any): Promise<FilledFeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('signing_filled_documents/' + user.uid );
        return await this.getFilledDocumentsGeneric(ref, numDocuments, cursor);
    }
    public async getPaginateReviewingFilledDocuments( numDocuments: number, cursor?: any): Promise<FilledFeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('reviewing_filled_documents/' + user.uid);
        return await this.getFilledDocumentsGeneric(ref, numDocuments, cursor);
    }

    public async getPaginateCompletedFilledDocuments( numDocuments: number, cursor?: any): Promise<FilledFeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('completed_filled_documents/' + user.uid );
        return await this.getFilledDocumentsGeneric(ref, numDocuments, cursor);
    }

    private async getFilledDocumentsGeneric(ref: any,  numDocuments: number, cursor?: any): Promise<FilledFeedInterface> {
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        let feed: FilledDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(FilledDocument.fromJSON(snpsht.val()));
        });
        feed = feed ? feed.filter( (el) => !!el) : [];

        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    /**
     *
     * @param documentId
     * @returns filledDocumentId
     */
    public async tryToBuyDocumentAndFill(documentId: string): Promise<string> {
        try {
            await this.docService.purchaseFreeDocumentWithoutLegalAdvice(documentId);
        } catch (e) {
            // Already bought
        }
        return await this.newFilledDocument(documentId);
    }



    // region buy legal advice

    public async getStartPaypalBuyLegalAdvice(filledDocumentId: string, callback?: (err: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientLegalAdviceStartPaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function() {

            // Set up a url on your server to create the payment
            const CREATE_URL = url;

            const body = {
                filled_document_id: filledDocumentId,
                token: token,
                platform_id: platformId
            };
            // Make a call to your server to set up the payment
            return paypal.request.post(CREATE_URL, body)
                .then(function(res) {
                    return res.id;
                }).catch(e => { callback(e); });
        };
    }

    public async getCompletePaypalBuyLegalAdvice(callback: (err: any, response: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientLegalAdviceCompletePaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function(data, actions) {
            // Set up a url on your server to create the payment
            const EXECUTE_URL = url;

            // Make a call to your server to set up the payment
            const body: any = {
                paymentID: data.paymentID,
                payerID: data.payerID,
                token: token,
                platform_id: platformId
            };


            // Make a call to your server to execute the payment
            return paypal.request.post(EXECUTE_URL, body)
                .then((res) => {
                    actions.close();
                    callback(undefined, res);
                }).catch((res) => {
                    actions.close();
                    callback(res, undefined);
                });
        };
    }

    public async getRealexHPPLegalAdvice(filledDocumentId: string): Promise<any> {
        return await this.cloud.legalAdviceHPPRealexTransaction(filledDocumentId);
    }

    public async makeCardPayment(filledDocumentId: string, pan: string,
                          expirationYear: number, expirationMonth: number, cvn: string, name: string, cardType: string): Promise<any> {
        try {
            const json = await this.cloud.legalAdviceMakeRealexTransaction(filledDocumentId, pan, expirationYear,
                expirationMonth, cvn, name, cardType);
            return json;
        } catch (e) {
            throw {
                errors: e
            };
        }
    }
    // endregion



}


