import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';

declare var JsBarcode: any;

import {LegalDocument} from 'milcontratos-database';
import {DocumentsService} from '../../services/client/documents.service';


function assert(predicate: boolean, msg: string) {
    if (!predicate) {
        throw new Error(msg);
    }
}


@Component({
    selector: 'mc-doc-preview',
    template: `<div class="document-container">
    <div id="zoom-box" class="zoom-box">
        <button type="button" class="btn-floating waves-effect waves-light deep-purple darken-4" (click)="zoomIn()"><i
            class="material-icons notranslate">add</i></button>
        <button type="button" class="btn-floating waves-effect waves-light deep-purple darken-4" (click)="zoomOut()"><i
            class="material-icons notranslate">remove</i></button>
        <button type="button" class="zoom-adjust btn-floating waves-effect waves-light deep-purple darken-4"
                (click)="zoomAdjust()"><i class="material-icons notranslate">crop_free</i></button>
        <button *ngIf="hasTranslation" type="button"
                class="zoom-adjust btn-floating waves-effect waves-light deep-purple darken-4" (click)="changeLanguage()">
            <i class="material-icons notranslate">translate</i></button>
        <button *ngIf="!loadingAnimation && downloadable" type="button"
                class="zoom-adjust btn-floating waves-effect waves-light deep-purple darken-4" (click)="downloadFile()">
            <i class="material-icons notranslate">cloud_download</i></button>
        <button *ngIf="loadingAnimation" type="button"
                class="zoom-adjust btn-floating waves-effect waves-light deep-purple darken-4">
            <div class="preloader-wrapper big active">
                <div class="spinner-layer spinner-red-only">
                    <div class="circle-clipper left">
                        <div class="circle"></div>
                    </div>
                    <div class="gap-patch">
                        <div class="circle"></div>
                    </div>
                    <div class="circle-clipper right">
                        <div class="circle"></div>
                    </div>
                </div>
            </div>
        </button>
    </div>

    <div id="mdp-visible-document">
        <div id="mdp-pages-container">

        </div>
    </div>
    <div id="mdp-invisible-document">
        <div id="mdp-invisible-header" class="din-a4 mdp-page-header">

        </div>
        <div id="mdp-invisible-body" class="din-a4">

        </div>
        <div id="mdp-invisible-footer" class="din-a4 mdp-page-footer" style="position: relative;">

        </div>
    </div>
</div>
`,
    styles: [`.document-container{width:100%;height:100%;overflow:hidden}#mdp-visible-document{width:100%;height:100%;overflow-y:scroll;box-shadow:inset 0 0 50px 0 rgba(0,0,0,.1),inset 0 2px 35px 0 rgba(0,0,0,.15)}#mdp-invisible-document{width:100%}.din-a4{width:210mm;padding-left:15mm;padding-right:15mm}#mdp-pages-container{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-flow:row wrap;-webkit-box-pack:center;justify-content:center;align-content:flex-start;-webkit-box-align:start;align-items:flex-start;position:relative;width:100%;background-color:#fff}.document-container:hover .zoom-box{opacity:1}.zoom-box{display:-webkit-box;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;flex-flow:column wrap;-webkit-box-pack:start;justify-content:flex-start;-webkit-box-align:center;align-items:center;align-content:center;position:absolute;margin-top:20px;top:20px;right:3vw;z-index:60;opacity:1}.zoom-box button:first-child{margin-bottom:10px}.zoom-adjust{margin-top:40px}.preloader-wrapper.big{margin-top:5px;width:30px;height:30px}`]
})
export class DocPreviewComponent implements OnInit, OnChanges {

    private readonly A4_WIDTH_CM = 21;
    private readonly A4_HEIGHT_CM = 29.7;

    @Input() document: LegalDocument;
    @Input() loadAnimation: boolean;
    @Input() downloadable: boolean = true;
    @Input() hash: string;
    @Input() signId: string;

    /**
     * This flag is used in the admin page to enable the preview to work with documents that have not been
     * uploaded to the backend.
     */
    @Input() isUploadPreview: boolean = false;

    /**
     * Will emit once the document is successfully loaded from the backend. Notice that this does not mean that the document has
     * been successfully painted, only loaded from the backend.
     */
    @Output() loaded: EventEmitter<void> = new EventEmitter();

    /**
     * Will emit each time the user changes the current language.
     */
    @Output() languageChange: EventEmitter<string> = new EventEmitter();

    /**
     * Will emit each time the user clicks the download button.
     */
    @Output() download: EventEmitter<void> = new EventEmitter();

    displayedDocument: LegalDocument;

    invisibleHeader: HTMLDivElement;
    invisibleBody: HTMLDivElement;
    invisibleFooter: HTMLDivElement;
    visibleDocument: HTMLDivElement;
    visiblePagesContainer: HTMLDivElement;

    pxOverCmRatio: number;

    loadingAnimation: boolean = false;

    hasTranslation: boolean = false;
    loadingTranslation: boolean = false;

    constructor(private documentsService: DocumentsService, private http: HttpClient) {
    }

    ngOnInit() {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
            /*
             * We must place them here because otherwise, given Angular's encapsulation of the styles within every
             * component, custom DOM elements would not see the class.
             */
            .dotted-field {
                display: inline-block;
                height: 20px;
                min-width: 75px;
                word-wrap: break-word;
            
                background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0));
                background-position: bottom;
                background-size: 5px 1px;
                background-repeat: repeat-x;
            }
            
            .bullet {
                margin-right: 5px;
            }

            .bullet-white {
                margin-right: 5px;
            }

            .bullet-dash {
                margin-right: 5px;
            }
            
            .list-bullet {
                margin-left: calc(100% / 24);
            }
            
            .list-bullet-white {
                margin-left: calc(100% / 12);
            }

            .list-bullet-dash {
                margin-left: calc(100% / 24);
            }

            .tabs-1 {
                margin-left: calc(100% / 12);
            }
            
            .mdp-page {
                position: relative;
                width: 210mm;
                min-width: 210mm; /* We shall use min-width and max-width because otherwise pages are resized. */
                max-width: 210mm;
                height: 297mm;
                padding: 0 1.5cm;
                margin-bottom: 20px;
                background-color: white;
                font-family: 'Roboto', sans-serif;
                box-shadow: 0 0 20px 0 #777;
                word-break: break-word;
            }
            
            .mdp-page-header {
                min-height: 25mm;
                margin: 0;
                /* We cannot use the shorthand version because it would override the left/right margin applied to the page */
                padding-top: 12.5mm;
            }
            
            p {
                margin-top: 0;
                margin-bottom: 16px;
            }
            
            .mdp-page-footer {
                position: absolute;
                bottom: 0;
            
                display: flex;
                flex-direction: column-reverse;
            
                min-height: 25mm;
                margin: 0;
                /* We cannot use the shorthand version because it would override the left/right margin applied to the page */
                padding-bottom: 12.5mm;
            }
        `;
        document.head.appendChild(style);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if ('document' in changes) {
            this.hasTranslation = Object.keys(this.document.availableLanguages).length > 1;
            await this.setDisplayedDocument(this.document.documentLanguage, false);
            this.loaded.emit();
        }

        if ('loadAnimation' in changes) {
            this.loadingAnimation = this.loadAnimation;
        }

        if ('hash' in changes) {
            this.reloadSignatures();
        }
    }

    // region public_low_level_api

    /**
     * Sets the value of the given field within the document.
     * Notice that if the field belongs to a repeteable block, all repetitions of the block must be created with a call
     * to "incrementRepeatableBlock" prior to calling this function. If an array is passed with more values than repetitions,
     * then all extra repetitions will be ignored. Similarly, if an array is passed with less values than repetitions, then
     * all missing repetitions will be filled with empty values.
     */
    changeFieldValue(fieldId: string, values: string[]) {
        const fieldElements = Array.from(this.invisibleBody.getElementsByClassName(`field-${fieldId}`));
        for (const fieldElement of fieldElements) {
            assert(fieldElement.tagName === 'SPAN', 'There is a field that is not a span!');

            const repeatableBlockElement = this.getRepeatableBlock(fieldElement as HTMLElement);
            if (!repeatableBlockElement) {
                this.setFieldText(fieldElement as HTMLSpanElement, values.join(', '));
            } else {
                this.setFieldText(fieldElement as HTMLSpanElement, values[this.getRepetitionIndex(repeatableBlockElement)]);
            }
        }
    }

    /**
     * Makes the given conditional block visible.
     *
     * @param blockId Id of the block.
     * @param repetitionIndex In case the conditional block belongs to a repeatable block, index of its repetition.
     *                        If the conditional block does not belong to a repeatable block, this argument must be 0.
     */
    showConditionalBlock(blockId: string, repetitionIndex: number) {
        const blockElement = this.getConditionalBlock(blockId, repetitionIndex);
        assert(blockElement !== undefined, `There is no conditional block with ID ${blockId}`);
        blockElement.style.display = 'block';
    }

    /**
     * Makes the given conditional block invisible.
     *
     * @param blockId Id of the block.
     * @param repetitionIndex In case the conditional block belongs to a repeatable block, index of its repetition.
     *                        If the conditional block does not belong to a repeatable block, this argument must be 0.
     */
    hideConditionalBlock(blockId: string, repetitionIndex: number) {
        const blockElement = this.getConditionalBlock(blockId, repetitionIndex);
        assert(blockElement !== undefined, `There is no conditional block with ID ${blockId}`);
        blockElement.style.display = 'none';
    }

    /**
     * Adds a new repetition to the end of the given repeteable block. Notice that the values of all fields within that new repetition
     * will be initialized to the current values of the last repetition. A call to changeFieldValue must be formed to set their values.
     */
    incrementRepeatableBlock(blockId: string) {
        const blocks = this.getRepetitionBlocks(blockId);

        const lastRepetitionElement = blocks[blocks.length - 1] as HTMLDivElement;
        const lastRepetitionIndex = this.getRepetitionIndex(lastRepetitionElement);

        const newRepetitionElement = lastRepetitionElement.cloneNode(true) as HTMLDivElement;
        newRepetitionElement.classList.remove(`repetition-${lastRepetitionIndex}`);
        newRepetitionElement.classList.add(`repetition-${lastRepetitionIndex + 1}`);

        const parent = lastRepetitionElement.parentElement;
        parent.insertBefore(newRepetitionElement, lastRepetitionElement.nextSibling);
    }

    /**
     * Removes the repetition at the given index of the given repetition block.
     */
    decrementRepeatableBlock(blockId: string, index: number) {
        const blocks = this.getRepetitionBlocks(blockId);
        assert(0 <= index && index < blocks.length, 'The given index is outside the range of repetitions for the given block!');
        assert(blocks.length > 1, 'Cannot remove the last repetition of a repeatable block!');

        for (let i = index + 1; i < blocks.length; i++) {
            const block = blocks[i];
            const repetitionIndex = this.getRepetitionIndex(block);
            block.classList.remove(`repetition-${repetitionIndex}`);
            block.classList.add(`repetition-${repetitionIndex - 1}`);
        }

        const blockToRemove = blocks[index];
        blockToRemove.parentElement.removeChild(blockToRemove);
    }

    /**
     * Recomputes the keys of all autonumerables of the document. In case an autonumerable exceeds the maximum number of keys
     * for that given kind of autonumerable, the keys will be warped to the first one again.
     *
     * @param kind Kind of autonumerable to recompute.
     */
    recomputeAutonumerables(kind?: string) {
        if (!this.displayedDocument.texts_lists) {
            return;
        }

        if (!kind) {
            for (const k of ['admiration', 'dollar', 'hashtag', 'euro', 'percentage']) {
                this.recomputeAutonumerables(k);
            }
            return;
        }

        const keys = this.displayedDocument.texts_lists[kind];
        if (!keys || keys.length === 0) {
            return;
        }

        const elements = Array.from(this.invisibleBody.getElementsByClassName(`counter-${kind}`)) as HTMLElement[];
        let i = 0;
        for (const element of elements) {
            if (!this.isVisible(element)) {
                continue;
            }

            if (element.classList.contains('reset')) {
                i = 0;
                continue;
            }

            element.innerText = keys[i % keys.length];
            i++;
        }
    }

    /**
     * Adds the given barcode to the signatures part of the document.
     *
     * @param signatureId ID of the signature. All subsequent calls that have to manipulate that signature shall use this ID.
     * @param signature Hash of the signature to be drawn onto the document.
     */
    addSignature(signatureId: string, signature: string) {
        const nonDigitalDiv = Array.from(this.invisibleBody.getElementsByClassName('non-digital-signature'))[0] as HTMLDivElement;
        assert(nonDigitalDiv !== undefined, 'There is no non-digital signature part in this document!');

        const digitalDiv = Array.from(this.invisibleBody.getElementsByClassName('digital-signature'))[0] as HTMLDivElement;
        assert(digitalDiv !== undefined, 'There is no digital signature part in this document!');

        nonDigitalDiv.style.display = 'none';

        const signatureImg = document.createElement('IMG');
        signatureImg.id = signatureId;
        signatureImg.style.width = '100%';
        signatureImg.style.height = '115px';
        digitalDiv.appendChild(signatureImg);
        JsBarcode(`#${signatureId}`, signature);
    }

    /**
     * Makes all changes made to the document after the last repaint visible to the user. Notice that by simply calling the
     * methods changeFieldValue, showConditionalBlock, etc., the user won't see any change. It is necessary to call this method
     * to actually display the changes. In order to boost performance, this method should be called only after scheduling all
     * individual changes separately, to prevent multiple expensive repaints.
     */
    repaint() {
        const headerHeight = this.getHeaderHeight();
        const footerHeight = this.getFooterHeight();
        const bodyHeight = this.cmToPx(this.A4_HEIGHT_CM) - headerHeight - footerHeight;

        const elements = Array.from(this.invisibleBody.children) as HTMLElement[];
        let yOrigin;
        const fragment = document.createDocumentFragment();
        let currentPageDiv: HTMLDivElement;
        let changePage = true;
        let numPages = 0;
        while (elements.length > 0) {
            if (changePage) {
                if (currentPageDiv) {
                    currentPageDiv.appendChild(this.cloneInDiv(this.invisibleFooter, 'mdp-page-footer'));
                    fragment.appendChild(currentPageDiv);
                    numPages++;
                }

                currentPageDiv = document.createElement('DIV') as HTMLDivElement;
                currentPageDiv.classList.add('mdp-page');
                currentPageDiv.appendChild(this.cloneInDiv(this.invisibleHeader, 'mdp-page-header'));
                yOrigin = elements[0].getBoundingClientRect().top;
                changePage = false;
            }

            const currentElement = elements.shift() as HTMLElement;
            const elementTop = currentElement.getBoundingClientRect().top - yOrigin;
            if (currentElement.classList.contains('page-break')) {
                changePage = true;
                continue;
            }

            if (this.getHeight(currentElement) === 0) {
                // This is not strictly necessary but as elements with 0 height are invisible, they are useless in the visible
                // DOM and we can speed up things by skipping their cloning and appending.
                continue;
            }

            const elementHeight = currentElement.getBoundingClientRect().height;
            const currentHeight = elementTop + elementHeight;
            if (currentHeight <= bodyHeight) {
                currentPageDiv.appendChild(currentElement.cloneNode(true));
            } else {
                if (currentElement.tagName === 'P' || currentElement.tagName === 'BR' || currentElement.tagName === 'IMG') {
                    changePage = true;
                    elements.unshift(currentElement);
                } else if (currentElement.tagName === 'DIV') {
                    elements.unshift(...Array.from(currentElement.children) as HTMLElement[]);
                } else {
                    console.warn(`Paginated element ${currentElement.tagName} different from "p", "br", "img" or "div"!`);
                    console.log(currentElement);
                    /*assert(
                        false,
                        `All paginated elements should be either "p", "br", "img" or "div". However, got ${currentElement.tagName}!`
                    );*/
                }
            }
        }

        // Necessary because of the last page.
        if (currentPageDiv && currentPageDiv.children.length > 0) {
            currentPageDiv.appendChild(this.cloneInDiv(this.invisibleFooter, 'mdp-page-footer'));
            fragment.appendChild(currentPageDiv);
        }

        this.visiblePagesContainer.innerHTML = '';
        this.visiblePagesContainer.appendChild(fragment);
    }

    /**
     * Increments the zoom of the visible document.
     * Notice that this function must be called after repaining the document.
     */
    zoomIn() {
        this.setCurrentScale(this.getCurrentScale() + 0.1);
    }

    /**
     * Decrements the zoom of the visible document.
     * Notice that this function must be called after repainting the document.
     */
    zoomOut() {
        this.setCurrentScale(this.getCurrentScale() - 0.1);
    }

    /**
     * Sets the zoom of the visible document such that it fits the screen.
     * Notice that this function must be called after repainting the document.
     */
    zoomAdjust() {
        const originalPageWidth = (Array.from(this.visibleDocument.getElementsByClassName('mdp-page'))[0] as HTMLDivElement).offsetWidth;
        this.setCurrentScale(this.visibleDocument.offsetWidth / originalPageWidth - 0.1);
    }

    /**
     * Changes the currently displayed language for the document.
     */
    async changeLanguage() {
        if (this.loadingTranslation) {
            return;
        }

        try {
            this.loadingTranslation = true;
            const availableLanguages = Object.keys(this.document.availableLanguages);
            const otherLanguage = availableLanguages.find((l) => l !== this.displayedDocument.documentLanguage);
            await this.setDisplayedDocument(otherLanguage, true);
            // this.repaint();
            this.languageChange.emit(otherLanguage);
        } catch (ex) {
            console.error(ex);
        } finally {
            this.loadingTranslation = false;
        }
    }

    // endregion

    // region backend_communication

    /**
     * Loads the HTML generated by the parser for a given document part.
     *
     * @param language Language of the part (ie, "es", "en", etc.).
     * @param part Part of the document (ie, "header", "body", "footer").
     * @returns The HTML generated by the parser for that part of the document in the given language.
     */
    private async loadDocumentPart(language: string, part: string): Promise<string> {
        const url = await this.documentsService.getUrlResource(this.displayedDocument[part][language]).toPromise();
        return await this.http.get(url, {responseType: 'text'}).toPromise();
    }

    downloadFile() {
        this.download.emit();
    }

    // endregion

    // region private_invisible_dom_manipulation

    /**
     * If the given element belongs to a repeatable block, returns its div. Otherwise, returns null.
     * Notice that this method assumes that an element can ONLY BELONG TO A SINGLE repeatable block.
     * If an element belonged to more than one repeatable block, this method would return just its closest repeatable block.
     */
    private getRepeatableBlock(element: HTMLElement): HTMLDivElement | null {
        return element.closest('DIV.repeatable') as HTMLDivElement | null;
    }

    /**
     * Given a repeatable block, returns its repetition index.
     */
    private getRepetitionIndex(repeatableBlockElement: HTMLDivElement): number {
        const repetitionIndexClass = Array.from(repeatableBlockElement.classList)
            .find((cls: string) => cls.indexOf('repetition-') >= 0);
        assert(repetitionIndexClass !== undefined, 'There is a repetition block without repetition index!');
        return parseInt(repetitionIndexClass.split('-')[1], 10);
    }

    /**
     * For a given condition block, return its div.
     *
     * @param blockId Id of the block.
     * @param repetitionIndex In case the conditional block belongs to a repeatable block, index of its repetition.
     *                        If the conditional block does not belong to a repeatable block, this argument must be 0.
     */
    private getConditionalBlock(blockId: string, repetitionIndex: number): HTMLDivElement {
        const element = Array.from(this.invisibleBody.getElementsByClassName(`cond-${blockId}`))
            .find((elem: HTMLElement) => {
                if (!elem || elem.tagName !== 'DIV') {
                    return false;
                }

                const repeatableBlock = this.getRepeatableBlock(elem);
                if (!repeatableBlock) {
                    return repetitionIndex === 0;
                }
                return this.getRepetitionIndex(repeatableBlock) === repetitionIndex;
            }) as HTMLDivElement;
        assert(!!element, `There is no conditional block with id ${blockId}`);
        return element;
    }

    /**
     * Return all div elements that belong to the given repeatable block.
     */
    private getRepetitionBlocks(blockId: string): HTMLDivElement[] {
        const blocks = Array
            .from(this.invisibleBody.getElementsByClassName(`rep-${blockId}`))
            .filter((element) => element.tagName === 'DIV') as HTMLDivElement[];
        assert(blocks.length > 0, 'There are no repetitions for the given block. Repeatable blocks can never be left without repetitions!');
        return blocks;
    }

    /**
     * Set the text of a field. Notice that this method will set the dotted-field class if the passed text is either
     * an empty string or undefined.
     */
    private setFieldText(fieldElement: HTMLElement, text?: string) {
        if (text) {
            fieldElement.classList.remove('dotted-field');
            fieldElement.innerText = text;
        } else {
            fieldElement.classList.add('dotted-field');
            fieldElement.innerText = '';
        }
    }

    /**
     * Given a DOM element, determine if it is visible.
     */
    private isVisible(element: HTMLElement): boolean {
        return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
    }

    // endregion

    // region private_visible_dom_manipulation

    /**
     * Returns the height of the header in pixels.
     */
    private getHeaderHeight(): number {
        return this.getHeight(this.invisibleHeader);
    }

    /**
     * Returns the height of the footer in pixels.
     */
    private getFooterHeight(): number {
        return this.getHeight(this.invisibleFooter);
    }

    private cmToPx(cm: number): number {
        /*if (!this.pxOverCmRatio) {
            const invisibleBodyWidth = this.invisibleBody.getBoundingClientRect().width;
            console.log('invisibleBodyWidth', invisibleBodyWidth);
            this.pxOverCmRatio = invisibleBodyWidth / this.A4_WIDTH_CM;
        }
        return cm * this.pxOverCmRatio;*/
        return cm * 96 / 2.54;
    }

    /**
     * Return a <div> having as children a (deep) clone of all of all the children of the given DOM element.
     *
     * @param element Element whose children are to be cloned into the resulting div.
     * @param classes Classes that are to be assigned to the returned div element.
     */
    private cloneInDiv(element: HTMLElement, ...classes: string[]): HTMLDivElement {
        const div = document.createElement('DIV') as HTMLDivElement;
        for (const child of Array.from(element.children)) {
            div.appendChild(child.cloneNode(true));
        }
        div.classList.add(...classes);
        return div;
    }

    /**
     * Return the height of an element in pixels.
     */
    private getHeight(element: HTMLElement): number {
        return element.getBoundingClientRect().height;
    }

    /**
     * Return the current scale of the visible document.
     */
    private getCurrentScale(): number {
        // Works because the first width is the scaled one and the second the unscaled one (see https://stackoverflow.com/a/26893663).
        return this.visiblePagesContainer.getBoundingClientRect().width / this.visiblePagesContainer.offsetWidth;
    }

    /**
     * Set the current scale of the visible document.
     */
    private setCurrentScale(scale: number) {
        this.visiblePagesContainer.style.transform = `scale(${scale}) translateY(50px)`;
        this.visiblePagesContainer.style.transformOrigin = '50% 0 0';
    }

    // endregion

    // region render_sanitization

    /**
     * Takes the DOM generated by the old parser and makes it compliant with the new parser directives.
     *
     * @param parent Element containing the DOM to be sanitized.
     */
    private sanitizeRenderDOM(parent: HTMLDivElement) {
        const version = this.getParserVersion(parent);

        this.substitutePsByBrs(parent);
        this.prefixIdClasses(parent);
        this.addRepetitionClass(parent);
        this.addBulletPointCharacters(parent);

        if (this.isVersionLt(version, [0, 1, 0])) {
            this.refactorAutonumerableRestarts(parent);
        }

        if (this.isVersionLt(version, [0, 1, 0])) {
            this.refactorSignature(parent);
        }

        if (this.isVersionLt(version, [0, 1, 0])) {
            this.changePageBreakClass(parent);
        }
    }

    /**
     * Returns the version of this document as an array of semantic version parts.
     * Notice that after calling this function the information will be removed from the DOM (otherwise it
     * messes up the page builder), so this method can be called just once.
     */
    private getParserVersion(parent: HTMLDivElement): [number, number, number] {
        const metaInfoDiv = Array.from(parent.getElementsByClassName('meta-info'))[0] as HTMLDivElement;
        if (!metaInfoDiv) {
            return [0, 0, 0];
        } else {
            const metaInfo = JSON.parse(metaInfoDiv.innerText);
            const version: string = metaInfo['version'];
            const v = version.split('.').map((v: string) => parseInt(v, 10));
            metaInfoDiv.remove();
            return v as [number, number, number];
        }
    }

    /**
     * Given two semantic versions v1 and v2, return true if v1 < v2 and false otherwise.
     */
    private isVersionLt(v1: [number, number, number], v2: [number, number, number]): boolean {
        if (v1[0] < v2[0]) {
            return true;
        } else {
            if (v1[1] < v2[1]) {
                return true;
            } else {
                if (v1[2] < v2[2]) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    /**
     * Substitutes all empty ps by brs in the given element.
     */
    private substitutePsByBrs(parent: HTMLDivElement) {
        const emptyPs = Array.from(parent.getElementsByTagName('P'))
            .filter((p: HTMLParagraphElement) => p.innerHTML === '');
        for (const p of emptyPs) {
            const br = document.createElement('br');
            br.setAttribute('style', p.getAttribute('style'));
            p.parentNode.replaceChild(br, p);
        }
    }

    /**
     * Prefix all classes that represent ids in the given element.
     */
    private prefixIdClasses(parent: HTMLDivElement) {
        if (this.displayedDocument.conditions) {
            this.prefixClasses(parent, this.displayedDocument.conditions.map((c) => c.id), 'cond');
        }
        if (this.displayedDocument.repeatableBlocks) {
            this.prefixClasses(parent, this.displayedDocument.repeatableBlocks.map((r) => r.id), 'rep');
        }
        if (this.displayedDocument.sections) {
            this.prefixClasses(
                parent,
                this.displayedDocument.sections
                    .map((sec) => sec.fields.map((f) => f.id))
                    .reduce((arr, ids) => arr.concat(ids), []),
                'field'
            );
        }
    }

    /**
     * Adds missing repetition index class to all repeatable blocks in the given element.
     */
    private addRepetitionClass(parent: HTMLDivElement) {
        const repetitionBlocks = Array.from(parent.getElementsByClassName('repeatable'));
        for (const repetition of repetitionBlocks) {
            repetition.classList.add('repetition-0');
        }
    }

    /**
     * Adds bullet point characters.
     */
    private addBulletPointCharacters(parent: HTMLDivElement) {
        const bulletPoints = Array.from(parent.getElementsByClassName('bullet'));
        for (const bulletPoint of bulletPoints) {
            (bulletPoint as HTMLSpanElement).innerText = '\u25CF';
        }
        const whiteBulletPoints = Array.from(parent.getElementsByClassName('bullet-white'));
        for (const whiteBulletPoint of whiteBulletPoints) {
            (whiteBulletPoint as HTMLSpanElement).innerText = '\u25CB';
        }
        const dashBulletPoints = Array.from(parent.getElementsByClassName('bullet-dash'));
        for (const dashBulletPoint of dashBulletPoints) {
            (dashBulletPoint as HTMLSpanElement).innerText = '-';
        }
    }

    /**
     * Refactor repeat of autonumerables to work with indepent divs instead of classes.
     */
    private refactorAutonumerableRestarts(parent: HTMLDivElement) {
        const restarts = Array.from(parent.getElementsByClassName('reset'));
        for (const enumerableElement of restarts) {
            const autonumerableType = Array.from(enumerableElement.classList).find((c: string) => c.indexOf('counter-') >= 0);
            assert(autonumerableType !== undefined, 'Cannot determine autonumerable type!');

            const resetElement = document.createElement('SPAN');
            resetElement.classList.add('reset', autonumerableType);
            enumerableElement.classList.remove('reset');

            const repeatableBlock = this.getRepeatableBlock(enumerableElement as HTMLElement);
            if (repeatableBlock) {
                const blockParent = repeatableBlock.parentElement;
                blockParent.insertBefore(resetElement, repeatableBlock);
            } else {
                const restartParent = enumerableElement.parentElement;
                restartParent.insertBefore(resetElement, enumerableElement);
            }
        }
    }

    /**
     * Refactor signature part to work with nested divs.
     */
    private refactorSignature(parent: HTMLDivElement) {
        const signatureDiv = Array.from(parent.getElementsByClassName('signature'))
            .find((elem) => elem.tagName === 'DIV');
        if (signatureDiv) {
            const newSignatureDiv = document.createElement('DIV');
            newSignatureDiv.classList.add('signature');

            const nonDigitalDiv = document.createElement('DIV');
            nonDigitalDiv.classList.add('non-digital-signature');
            for (const child of Array.from(signatureDiv.children)) {
                child.classList.remove('signature');
                nonDigitalDiv.appendChild(child);
            }
            newSignatureDiv.appendChild(nonDigitalDiv);

            const digitalDiv = document.createElement('DIV');
            digitalDiv.classList.add('digital-signature');
            newSignatureDiv.appendChild(digitalDiv);

            signatureDiv.parentElement.replaceChild(newSignatureDiv, signatureDiv);
        }
    }

    /**
     * Change old "pageBreak" class to new "page-break" one.
     */
    private changePageBreakClass(parent: HTMLDivElement) {
        const breakElements = Array.from(document.getElementsByClassName('pageBreak'));
        for (const breakElement of breakElements) {
            breakElement.classList.remove('pageBreak');
            breakElement.classList.add('page-break');
        }
    }

    /**
     * Takes an array of classes and substitutes them throughout the children of the given element by classes of the form prefix-cls where
     * cls was the original name of the class.
     */
    private prefixClasses(parent: HTMLElement, classes: string[], prefix: string) {
        for (const cls of classes) {
            const elements = Array.from(parent.getElementsByClassName(cls));
            for (const element of elements) {
                element.classList.remove(cls);
                element.classList.add(`${prefix}-${cls}`);
            }
        }
    }

    // endregion

    // region state_management

    /**
     * Loads the given translation of the current document into the doc preview.
     */
    private async setDisplayedDocument(language: string, reloadSignatures: boolean) {
        console.log('setDisplayedDocument', language, reloadSignatures);
        if (this.isUploadPreview) {
            this.displayedDocument = this.document;
        } else {
            if (this.document.availableLanguages[language]) {
                this.displayedDocument = await this.documentsService.getCompletDocumentById(this.document.availableLanguages[language]);
            } else {
                this.displayedDocument = this.document;
            }
        }

        this.invisibleHeader = document.getElementById('mdp-invisible-header') as HTMLDivElement;
        this.invisibleBody = document.getElementById('mdp-invisible-body') as HTMLDivElement;
        this.invisibleFooter = document.getElementById('mdp-invisible-footer') as HTMLDivElement;
        this.visibleDocument = document.getElementById('mdp-visible-document') as HTMLDivElement;
        this.visiblePagesContainer = document.getElementById('mdp-pages-container') as HTMLDivElement;

        const htmls = await Promise.all([
            this.loadDocumentPart(language, 'header'),
            this.loadDocumentPart(language, 'body'),
            this.loadDocumentPart(language, 'footer')
        ]);
        this.invisibleHeader.innerHTML = htmls[0];
        this.invisibleBody.innerHTML = htmls[1];
        this.invisibleFooter.innerHTML = htmls[2];

        // TODO: Remove once the render does not need sanitization.
        this.sanitizeRenderDOM(this.invisibleHeader);
        this.sanitizeRenderDOM(this.invisibleBody);
        this.sanitizeRenderDOM(this.invisibleFooter);

        if (reloadSignatures) {
            this.reloadSignatures();
        }
    }

    private reloadSignatures() {
        if (this.hash) {
            this.addSignature('id99', this.hash);
            this.repaint();
        }
    }

    // endregion
}
