import {Injectable} from '@angular/core';
import matchSorter from 'match-sorter';
import * as firebase from 'firebase';
import {Parser} from 'json2csv';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {
    AutocompleteInterface,
    FeedInterface,
    getAllIconDefaultSystem,
    getAllIconPlatformSystem,
    IconNode,
    PublicLegalDocument,
    getDirectoryReferenceForSnapshot,
    getQuestionReferenceForSnapshot,
    QuestionInterface,
    getRootQuestionPlatformExpertSystem,
    getRootQuestionDefaultExpertSystem,
    getQuestionPlatformExpertSystem,
    getQuestionDefaultExpertSystem,
    getRootDirectoryPlatformDirectorySystem,
    getRootDirectoryDefaultDirectorySystem,
    getDirectoryPlatformDirectorySystem, getDirectoryDefaultDirectorySystem
} from 'milcontratos-database';
import {DocumentsService} from './documents.service';
import {Observable} from 'rxjs';
import {DirectoryInterface} from 'milcontratos-database';
import {EnvironmentBaseService} from '../shared/environment-base.service';

@Injectable({
    providedIn: 'root'
})
export class ListDocumentsService {

    private nameByIdNoStatic: AutocompleteInterface[];
    private nameByIdStatic: AutocompleteInterface[];
    private questionRootId: string;
    private refreshing: boolean = false;
    constructor(private cloud: CloudFunctionsService,
                private docService: DocumentsService,
                private enviromentService: EnvironmentBaseService) {

    }

    public async refreshListNoStatic() {
        if (!this.refreshing) {
            this.refreshing = true;
            const snapshot = await firebase.database().ref('public_documents/by_document_id').once('value');
            const documents = snapshot.val();
            this.nameByIdNoStatic = Object.keys(documents).filter((id) => !documents[id]['is_static_document']).map((id) => {
                return {'id': id, 'name': documents[id]['name']};
            });
            this.refreshing = false;
        }
    }

    public async refreshListStatic() {
        if (!this.refreshing) {
            this.refreshing = true;
            const snapshot = await firebase.database().ref('public_documents/by_document_id').once('value');
            const documents = snapshot.val();
            this.nameByIdStatic = Object.keys(documents).filter((id) => documents[id]['is_static_document']).map((id) => {
                return {'id': id, 'name': documents[id]['name']};
            });
            this.refreshing = false;
        }
    }

    public async autocomplete(value: string): Promise<AutocompleteInterface[]> {
        if (!value) {
            return [];
        }
        if (!this.nameByIdNoStatic) {
            await this.refreshListNoStatic();
        }
        return matchSorter(this.nameByIdNoStatic, value, {keys: ['name']});
    }

    public async autocompleteStatic(value: string): Promise<AutocompleteInterface[]> {
        if (!value) {
            return [];
        }
        if (!this.nameByIdStatic) {
            await this.refreshListStatic();
        }
        return matchSorter(this.nameByIdStatic, value, {keys: ['name']});
    }

    public async searchDocuments(text: string): Promise<PublicLegalDocument[]> {
        const result: any = await Promise.all((await this.cloud.searchDocumentClient(text)).map(async (doc) => {
            const d = PublicLegalDocument.fromJSON(doc);
            d.availableLanguages = {};
            d.availableLanguages[d.documentLanguage] = d.id;
            if (doc.translation_language) {
                d.availableLanguages[doc.translation_language] = doc.translation_id;
            }
            return d;
        }));
        return result;
    }

    public async searchDocumentsAdmin(text: string): Promise<PublicLegalDocument[]> {
        const result: any = await Promise.all((await this.cloud.searchDocumentAdmin(text)).map(async (doc) => {
            return PublicLegalDocument.fromJSON(doc);
        }));
        return result;
    }

    public async searchDocumentsAdminWithoutStaticDocuments(text: string): Promise<PublicLegalDocument[]> {
        const result: any[] = await Promise.all((await this.cloud.searchDocumentAdmin(text)).map(async (doc) => {
            return await PublicLegalDocument.fromJSON(doc);
        }));
        return result.filter((d) => !d.isStaticDocument);
    }

    public async searchPublishedDocumentsAdmin(text: string): Promise<PublicLegalDocument[]> {
        const result: any = await Promise.all((await this.cloud.searchPublishedDocumentAdmin(text)).map(async (doc) => {
            return await PublicLegalDocument.fromJSON(doc);
        }));
        return result;
    }

    public async getListDocuments(numDocuments: number, cursor?: any, questionId?: string): Promise<FeedInterface> {

        if (!questionId) {
            return await this.getListDocumentsGeneral(numDocuments, cursor);
        } else {
            if (!this.questionRootId) {
                this.questionRootId = (await firebase.database().ref('question_root/id').once('value')).val();
            }
            if (questionId === this.questionRootId) {
                return await this.getListDocumentsGeneral(numDocuments, cursor);
            }
            return await this.getListDocumentsQuestion(numDocuments, questionId, cursor);
        }

    }

    public getListDocumentsObs(questionId?: string, search?: string, directoryId?: string): Observable<PublicLegalDocument[]> {
        if (directoryId && questionId) {

        }
        if (!questionId && (!search || search.length <= 0) && !directoryId) {
            return new Observable((observer) => {
                this.getListDocumentsGeneralObs(observer);
            });
        } else if (search && directoryId) {
            return new Observable((observer) => {
                this.getListDocumentsDirectorySearchObs(search, directoryId, observer);
            });
        } else if (directoryId) {
            return new Observable((observer) => {
                this.getListDocumentsDirectoryObs(directoryId, observer);
            });
        } else if (!search || search.length <= 0) {
            return new Observable((observer) => {
                this.getListDocumentsQuestionObs(questionId, observer);
            });
        } else if (!questionId) {
            return new Observable((observer) => {
                this.getListSearchObs(search, observer);
            });
        } else {
            return new Observable((observer) => {
                this.getListDocumentsQuestionSearchObs(search, questionId, observer);
            });
        }

    }

    private removeUndefiened(array: any[]) {
        const newArray = new Array();
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                newArray.push(array[i]);
            }
        }
        return newArray;

    }
    private async getListDocumentsGeneralObs(observer: any) {
        let cursor;
        let latestCursor  = 'cursor';
        let tmp;
        let feed = [];
        while (cursor !== latestCursor) {
            tmp = await this.getListDocumentsGeneral(40, cursor);
            latestCursor = cursor;
            cursor = tmp['cursor'];
            feed = feed.concat(this.removeUndefiened(tmp['documents']));

            observer.next(feed);

        }
        observer.complete();
    }

    private async getListDocumentsQuestionObs(questionId: string, observer: any) {
        let cursor;
        let latestCursor = 'cursor';
        let tmp;
        let feed = [];
        while (cursor !== latestCursor) {
            tmp = await this.getListDocumentsQuestion(40, questionId, cursor);
            latestCursor = cursor;
            cursor = tmp['cursor'];
            feed = feed.concat(this.removeUndefiened(tmp['documents']));
            observer.next(feed);
        }
        observer.complete();
    }

    private async getListSearchObs(text: string, observer: any) {
        observer.next(this.removeUndefiened(await this.searchDocuments(text)));
        observer.complete();
    }

    private async getListDocumentsDirectoryObs(directoryId: string, observer: any) {
        let cursor;
        let latestCursor = 'cursor';
        let tmp;
        let feed = [];
        while (cursor !== latestCursor) {
            tmp = await this.getListDocumentsDirectory(40, directoryId, cursor);
            latestCursor = cursor;
            cursor = tmp['cursor'];
            feed = feed.concat(this.removeUndefiened(tmp['documents']));
            observer.next(feed);
        }
        observer.complete();
    }

    private async getListDocumentsQuestionSearchObs(search: string, questionId: string, observer: any) {
        let cursor;
        let latestCursor = 'cursor';
        let tmp;
        let feed = [];
        while (cursor !== latestCursor) {
            tmp = await this.getListDocumentsQuestion(40, questionId, cursor);
            latestCursor = cursor;
            cursor = tmp['cursor'];
            feed = feed.concat(this.removeUndefiened(tmp['documents']));
            observer.next(matchSorter(feed, search, {threshold: matchSorter.rankings.MATCHES, keys: ['name', 'tags']}));
        }
        observer.complete();
    }

    private async getListDocumentsDirectorySearchObs(search: string, directoryId: string, observer: any) {
        let cursor;
        let latestCursor = 'cursor';
        let tmp;
        let feed = [];
        while (cursor !== latestCursor) {
            tmp = await this.getListDocumentsDirectory(40, directoryId, cursor);
            latestCursor = cursor;
            cursor = tmp['cursor'];
            feed = feed.concat(this.removeUndefiened(tmp['documents']));
            observer.next(matchSorter(feed, search, {threshold: matchSorter.rankings.MATCHES, keys: ['name', 'tags']}));
        }
        observer.complete();
    }


    private async getListDocumentsQuestion(numDocuments: number, questionId: string, cursor?: any): Promise<FeedInterface>  {
        const ref = firebase.database().ref(await getQuestionReferenceForSnapshot(this.enviromentService.platformId(), questionId));
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }
        const feedPromise: Promise<PublicLegalDocument>[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feedPromise.push(this.docService.getPublicDocumentByVersionIdPlatform(snpsht.val()));
        });
        const feed: PublicLegalDocument[] = await Promise.all(feedPromise);
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    private async getListDocumentsDirectory(numDocuments: number, directoryId: string, cursor?: any): Promise<FeedInterface>  {
        const ref = firebase.database().ref(await getDirectoryReferenceForSnapshot(this.enviromentService.platformId(), directoryId));
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }
        const feedPromise: Promise<PublicLegalDocument>[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feedPromise.push(this.docService.getPublicDocumentByVersionIdPlatform(snpsht.val()));
        });
        const feed: PublicLegalDocument[] = await Promise.all(feedPromise);
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }
    private async getListDocumentsGeneral(numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const ref = firebase.database().ref('platform_public_documents/' + this.enviromentService.platformId() + '/by_version_id');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        const feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    public async getListDocumentsGeneralAdminPlatform(platformId: string, numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const ref = firebase.database().ref('platform_public_documents/' + platformId + '/by_version_id');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        const feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    public async getListDocumentsAdmin(numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const ref = firebase.database().ref('admin_documents');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        const feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }
    public async getListDocumentsAdminId(numDocuments: number, asc: boolean, cursor?: any): Promise<FeedInterface> {
        if (cursor === 'end') {
            return {
                cursor: 'end',
                documents: []
            };
        }
        const ref = firebase.database().ref('admin_documents');
        let snapshot;
        if (cursor && asc) {
            snapshot = await ref.orderByChild('client_id_numeric').startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else if (asc) {
            snapshot = await ref.orderByChild('client_id_numeric').limitToFirst(numDocuments).once('value');
        } else if (cursor) {
            snapshot = await ref.orderByChild('client_id_numeric').endAt(cursor).limitToLast(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('client_id_numeric').limitToLast(numDocuments).once('value');
        }

        let feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            if (asc) {
                newCursor = snpsht.val()['client_id_numeric'];
            } else {
                newCursor = newCursor === cursor ? snpsht.val()['client_id_numeric'] : newCursor;
            }
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        if (!asc) {
            feed = feed.reverse();
        }
        if (feed.length < numDocuments) {
            newCursor = 'end';
        }
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }
    public async getListDocumentsAdminName(numDocuments: number, asc: boolean, cursor?: any): Promise<FeedInterface> {
        if (cursor === 'end') {
            return {
                cursor: 'end',
                documents: []
            };
        }
        const ref = firebase.database().ref('admin_documents');
        let snapshot;
        if (cursor && asc) {
            snapshot = await ref.orderByChild('name').startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else if (asc) {
            snapshot = await ref.orderByChild('name').limitToFirst(numDocuments).once('value');
        } else if (cursor) {
            snapshot = await ref.orderByChild('name').endAt(cursor).limitToLast(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('name').limitToLast(numDocuments).once('value');
        }

        let feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            if (asc) {
                newCursor = snpsht.val()['name'];
            } else {
                newCursor = newCursor === cursor ? snpsht.val()['name'] : newCursor;
            }
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        if (!asc) {
            feed = feed.reverse();
        }
        if (feed.length < numDocuments) {
            newCursor = 'end';
        }
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    /**
     * Get the question by id.
     * If id is not defined it will return the root question
     * @param questionId
     */
    public async getQuestion(questionId?: string): Promise<QuestionInterface> {
        let question;
        if (!questionId) {
            question = await getRootQuestionPlatformExpertSystem(this.enviromentService.platformId());
            if (!question) {
                question = await getRootQuestionDefaultExpertSystem();
            }
        } else {
            question = await getQuestionPlatformExpertSystem(this.enviromentService.platformId(), questionId);
            if (!question) {
                question = await getQuestionDefaultExpertSystem(questionId);
            }
        }
        return question;
    }

    /**
     * Get the directory by id.
     * If id is not defined it will return the root directory
     * @param directoryId
     */
    public async getDirectory(directoryId?: string): Promise<DirectoryInterface> {
        let directory;
        if (!directoryId) {
            directory = await getRootDirectoryPlatformDirectorySystem(this.enviromentService.platformId());
            if (!directory) {
                directory = await getRootDirectoryDefaultDirectorySystem();
            }
        } else {
            directory = await getDirectoryPlatformDirectorySystem(this.enviromentService.platformId(), directoryId);
            if (!directory) {
                directory = await getDirectoryDefaultDirectorySystem(directoryId);
            }
        }
        return directory;
    }

    public async getIcons(): Promise<IconNode[]> {

        let icons = await  getAllIconPlatformSystem(this.enviromentService.platformId());
        if (!icons) {
            icons = await  getAllIconDefaultSystem();
        }
        return icons;
    }

    private getDateFormated(d: Date): string {
        if (!d){
            return undefined;
        }
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    }

    public async getAllAdminDocumentsCsv(): Promise<string> {
        const allJsons = [];
        let cursor;
        let res = await this.getListDocumentsAdmin(100, cursor);
        let documents = res.documents;
        cursor = res.cursor;
        while (documents.length > 0) {
            documents.forEach((doc) => {
               allJsons.push({
                   UUID: doc.version_id,
                   MilcontratosId: doc.clientId,
                   MilcontratosId2: doc.clientSecondaryId,
                   TextId: doc.textId,
                   Publicado: doc.isPublished ? 'Si' : 'No',
                   Estatico: doc.isStaticDocument ? 'Si' : 'No',
                   Nombre: doc.name,
                   'DescripciÃ³n corta': doc.short_description.replace(/[\n\r]/g,' '),
                   'Fecha PublicaciÃ³n': this.getDateFormated(doc.publishDate),
                   'Fecha modificaciÃ³n': this.getDateFormated(doc.lastModificationDate),
                   'Tipos': doc.types ? doc.types.map((t) => t.name).join(',') : undefined,
                   'Plataformas': doc.platforms ? doc.platforms.map((p) => p.name).join(',') : undefined,
                   'Keywords': doc.tags ? doc.tags.join(',') : undefined

               });
            });


            res = await this.getListDocumentsAdmin(100, cursor);
            documents = res.documents;
            cursor = res.cursor;
        }
        const fields = ['UUID', 'MilcontratosId', 'MilcontratosId2', 'TextId', 'Publicado', 'Estatico', 'Nombre', 'DescripciÃ³n corta',
            'Fecha PublicaciÃ³n', 'Fecha modificaciÃ³n', 'Tipos', 'Plataformas', 'Keywords'];
        const json2csvParser = new Parser({ fields , delimiter: ';', flatten: true});
        const csv = json2csvParser.parse(allJsons);


        return csv;
    }

}
