import { Injectable } from '@angular/core';

import { getCall, postCall } from '../../utils/CloudFunctions';
import { EnvironmentBaseService } from './environment-base.service';
import {
    EditablePublicDocument, LegalDocument, ProfileInterface,
    StaticLegalDocument
} from 'milcontratos-database';


@Injectable({
    providedIn: 'root'
})
export class CloudFunctionsService {
    constructor(private env: EnvironmentBaseService) {

    }
    async getAdminToken() {
        const json = await getCall(`${this.env.urlFunctions()}/adminTokenGetAdminToken`, this.env.platformId());
        return json['token'];
    }

    async getPlatformManagerToken() {
        const json = await postCall(`${this.env.urlFunctions()}/adminTokenGetManagerPlatformToken`, {}, this.env.platformId());
        return json['token'];
    }

    async getDocumentToken(documentId: string, filledDocumentId?: string) {
        const body = {
            document_id: documentId
        };
        if (filledDocumentId) {
            body['filled_document_id'] = filledDocumentId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsGetDocumentToken`, body, this.env.platformId());
        return json['token'];
    }

    async getDocumentTokenSign(documentId: string, signId: string, ownerId?: string, filledDocumentId?: string, emailCode?: string) {
        const body = {
            sign_id: signId,
            document_id: documentId
        };
        if (filledDocumentId) {
            body['filled_document_id'] = filledDocumentId;
        }
        if (emailCode) {
            body['email_code'] = emailCode;
        }
        if (ownerId) {
            body['owner_id'] = ownerId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsGetDocumentToken`, body, this.env.platformId());
        return json['token'];
    }

    async getFilledDocumentToken(filledDocumentId: string, signId?: string, emailCode?: string, ownerId?: string) {
        const body = {
            filled_document_id: filledDocumentId
        };
        if (signId) {
            body['sign_id'] = signId;
        }
        if (emailCode) {
            body['email_code'] = emailCode;
        }
        if (ownerId) {
            body['owner_id'] = ownerId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsGetFilledDocumentToken`, body, this.env.platformId());
        return json['token'];
    }



    async startJob(jobId: string, isTranslationDocument: boolean) {
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsNewDocumentJob`, {
            job_id: jobId,
            translation_document: !!isTranslationDocument
        }, this.env.platformId());
        return json;
    }

    async newDocument(jobId: string, document: LegalDocument | StaticLegalDocument, last_version_id?: string, free_update?: boolean) {
        const body = {
            job_id: jobId,
            document: document
        };

        if (last_version_id) {
            body['last_version_id'] = last_version_id;
            body['free_update'] = !!free_update;
        }

        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsPushNewDocument`, body, this.env.platformId());
    }

    async newDocumentFromMarge(jobId: string, document: LegalDocument | StaticLegalDocument, toMergeDocumentsId: string[],
        free_update?: boolean) {
        const body = {
            job_id: jobId,
            document: document,
            to_merge_documents_id: toMergeDocumentsId,
            free_update: !!free_update
        };

        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsPushNewDocument`, body, this.env.platformId());
    }

    async addLanguageDocument(jobId: string, document: LegalDocument, parentDocumentId: string) {
        const body = {
            job_id: jobId,
            document: document,
            parent_document_id: parentDocumentId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsAddLanguages`, body, this.env.platformId());
    }

    async addDocumentToResponse(response_id: string, document_version_id: string) {
        const body = {
            response_id: response_id,
            document_version_id: document_version_id
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemAddDocumentToResponse`, body, this.env.platformId());
        return json;
    }

    async addQuestionFromResponse(question_text: string, response_id: string) {
        const body = {
            response_id: response_id,
            question_text: question_text
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemAddQuestionFromResponse`, body, this.env.platformId());
        return json;
    }
    async addResponseToQuestion(question_id: string, response_text: string) {
        const body = {
            question_id: question_id,
            response_text: response_text
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemAddResponseToQuestion`, body, this.env.platformId());
        return json;
    }
    async removeQuestionFromResponse(question_id: string) {
        const body = {
            question_id: question_id
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemRemoveQuestionFromResponse`, body, this.env.platformId());
        return json;
    }
    async quitDocumentFromResponse(response_id: string, document_version_id: string) {
        const body = {
            response_id: response_id,
            document_version_id: document_version_id
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemQuitDocumentFromResponse`, body, this.env.platformId());
        return json;
    }
    async removeResponseToQuestion(response_id: string) {
        const body = {
            response_id: response_id
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemRemoveResponseToQuestion`, body, this.env.platformId());
        return json;
    }

    async expertSystemNewStructure(platformId: string, structure: any) {
        const body = {
            platform_target: platformId,
            structure: structure
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemSaveNewQuestionStructure`, body, this.env.platformId());
        return json;
    }

    async directoryNewStructure(platformId: string, structure: any) {
        const body = {
            platform_target: platformId,
            structure: structure
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminExpertSystemSaveNewDirectoryStructure`, body, this.env.platformId());
        return json;
    }

    async initSearcher(offset: number) {
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsCreateSearcher`, { offset: offset }, this.env.platformId());
    }
    async searchDocumentClient(text: string) {
        const body = {
            text: text
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientDocumentsSearcher`, body, this.env.platformId(), true);
        return json['results'];
    }

    async searchDocumentAdmin(text: string) {
        const body = {
            text: text
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsSearch`, body, this.env.platformId());
        return json['results'];
    }

    async searchPublishedDocumentAdmin(text: string) {
        const body = {
            text: text
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsSearchPublished`, body, this.env.platformId());
        return json['results'];
    }

    async getPackDocumentsIncluded(typesName: string[]) {
        const body = {
            types_name: typesName
        };

        const json = await postCall(`${this.env.urlFunctions()}/clientPacksGetDocumentsIncluded`, body, this.env.platformId(), true);
        return json['results'];
    }


    async packsFreePurchase(packId: string, dealText?: string) {
        const body = {
            pack_id: packId
        };
        if (dealText) {
            body['deal_text'] = dealText;
        }
        return await postCall(`${this.env.urlFunctions()}/clientPacksFreePurchase`, body, this.env.platformId());
    }




    async assignMessageChat(reassign?: boolean) {
        const body = {
            reassign: reassign ? reassign : false
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientChatReassignMessage`, body, this.env.platformId());
        return json;
    }

    async contactUs(name: string, email: string, textBody: string, subject?: string) {
        const body = {
            name: name,
            email: email,
            body: textBody
        };
        if (subject) {
            body['subject'] = subject;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientChatContactUs`, body, this.env.platformId());
        return json;
    }

    async geUserByUID(uid: string) {
        const body = {
            uid: uid
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientGetUserByUID`, body, this.env.platformId());
        return json;
    }

    async createFillDocument(documentId: string) {
        const body = {
            document_id: documentId
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsCreateFillDocument`, body, this.env.platformId());
        return json['id'];
    }

    async generalDocumentAdquisition(documentId: string, useSubscription: boolean, packPurchaseId?: string, dealText?: string, actualDocumentId?: string) {
        const body = {
            document_id: documentId,
            use_subscription: useSubscription
        };
        if (packPurchaseId) {
            body['pack_purchase_id'] = packPurchaseId;
        }

        if (dealText) {
            body['deal_text'] = dealText;
        }

        if (actualDocumentId) {
            body['actual_document_id'] = actualDocumentId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientDocumentsGeneralDocumentAcquisition`, body, this.env.platformId());
        return json;
    }

    async hasUserExceedMaxDocumentAcquisition(): Promise<boolean> {
        const body = {};
        const json = await postCall(`${this.env.urlFunctions()}/clientDocumentsHasUserExceedMaxDocumentAcquisition`,
            body, this.env.platformId());
        return json['hasUserExceedMaxDocumentAcquisition'];
    }

    async hasUserExceedMaxDocumentPromotion(): Promise<boolean> {
        const body = {};
        const json = await postCall(`${this.env.urlFunctions()}/clientDocumentsHasUserExceedMaxDocumentPromotion`,
            body, this.env.platformId());
        return json['hasUserExceedMaxDocumentPromotion'];
    }

    async completeFillDocument(documentId: string, filledDocumentId: string, noSign: boolean): Promise<boolean> {
        const body = {
            no_sign: noSign,
            filled_document_id: filledDocumentId,
            document_id: documentId
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsCompleteFillDocument`, body, this.env.platformId());
        return json['no_signed'];
    }

    async undoSigningState(documentId: string, filledDocumentId: string) {
        const body = {
            filled_document_id: filledDocumentId,
            document_id: documentId
        };
        await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsUndoSigning`, body, this.env.platformId());
    }

    async removeFillingDocument(documentId: string, filledDocumentId: string) {
        const body = {
            filled_document_id: filledDocumentId,
            document_id: documentId
        };
        await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsRemoveFilledDocuments`, body, this.env.platformId());
    }

    // region Signing process

    async addSigner(filledDocumentId: string, signId: string, email: string) {
        const body = {
            filled_document_id: filledDocumentId,
            sign_id: signId,
            email: email
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsAddSigner`, body, this.env.platformId());
        return json;
    }

    async verifySigningEmailCode(signId: string, ownerId: string, filledDocumentId: string, emailCode: string) {
        const body = {
            owner_id: ownerId,
            filled_document_id: filledDocumentId,
            sign_id: signId,
            email_code: emailCode
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsVerifySigningEmailCode`, body, this.env.platformId());
        return json;
    }

    async signWithPhoneByAssignation(signId: string, phone: string) {
        const body = {
            sign_id: signId,
            phone: phone
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsSignWithPhone`, body, this.env.platformId());
        return json;
    }
    async signWithPhoneByEmail(signId: string, phone: string, ownerId: string, filledDocumentId: string, emailCode: string) {
        const body = {
            owner_id: ownerId,
            filled_document_id: filledDocumentId,
            sign_id: signId,
            email_code: emailCode,
            phone: phone
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsSignWithPhone`, body, this.env.platformId());
        return json;
    }

    async completeSign(signId: string, smsCode: string) {
        const body = {
            sign_id: signId,
            sms_code: smsCode
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsCompleteSign`, body, this.env.platformId());
        return json;
    }

    // endregion

    async renderFillDocument(filledDocumentId: string, isCompleted?: boolean, ownerId?: string) {
        const body = {
            filled_document_id: filledDocumentId,
            is_completed: !!isCompleted
        };
        if (ownerId) {
            body['owner_id'] = ownerId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsRenderDocument`, body, this.env.platformId());
        return json['job_id'];
    }

    async renderAdminFillDocument(filledDocumentPath: string, documentPath: string, language: string) {
        const body = {
            filled_document_path: filledDocumentPath,
            language: language,
            document_path: documentPath
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsRenderTemporal`, body, this.env.platformId());
        return json['job_id'];
    }

    async createSubscriptionPaypal(subscriptionId) {
        const body = {
            subscription_id: subscriptionId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminSubscriptionCreatePaypal`, body, this.env.platformId());
        return json;
    }

    async sendAllEmails(email, baseUrl?: string, platformName?: string) {
        const body = {
            email: email
        };

        body['base_url'] = baseUrl;
        body['platform_name'] = platformName;
        const json = await postCall(`${this.env.urlFunctions()}/adminRootSendAllEmails`, body, this.env.platformId());
        return json;
    }

    async createInvoiceTables(pool: boolean, addInvoices: boolean) {
        const json = await postCall(`${this.env.urlFunctions()}/adminRootCreateTables`,
            { pool: !!pool, add_invoices: !!addInvoices }, this.env.platformId());
        return json;
    }

    async refactorDocument(offset: number) {
        const json = await postCall(`${this.env.urlFunctions()}/adminRootDocumentRefactor`, { offset: offset }, this.env.platformId());
    }

    async generateImagePreviewDocument(documentId: string) {
        const json = await postCall(`${this.env.urlFunctions()}/adminRootImageDocumentPreview`,
            { document_id: documentId }, this.env.platformId());
    }

    async cancelSubscription() {
        return await postCall(`${this.env.urlFunctions()}/clientSubscriptionCancel`, {}, this.env.platformId());
    }

    // region card payment


    async redsysSubscription(subscriptionId: string, isYearly: boolean) {
        const body = {
            subscription_id: subscriptionId,
            is_yearly: isYearly
        };
        return await postCall(`${this.env.urlFunctions()}/clientSubscriptionCardAddToSubscription`, body, this.env.platformId());
    }

    async documentsMakeRealexTransaction(documentId: string, useSubscription: boolean, purchasedPackId: string | undefined,
        legalAdvice: boolean, pan: string, expirationYear: number, expirationMonth: number, cvn: string,
        name: string, cardType: string, dealText?: string) {
        const body = {
            document_id: documentId,
            purchased_pack_id: purchasedPackId,
            use_subscription: useSubscription,
            legal_advice: legalAdvice,
            pan: pan,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            cvn: cvn,
            name: name,
            cardType: cardType
        };

        if (dealText) {
            body['deal_text'] = dealText;
        }
        return await postCall(`${this.env.urlFunctions()}/clientDocumentsMakeRealexTransaction`, body, this.env.platformId());
    }

    async packsMakeRealexTransaction(packId: string, pan: string,
        expirationYear: number, expirationMonth: number, cvn: string, name: string, cardType: string,
        dealText?: string) {
        const body = {
            pack_id: packId,
            pan: pan,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            cvn: cvn,
            name: name,
            cardType: cardType
        };
        if (dealText) {
            body['deal_text'] = dealText;
        }
        return await postCall(`${this.env.urlFunctions()}/clientPacksMakeRealexTransaction`, body, this.env.platformId());
    }

    async packsHPPRealexTransaction(packId: string, dealText?: string) {
        const body = {
            pack_id: packId
        };
        if (dealText) {
            body['deal_text'] = dealText;
        }
        return await postCall(`${this.env.urlFunctions()}/clientPacksStartHppRealexTransaction`, body, this.env.platformId());
    }

    async documentsHPPRealexTransaction(documentId: string, useSubscription: boolean, purchasedPackId: string | undefined,
        legalAdvice: boolean, dealText?: string) {
        const body = {
            document_id: documentId,
            purchased_pack_id: purchasedPackId,
            use_subscription: useSubscription,
            legal_advice: legalAdvice
        };
        if (dealText) {
            body['deal_text'] = dealText;
        }
        return await postCall(`${this.env.urlFunctions()}/clientDocumentsStartHppRealexTransaction`, body, this.env.platformId());
    }

    async legalAdviceHPPRealexTransaction(filledDocumentId: string) {
        const body = {
            filled_document_id: filledDocumentId
        };
        return await postCall(`${this.env.urlFunctions()}/clientLegalAdviceStartHppRealexTransaction`, body, this.env.platformId());
    }

    async legalAdviceMakeRealexTransaction(filledDocumentId: string, pan: string, expirationYear: number, expirationMonth: number,
        cvn: string, name: string, cardType: string) {
        const body = {
            filled_document_id: filledDocumentId,
            pan: pan,
            expiration_year: expirationYear,
            expiration_month: expirationMonth,
            cvn: cvn,
            name: name,
            cardType: cardType
        };
        return await postCall(`${this.env.urlFunctions()}/clientLegalAdviceMakeRealexTransaction`, body, this.env.platformId());
    }

    // endregion

    async editPublicDocument(documentId: string, documentContent: EditablePublicDocument, isSecondaryLanguage: boolean) {
        const body = {
            document_id: documentId,
            document_content: documentContent,
            is_secondary_language: isSecondaryLanguage
        };
        await postCall(`${this.env.urlFunctions()}/adminDocumentsEditPublicContent`, body, this.env.platformId());
    }

    async giftDocument(documentId: string, userId: string, legalAdvice?: boolean) {
        const body = {
            document_id: documentId,
            user_id: userId,
            legal_advice: !!legalAdvice
        };
        await postCall(`${this.env.urlFunctions()}/adminPurchaseFreeDocument`, body, this.env.platformId());
    }

    async giftPack(packId: string, userId: string) {
        const body = {
            pack_id: packId,
            user_id: userId
        };
        await postCall(`${this.env.urlFunctions()}/adminPurchaseFreePack`, body, this.env.platformId());
    }

    async giftAllDocuments(userId: string) {
        const body = {
            user_id: userId
        };
        await postCall(`${this.env.urlFunctions()}/adminPurchaseAllDocuments`, body, this.env.platformId());
    }

    async removeUnpublished(documentId: string) {
        const body = {
            document_id: documentId
        };
        await postCall(`${this.env.urlFunctions()}/adminDocumentsRemoveUnpublished`, body, this.env.platformId());
    }

    async removePublished(documentId: string) {
        const body = {
            document_id: documentId
        };
        await postCall(`${this.env.urlFunctions()}/adminDocumentsRemovePublished`, body, this.env.platformId());
    }

    async documentsPublish(documentId: string) {
        const body = {
            document_id: documentId
        };
        await postCall(`${this.env.urlFunctions()}/adminDocumentsPublish`, body, this.env.platformId());
    }

    async zipBackup(documentId: string): Promise<string> {
        const body = {
            document_id: documentId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsZipBackup`, body, this.env.platformId());
        return json['path'];
    }

    async completeReview(filledDocumentId: string, bodyText: string): Promise<void> {
        const body = {
            filled_document_id: filledDocumentId,
            body: bodyText
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminDocumentsCompleteReview`, body, this.env.platformId());
    }


    async usersPaginationList(numUsers: number, cursor?: string, onlyRoot?: boolean,
        onlyRegularUsers?: boolean, onlyHasPermissions?: boolean,
        byPermissionId?: string): Promise<{ users: ProfileInterface[], cursor: string }> {
        const body = {
            number_users: numUsers,
            only_root: !!onlyRoot,
            only_regular_users: !!onlyRegularUsers,
            only_has_permissions: !!onlyHasPermissions
        };
        if (cursor) {
            body['cursor'] = cursor;
        }
        if (byPermissionId) {
            body['permission_id'] = byPermissionId;
        }
        const json = await postCall(`${this.env.urlFunctions()}/adminUsersPaginationList`, body, this.env.platformId());
        return json;
    }

    async usersToggleActiveUser(userId: string, disabled: boolean): Promise<void> {
        const body = {
            user_id: userId,
            disabled: disabled
        };
        await postCall(`${this.env.urlFunctions()}/adminUsersToggleActiveUser`, body, this.env.platformId());
    }

    async usersAddUser(name: string, email: string, password: string, platformId: string, emailVerified?: boolean) {
        const body = {
            user_name: name,
            user_email: email,
            user_password: password,
            platform_target: platformId,
            email_verified: emailVerified
        };
        await postCall(`${this.env.urlFunctions()}/adminUsersAddUser`, body, this.env.platformId());
    }

    async startRemoveAccount(email?: string): Promise<void> {
        const body = {};
        if (email) {
            body['email'] = email;
        }
        await postCall(`${this.env.urlFunctions()}/clientUserStartRemoveUser`, body, this.env.platformId());

    }

    async removeAccount(emailCode: string): Promise<void> {
        const body = {
            email_code: emailCode
        };
        await postCall(`${this.env.urlFunctions()}/clientUserRemoveUser`, body, this.env.platformId());

    }

    async sendEmailVerification(): Promise<void> {
        const body = {};
        await postCall(`${this.env.urlFunctions()}/clientUserSendEmailVerification`, body, this.env.platformId());

    }

    async completeEmailVerification(emailCode: string): Promise<void> {
        const body = {
            email_code: emailCode
        };
        await postCall(`${this.env.urlFunctions()}/clientUserCompleteEmailVerification`, body, this.env.platformId(), true);

    }

    async getIndividualUser(userId: string): Promise<ProfileInterface> {
        const body = {
            user_id: userId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminUsersGetIndividual`, body, this.env.platformId());
        return json;
    }

    async addPublicDocument(platformId: string, documentId: string, addDocument: boolean): Promise<ProfileInterface> {
        const body = {
            platform_id_to_add: platformId,
            document_id: documentId,
            add_document: addDocument
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformAddPublicDocument`, body, this.env.platformId());
        return json;
    }


    async addAllPublicDocument(platformId: string, addDocuments: boolean): Promise<ProfileInterface> {
        const body = {
            platform_id: platformId,
            add_documents: addDocuments
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformAddAllPublicDocument`, body, this.env.platformId());
        return json;
    }

    async createPlatformInvoiceTable(platformId: string): Promise<void> {
        const body = {
            platform_target: platformId,
        };
        await postCall(`${this.env.urlFunctions()}/adminPlatformCreatePlatformInvoiceTable`, body, this.env.platformId());
    }

    async getMetricValue(column: string, platformId: string, from: Date, to?: Date): Promise<number> {
        const body = {
            column: column,
            platform_target: platformId,
            from_date: from.toISOString()
        };
        if (to) {
            body['to_date'] = to.toISOString();
        }
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformGetMetric`, body, this.env.platformId());
        return json['value'];
    }

    async createApiKey(platformId: string): Promise<string> {
        const body = {
            platform_target: platformId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformCreateAPIKey`, body, this.env.platformId());
        return json['key'];
    }

    async listApiKeys(platformId: string): Promise<string[]> {
        const body = {
            platform_target: platformId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformGetAPIKeys`, body, this.env.platformId());
        return json['keys'];
    }

    async removeApiKey(platformId: string, key: string) {
        const body = {
            platform_target: platformId,
            key: key
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminPlatformRemoveAPIKey`, body, this.env.platformId());
    }

    async uploadAttachmentForReview(filledDocumentId: string, contentType: string,
        fileName: string): Promise<{ path: string, token: string }> {
        const body = {
            filled_document_id: filledDocumentId,
            content_type: contentType,
            file_name: fileName,
        };

        return await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsUploadAttachmentForReview`, body, this.env.platformId());
    }

    async uploadAttachmentForAnnex(filledDocumentId: string, contentType: string,
        fileName: string): Promise<{ path: string, token: string }> {
        const body = {
            filled_document_id: filledDocumentId,
            content_type: contentType,
            file_name: fileName,
        };

        return await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsUploadAttachmentForAnnex`, body, this.env.platformId());
    }

    async updateAnnex(filledDocumentId: string, annexes: string[]) {
        const body = {
            filled_document_id: filledDocumentId,
            annexes: annexes
        };

        return await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsUpdateAnnex`, body, this.env.platformId());
    }

    async updateOverwrite(filledDocumentId: string, overwriteDocument: boolean, path?: string) {
        const body = {
            filled_document_id: filledDocumentId,
            overwrite_document: overwriteDocument
        };
        if (overwriteDocument) {
            body['path'] = path;
        }
        return await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsOverwriteFilledDocument`, body, this.env.platformId());
    }



    async sendDocumentToReview(filledDocumentId: string, title: string, bodyMessage: string, email: string,
        docxPath: string, phone?: string, paths?: string[]) {
        const body = {
            filled_document_id: filledDocumentId,
            title: title,
            body: bodyMessage,
            email: email,
            docx_path: docxPath
        };
        if (phone) {
            body['phone'] = phone;
        }
        if (paths && paths.length < 4) {
            body['paths'] = paths;
        }
        await postCall(`${this.env.urlFunctions()}/clientOwnedDocumentsToReview`, body, this.env.platformId());
    }

    async adminPaginateBills(numBills: number, platformName: string, offset?: number,
        fromDate?: string, toDate?: string): Promise<{ invoices: any[]; offset: number }> {
        const body = {
            num_bills: numBills,
            platform_name: platformName
        };
        if (offset) {
            body['offset'] = offset;
        }
        if (fromDate) {
            body['from_date'] = fromDate;
        }
        if (toDate) {
            body['to_date'] = toDate;
        }
        return await postCall(`${this.env.urlFunctions()}/adminBillingPaginateBills`, body, this.env.platformId());
    }

    async editInvoiceDetails(transactionId: string, details: any, userId: string) {
        const body = {
            transaction_id: transactionId,
            details: details,
            user_id: userId,
        };
        await postCall(`${this.env.urlFunctions()}/adminBillingEditInvoice`, body, this.env.platformId());
    }

    async setTransactionInvoiceDetails(transactionId: string, details: any) {
        const body = {
            transaction_id: transactionId,
            details: details
        };
        await postCall(`${this.env.urlFunctions()}/clientInvoiceGenerateInvoice`, body, this.env.platformId());
    }

    async clientRenderInvoice(transactionId: string) {
        const body = {
            transaction_id: transactionId
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientInvoiceRenderInvoice`, body, this.env.platformId());
        return json['job_id'];
    }

    async adminRenderInvoice(transactionId: string, userId: string, platformId: string) {
        const body = {
            transaction_id: transactionId,
            user_id: userId,
            platform_target: platformId
        };
        const json = await postCall(`${this.env.urlFunctions()}/adminBillingRenderInvoice`, body, this.env.platformId());
        return json['job_id'];
    }

    async loginArag(token: string): Promise<string> {
        const body = {
            token: token
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserLoginArag`, body, this.env.platformId(), true);
        return json['token'];
    }

    async loginAlmudena(token: string): Promise<string> {
        const body = {
            token: token
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserLoginAlmudena`, body, this.env.platformId(), true);
        return json['token'];
    }

    async getPlatformIdByName(platformName: string): Promise<string> {
        const body = {
            platformName: platformName
        };
        const json = await postCall(`${this.env.urlFunctions()}/getPlatformIdByName`, body, this.env.platformId(), true);
        return json['platformId'];
    }

    async loginPlatform(email: string, password: string): Promise<string> {
        const body = {
            email: email,
            password: password
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserLoginPlatform`, body, this.env.platformId(), true);
        return json['token'];
    }

    async sendRecoveryPassword(email: string) {
        const body = {
            email: email
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserSendRecoveryPassword`, body, this.env.platformId(), true);
    }
    async completeRecoveryPassword(emailCode: string, newPassword: string) {
        const body = {
            email_code: emailCode,
            new_password: newPassword
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserCompleteRecoveryPassword`, body, this.env.platformId(), true);
    }

    async changePassword(oldPassword: string, newPassword: string) {
        const body = {
            old_password: oldPassword,
            new_password: newPassword
        };
        const json = await postCall(`${this.env.urlFunctions()}/clientUserCompleteRecoveryPassword`, body, this.env.platformId());
    }

   async loginPimec(token: string, email: string, nif: string): Promise<string> {
       const body = {
           token: token,
           email: email,
           nif: nif
       };
       const json = await postCall(`${this.env.urlFunctions()}/clientUserLoginPimeclegaltech`, body, this.env.platformId(), true);
       return json['token'];
    }

}
