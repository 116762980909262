import {validate, ValidationError} from 'class-validator';


function getErrors(errors: ValidationError[]): string[]{
    let textErrors: string[] = [];
    for (let i = 0; i < errors.length; i++) {
        if (errors[i].constraints) {
            textErrors = textErrors.concat( Object.keys(errors[i].constraints).map((key) => {
                return errors[i].constraints[key];
            }));
        }
        if (errors[i].children) {
            textErrors = textErrors.concat(getErrors(errors[i].children));
        }
    }
    return textErrors;

}

export async function validateModel(modelAny) {
    const errors = await validate(modelAny);
    if (errors.length > 0) {
        throw {
            errors: getErrors(errors)
        };
    }
}
