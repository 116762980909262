import { Injectable } from '@angular/core';
import uuidv4 from 'uuid/v4';
import * as firebase from 'firebase';
import {validateModel} from '../../utils/validation';
import {SubscriptionInterface, SubscriptionPlan} from 'milcontratos-database';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {removeEmpty} from '../../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionAdminService {

  constructor(private cloud: CloudFunctionsService) { }

    async createSubscription(subscription: SubscriptionInterface): Promise<SubscriptionPlan> {
        const seenTypes = [];
        for (let i = 0; i < subscription.not_included_types.length; i++) {
            if (seenTypes.indexOf(subscription.not_included_types[i].id) > -1) {
                throw {error: 'Do not repeat the same types'};
            }
            const type = (await firebase.database().ref('documents_types/' + subscription.not_included_types[i].id).once('value')).val();
            if (!type) {
                throw {error: 'not valid type'};
            }
            seenTypes.push(subscription.not_included_types[i].id);
        }
        const subscriptionObj = SubscriptionPlan.fromJSON(subscription);
        subscriptionObj.id = uuidv4();
        await validateModel(subscriptionObj);


        await firebase.database().ref('subscriptions/' + subscriptionObj.id).set(removeEmpty(subscriptionObj.toJSON()));
        await this.cloud.createSubscriptionPaypal(subscriptionObj.id);
        return subscriptionObj;
    }

    async updateSubscription(subscription: SubscriptionInterface): Promise<SubscriptionPlan> {
        const seenTypes = [];
        for (let i = 0; i < subscription.not_included_types.length; i++) {
            if (seenTypes.indexOf(subscription.not_included_types[i].id) > -1) {
                throw {error: 'Do not repeat the same types'};
            }
            const type = (await firebase.database().ref('documents_types/' + subscription.not_included_types[i].id).once('value')).val();
            if (!type) {
                throw {error: 'not valid type'};
            }
            seenTypes.push(subscription.not_included_types[i].id);
        }
        const subscriptionObj = SubscriptionPlan.fromJSON(subscription);
        await validateModel(subscriptionObj);
        const snapshot = await firebase.database().ref('subscriptions/' + subscriptionObj.id).once('value');
        const currentSubscription = SubscriptionPlan.fromJSON(snapshot.val());
        if (currentSubscription.monthlyPrice !== subscriptionObj.monthlyPrice
            || currentSubscription.yearlyPrice !== subscriptionObj.yearlyPrice) {
            // TODO Update price!
        }
        currentSubscription.notIncludedTypes = subscriptionObj.notIncludedTypes;
        currentSubscription.name = subscriptionObj.name;
        currentSubscription.description = subscriptionObj.description;
        currentSubscription.amount = subscriptionObj.amount;
        currentSubscription.orderKey = subscriptionObj.orderKey;
        currentSubscription.hasLegalAdvice = subscriptionObj.hasLegalAdvice;
        currentSubscription.tag = subscriptionObj.tag;
        currentSubscription.amountOfReviews = subscriptionObj.amountOfReviews;


        await firebase.database().ref('subscriptions/' + subscriptionObj.id).set(removeEmpty(subscriptionObj.toJSON()));
        return subscriptionObj;
    }

}
