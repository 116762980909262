import {Injectable} from '@angular/core';
import {UserService} from '../shared/user.service';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {OwnedDocument, OwnedFeedInterface} from 'milcontratos-database';
import * as firebase from 'firebase';
import {FilledFeedInterface, PublicSignature, SignatureFeedInterface} from 'milcontratos-database';

@Injectable({
    providedIn: 'root'
})
export class SignatureService {

    constructor(private auth: UserService,
                private cloud: CloudFunctionsService) {
    }

    async getPhoneCodeByAssignation(signId: string, phone: string) {
        await this.cloud.signWithPhoneByAssignation(signId, phone);
    }

    async getPhoneCodeByEmail(signId: string, phone: string, ownerId: string, filledDocumentId: string, emailCode: string) {
        await this.cloud.signWithPhoneByEmail( signId, phone, ownerId, filledDocumentId, emailCode);
    }

    async assignEmailSign( filledDocumentId: string, signId: string, email: string) {
        await this.cloud.addSigner(filledDocumentId, signId, email);
    }

    async sendCompletionCode(signId: string, smsCode: string) {
        return await this.cloud.completeSign(signId, smsCode);
    }

    public async getPaginateSign( numSigns: number, cursor?: any): Promise<SignatureFeedInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('signatures/' + user.uid );
        return await this.getPaginateSignaturesGeneric(ref, numSigns, cursor);
    }

    private async getPaginateSignaturesGeneric( ref: any, numSigns: number, cursor?: any): Promise<SignatureFeedInterface> {

        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numSigns + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numSigns).once('value');
        }
        let feed: PublicSignature[] = [];
        snapshot.forEach(snpsht => {
            feed.push(PublicSignature.fromJSON(snpsht.val()));
        });
        feed = feed ? feed.filter( (el) => !!el) : [];

        return {
            cursor: feed.length > 0 ? feed[feed.length - 1].id : undefined,
            documents: cursor ?  feed.slice(1, numSigns + 1) : feed
        };
    }


}
