import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Field } from 'milcontratos-database';
import { Subscription } from 'rxjs';
import { FieldErrorService } from '../../../services/client/field-error.service';

@Component({
    selector: 'app-single-options-field',
    template: `<div (mouseup)="onMouseUp($event)">
    <label class="input-label">{{field.caption}} <span *ngIf="field.description" class="info-icon"><i
                class="material-icons notranslate">info</i></span>
        <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
    </label>

    <!-- <select [ngModel]="docField" (ngModelChange)="sendInput($event)"
                materialize="FormSelect"
                [materializeSelectOptions]="field.options">
            <option [value]="-1">Selecciona una opciÃ³n ...,</option>
            <option *ngFor="let option of field.options; let oi = index" [value]="oi">{{option}}</option>
        </select> -->

    <!-- <label class="input-label">{{field.caption}} <span *ngIf="field.description" class="info-icon"><i class="material-icons notranslate">info</i></span>
            <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
        </label> -->
    <!-- <input [id]="'checkbox' + field.id + 'i' + index" class="with-gap styled-checkbox" type="checkbox"
               [ngModel]="value" (ngModelChange)="sendInput($event)"/> -->

    <!-- <div *ngFor="let option of field.options; let oi = index" >
            <input class="with-gap styled-checkbox" type="checkbox" value="option"/>{{option}}
        </div> -->

    <!-- <label [for]="'checkbox' + field.id + 'i' + index" class="checkbox">
                <input [id]="'checkbox' + field.id + 'i' + index" class="with-gap styled-checkbox" type="checkbox"
                       [ngModel]="value" (ngModelChange)="sendInput($event)"/>
                <span>{{field.caption}} <span *ngIf="field.description" class="info-icon"><i class="material-icons notranslate">info</i></span>
                    <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
                </span>
        </label> -->


    <!-- [ngModel]="oi" [value]="oi" -->
    <!-- <label  class="checkbox">
                <input  class="with-gap styled-checkbox" type="radio" [name]="field.id+'-openBtn'"
                    value="-1" [ngModel]="docField" (ngModelChange)="sendInput(-1)"/>
                <span>Seleccione una opciÃ³n...</span>
        </label> -->
    <!-- <button class="waves-effect waves-light btn next-button" (click)="changeShow()">Seleccione una opciÃ³n...</button> -->
    <div class="field-values-box">
        <!-- <input type="text" class="input-text"
                [value]="selectedOption"
                autocomplete="off"
                (focus)="openOptions()"/> -->
        <div style="border-bottom: 1px solid #9e9e9e; width: 100%;">
            <p type="text" class="input-text" style="margin-top: 16px;  width: 100%;" (click)="toggleOptions()">
                {{ (selectedOption.length>30)? (selectedOption | slice:0:30)+'...':(selectedOption) }}
                <!--<span class="info-icon" style="position: relative; top: 0px; left: calc(100% - 115px);">
                        <i class="material-icons notranslate">arrow_drop_down</i></span>-->
            </p>
        </div>
        <div *ngIf="opened" style="margin-top: 5px;">
            <div>
                <label class="checkbox">
                    <input autocomplete="off" class="with-gap styled-checkbox" type="radio" [name]="field.id" [value]="-1"
                        [ngModel]="docField" (ngModelChange)="sendInput(-1)" />
                    <span>Selecciona una opciÃ³...</span>
                </label>
            </div>
            <div *ngFor="let option of field.options; let oi = index">
                <label class="checkbox">
                    <input autocomplete="off" class="with-gap styled-checkbox" type="radio" [name]="field.id" [value]="oi"
                        [ngModel]="docField" (ngModelChange)="sendInput(oi)" (click)="clickIn(oi)" />
                    <span style="width: 100%;">{{option}}</span>
                </label>
            </div>
        </div>
    </div>

    <small class="error-message" *ngIf="error">Ãs necessari omplir aquest camp</small>

</div>
`,
    styles: [`.info-icon{position:relative;top:8px}.dropdown-box{position:relative;width:100%}.dropdown-selected{width:100%;padding-bottom:3px;border-bottom:1px solid #929699}.dropdown-selected:active{border-bottom:1px solid #4c52c1}.dropdown-options-list{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-flow:row wrap;-webkit-box-pack:start;justify-content:flex-start;-webkit-box-align:start;align-items:flex-start;align-content:flex-start;position:absolute;top:-15px;z-index:9999;width:100%;background-color:#fff;box-shadow:0 2px 2px 0 rgba(0,0,0,.14),0 1px 5px 0 rgba(0,0,0,.12),0 3px 1px -2px rgba(0,0,0,.2)}.dropdown-option{width:100%;border-bottom:1px solid #929699;padding:12px 15px}.dropdown-option:last-child{border:none}.dropdown-option:hover{background-color:#4c52c1;color:#fff}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}.input-label:hover .hover-field{display:block;min-width:300px}.input-label{position:relative}.error-message{color:red;font-size:10px}.checkbox:hover .hover-field{display:block;min-width:300px}.checkbox{position:relative}@media (max-width:767.98px){.input-label{font-size:14px}}`]
})
export class SingleOptionsFieldComponent implements OnInit, OnChanges, OnDestroy {

    @Input() field: Field;
    @Input() repeatIndex: number;
    @Input() docField: number;
    @Input() dropdownUp: boolean;
    @Output() sendText: EventEmitter<number> = new EventEmitter();

    selectedOption: string = 'Selecciona una opciÃ³n ...';

    displayDropdown: boolean = false;
    opened: boolean = false;

    error: boolean = false;

    private fieldErrorSubscription: Subscription;

    constructor(private fieldError: FieldErrorService) {
    }

    ngOnInit() {
        this.fieldErrorSubscription = this.fieldError.errorIdsObservable().subscribe(res => {
            this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
        });

        if (this.docField === undefined || this.docField === null || this.docField < 0) {
            this.docField = -1;
            this.selectedOption = 'Selecciona una opciÃ³n ...';
        } else {
            this.selectedOption = this.field.options[this.docField];
        }
    }

    ngOnChanges() {
        this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
    }

    ngOnDestroy() {
        if (this.fieldErrorSubscription) {
            this.fieldErrorSubscription.unsubscribe();
        }
    }

    onMouseUp(e) {
        //console.log("On mouse up:", e)
        if ('dropdown-content select-dropdown active' === e.path[2].className) {
            const id = e.path[2].id;
            const elementId = document.getElementById(id);

            const top = elementId.getBoundingClientRect().top;
            if (top > 3 * window.innerHeight / 5) {
                setTimeout(() => {
                    elementId.style.top = '-150px';
                });
            }
        }
    }

    clickIn(e) {
        this.sendInput(e);
        this.closeOptions();
    }

    toggleOptions() {
        this.opened = !this.opened;
    }

    openOptions() {
        this.opened = true;
    }

    closeOptions() {
        this.opened = false;
    }

    sendInput(number) {
        this.docField = parseInt(number, 10);
        this.fieldError.deleteErrorField(this.field.id, this.repeatIndex);
        this.sendText.emit(this.docField);
        if (this.docField === -1) {
            this.selectedOption = 'Selecciona una opciÃ³n ...';
        } else {
            this.selectedOption = this.field.options[this.docField];
        }
        this.closeOptions();
    }
}
