import {Injectable} from '@angular/core';
import {changeDatabaseConnection} from 'milcontratos-database';
import * as firebase from 'firebase';


@Injectable({
    providedIn: 'root'
})
export abstract class EnvironmentBaseService {
    constructor() {
        changeDatabaseConnection(firebase);
    }
    /**
     * Options needed to initialize Firebase.
     */
    abstract firebaseOptions(): any;

    /**
     * Base URL where the Cloud Functions are found.
     */
    abstract urlFunctions(): string;

    /**
     * Bucket where all storage files are saved.
     */
    abstract bucket(): string;

    /**
     * Bucket where all storage files are saved.
     */
    abstract platformId(): string;

    abstract setPlatformId(platformId: string): void;
}
