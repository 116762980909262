import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {Field} from 'milcontratos-database';
import {Subscription} from 'rxjs';
import {FieldErrorService} from '../../../services/client/field-error.service';


@Component({
    selector: 'app-binary-field',
    template: `<label [for]="'checkbox' + field.id + 'i' + index" class="checkbox">
    <input autocomplete="off" [id]="'checkbox' + field.id + 'i' + index" class="with-gap styled-checkbox"
        type="checkbox" [ngModel]="value" (ngModelChange)="sendInput($event)" />
    <span>{{field.caption}} <span *ngIf="field.description" class="info-icon"><i
                class="material-icons notranslate">info</i></span>
        <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
    </span>
</label>

<small class="error-message" *ngIf="error">Ãs necessari omplir aquest camp</small>
`,
    styles: [`.info-icon{position:relative;top:8px}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}.checkbox:hover .hover-field{display:block;min-width:300px}.checkbox{position:relative}.error-message{color:red;font-size:10px}`]
})
export class BinaryFieldComponent implements OnInit, OnChanges, OnDestroy {

    @Input() field: Field;
    @Input() repeatIndex: number;
    @Input() value: boolean;
    @Input() index: number;
    @Output() sendText: EventEmitter<boolean> = new EventEmitter();

    error: boolean = false;

    private fieldErrorSubscription: Subscription;

    constructor(private fieldError: FieldErrorService) {
    }

    ngOnInit() {
        this.fieldErrorSubscription = this.fieldError.errorIdsObservable().subscribe(res => {
            this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
        });
    }

    ngOnChanges() {
        this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
    }

    ngOnDestroy() {
        if (this.fieldErrorSubscription) {
            this.fieldErrorSubscription.unsubscribe();
        }
    }

    sendInput(evt) {
        this.sendText.emit(evt);
        this.fieldError.deleteErrorField(this.field.id, this.repeatIndex);
    }

}
