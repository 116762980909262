import {Injectable} from '@angular/core';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import * as firebase from 'firebase';
import {ExpertQuestion, QuestionInterface} from 'milcontratos-database';
import {ExpertQuestionNode, ExpertResponseNode} from 'milcontratos-database';
import * as pretty from 'pretty-data';
import {fromJSONToSpanishXML, fromSpanishXMLToJSON} from '../../utils/ParseSpanishXMLExpertSystem';
import {fromSpanishXMLToJSONDirectory} from '../../utils/ParseSpanishXMLDirectory';
import {DirectoryNode} from 'milcontratos-database';
import {PlatformsAdminService} from './platforms-admin.service';
import {AngularFireStorage} from '@angular/fire/storage';

export async function getPublicQuestionById(questionId: string): Promise<QuestionInterface> {
    const question: ExpertQuestion = ExpertQuestion.fromJSON(await getQuestionJson(questionId));
    return question.toJSONSimplified();
}

async function getQuestionJson(questionId: string): Promise<any> {
    const snapshot = await firebase.database().ref('questions/' + questionId).once('value');
    return snapshot.val();
}

@Injectable({
    providedIn: 'root'
})
export class ExpertSystemAdminService {

    expertSystemPath = '/backend/directorySystem/';
    directoryPath = '/backend/expertSystem/';

    constructor(private cloud: CloudFunctionsService,
                private storage: AngularFireStorage,
                private platformService: PlatformsAdminService) {

    }

    // public async addDocumentToResponse(responseId: string, documentVersionId: string) {
    //     await this.cloud.addDocumentToResponse(responseId, documentVersionId);
    // }
    //
    // public async addQuestionFromResponse(questionText: string, responseId: string) {
    //     await this.cloud.addQuestionFromResponse(questionText, responseId);
    // }
    //
    // public async addResponseToQuestion(responseText: string, questionId: string) {
    //     await this.cloud.addResponseToQuestion(questionId, responseText);
    // }
    //
    // public async removeQuestionFromResponse(questionId: string) {
    //     await this.cloud.removeQuestionFromResponse(questionId);
    // }
    //
    // public async removeResponseToQuestion(responseId: string) {
    //     await this.cloud.removeResponseToQuestion(responseId);
    // }
    //
    // public async quitDocumentFromResponse(responseId: string, documentVersionId: string) {
    //     await this.cloud.quitDocumentFromResponse(responseId, documentVersionId);
    // }

    public async generateXML(): Promise<Blob> {
        const res = (await firebase.database().ref('questions').once('value')).val();
        const obj = await ExpertQuestionNode.fromFlattenJSON(res,
            (await firebase.database().ref('question_root/id').once('value')).val());
        const xml = await fromJSONToSpanishXML(obj);
        const options = {indentation: '  ', stripComments: true};
        const formattedXml = pretty.pd.xml(xml);
        const blob = new Blob([formattedXml], {type: 'text/plain'});
        return blob;
    }

    public async saveXMLAsNewStructure(platformId: string, file: File): Promise<any> {
        if (!platformId) {
            throw {error: 'platformId is undefined' };
        }
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                let json: ExpertQuestionNode;
                try {
                    json = await fromSpanishXMLToJSON(fileReader.result);
                    await this.cloud.expertSystemNewStructure(platformId, await json.toJSON());
                    await this.uploadExpertXmlFile(platformId, file);
                } catch (e) {
                    reject(e);
                }
                resolve();
            };
            fileReader.readAsText(file, 'utf-8');
        });
    }

    public async uploadExpertXmlFile(platformId: string, file) {
        const defaultPlatform = await this.platformService.getDefaultPlatform();
        const ref = this.storage.ref(`${this.expertSystemPath}/${platformId}.xml`);
        await ref.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        if (defaultPlatform.id === platformId) {
            const ref2 = this.storage.ref(`${this.expertSystemPath}/deafult.xml`);
            await ref2.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        }
    }

    public getExpertXmlPath(platformId): string {
        if (!platformId) {
            throw {error: 'platformId is undefined' };
        }
        return `${this.expertSystemPath}/${platformId}.xml`;
    }
    public getExpertXmlPathDefault(): string {
        return `${this.expertSystemPath}/deafult.xml`;
    }

    public directoryTests(file) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                let json: DirectoryNode;
                try {
                    json = await fromSpanishXMLToJSONDirectory(fileReader.result);
                    const res = await json.toJSON();
                    const res2: DirectoryNode = await DirectoryNode.fromJSON(res);
                    const flatten = await res2.toFlattenTreeJSON();
                    const test = await  DirectoryNode.fromFlattenJSON({...flatten['flattenJson']}, flatten['rootId']);
                } catch (e) {
                    reject(e);
                }
                resolve();
            };
            fileReader.readAsText(file, 'utf-8');
        });
    }

    public async saveXMLAsNewDirectoryStructure(platformId: string, file: File): Promise<any> {
        if (!platformId) {
            throw {error: 'platformId is undefined' };
        }
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                let json: DirectoryNode;
                try {
                    json = await fromSpanishXMLToJSONDirectory(fileReader.result);
                    await this.cloud.directoryNewStructure(platformId, await json.toJSON());
                    await this.uploadDirectoryXmlFile(platformId, file);
                } catch (e) {
                    reject(e);
                }
                resolve();
            };
            fileReader.readAsText(file, 'utf-8');
        });
    }

    public async uploadDirectoryXmlFile(platformId: string, file) {
        if (!platformId) {
            throw {error: 'platformId is undefined' };
        }
        const defaultPlatform = await this.platformService.getDefaultPlatform();
        const ref = this.storage.ref(`${this.directoryPath}/${platformId}.xml`);
        await ref.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        if (defaultPlatform.id === platformId) {
            const ref2 = this.storage.ref(`${this.directoryPath}/deafult.xml`);
            await ref2.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        }
    }

    public getDirectoryXmlPath(platformId: string): string {
        if (!platformId) {
            throw {error: 'platformId is undefined' };
        }
        return `${this.directoryPath}/${platformId}.xml`;
    }

    public getDirectoryXmlPathDefault(): string {
        return `${this.directoryPath}/deafult.xml`;
    }




}
