import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EnvironmentBaseService} from './services/shared/environment-base.service';
import {DocPageComponent} from './components/doc-page/doc-page.component';
import {DocPreviewComponent} from './components/doc-preview/doc-preview.component';
import {FillDocumentComponent} from './components/fill-document/fill-document.component';
import {FormsModule} from '@angular/forms';
import {BinaryFieldComponent} from './components/fields/binary-field/binary-field.component';
import {DateFieldComponent} from './components/fields/date-field/date-field.component';
import {MultipleOptionsFieldComponent} from './components/fields/multiple-options-field/multiple-options-field.component';
import {NumberFieldComponent} from './components/fields/number-field/number-field.component';
import {SingleOptionsFieldComponent} from './components/fields/single-options-field/single-options-field.component';
import {TextFieldComponent} from './components/fields/text-field/text-field.component';
import {TextareaFieldComponent} from './components/fields/textarea-field/textarea-field.component';
import {MaterializeModule} from '@samuelberthe/angular2-materialize';
import { FillFieldComponent } from './components/fill-document/fill-section/fill-field/fill-field.component';
import { FillRepeatableComponent } from './components/fill-document/fill-section/fill-repeatable/fill-repeatable.component';
import { FillSectionComponent } from './components/fill-document/fill-section/fill-section.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireDatabaseModule} from '@angular/fire/database';
import {AngularFireAuthModule} from '@angular/fire/auth';


@NgModule({
    imports: [
        CommonModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        FormsModule,
        MaterializeModule,
        PerfectScrollbarModule,
        AngularFireAuthModule,
        BrowserAnimationsModule,
        PdfViewerModule
    ],
    declarations: [
        DocPageComponent,
        DocPreviewComponent,
        BinaryFieldComponent,
        DateFieldComponent,
        MultipleOptionsFieldComponent,
        NumberFieldComponent,
        SingleOptionsFieldComponent,
        TextFieldComponent,
        TextareaFieldComponent,
        FillDocumentComponent,
        FillFieldComponent,
        FillRepeatableComponent,
        FillSectionComponent

    ],
    exports: [
        DocPageComponent,
        DocPreviewComponent,
        BinaryFieldComponent,
        DateFieldComponent,
        MultipleOptionsFieldComponent,
        NumberFieldComponent,
        SingleOptionsFieldComponent,
        TextFieldComponent,
        TextareaFieldComponent,
        FillDocumentComponent,
    ]
})
export class MilcontratosModule {
    static fromRoot(env): ModuleWithProviders {
        return {
            ngModule: MilcontratosModule,
            providers: [
                {
                    provide: EnvironmentBaseService,
                    useClass: env
                }
            ]
        };
    }

}
