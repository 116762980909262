import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterializeAction} from '@samuelberthe/angular2-materialize';

@Component({
    selector: 'app-date-field',
    template: `<label class="input-label">{{field.caption}}</label>
<div class="input-box">
    <input autocomplete="off" type="text" class="input-text" [placeholder]="'DD'"
           [ngModel]="day"
           (ngModelChange)="day = $event"
           (blur)="dayHasChanged()"/>
    <div class="input-separator">/</div>
    <input autocomplete="off" type="text" class="input-text" [placeholder]="'MM'"
           [ngModel]="month"
           (ngModelChange)="month = $event"
           (blur)="monthHasChanged()"/>
    <div class="text">/</div>
    <input autocomplete="off" type="text" class="input-text" [placeholder]="'YYYY'"
           [ngModel]="year"
           (ngModelChange)="year = $event"
           (blur)="yearHasChanged()"/>
</div>
<div class="error">
    <small *ngIf="error">
        La data introduÃ¯da no Ã©s correcte
    </small>
</div>
<div class="error">
    <small *ngIf="dayError">
        El format de dia esperat Ã©s DD
    </small>
</div>
<div class="error">
    <small *ngIf="monthError">
        El format de mes esperat Ã©s MM
    </small>
</div>
<div class="error">
    <small *ngIf="yearError">
        El format d'any esperat Ã©s AAAA
    </small>
</div>
`,
    styles: [`.info-icon{position:relative;top:8px}.error{display:block;color:red;width:100%}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}label:hover .hover-field{display:block;min-width:300px}label{position:relative}.input-label:hover .hover-field{display:block;min-width:300px}.input-label{position:relative}.input-box{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-flow:row nowrap;-webkit-box-pack:start;justify-content:flex-start;-webkit-box-align:center;align-items:center;align-content:center;width:100%}input[type=text]:not(.browser-default){margin:1rem 0;height:2rem;text-align:right}.input-separator{font-size:1rem;margin:3px 8px 0}.input-error{font-weight:700;font-size:10px;letter-spacing:.1vw;line-height:1.70758vh;color:red!important}@media (max-width:767.98px){.input-label{font-size:14px}}`]
})
export class DateFieldComponent implements OnInit, OnChanges {

    @Input() field: any;
    @Input() index: number;
    @Input() docField: string;
    @Output() sendText: EventEmitter<string> = new EventEmitter();

    error: boolean = false;
    dayError: boolean = false;
    monthError: boolean = false;
    yearError: boolean = false;

    day: string;
    month: string;
    year: string;

    constructor() {
    }

    ngOnInit() {
        // if (this.docField !== '' && this.docField !== undefined) {
        //     const ct = new Date(this.docField);
        //     this.day = ct.getDate().toString();
        //     this.month = (ct.getMonth() + 1).toString();
        //     this.year = ct.getFullYear().toString();
        // }
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('docField' in changes) {
            if (this.docField !== '' && this.docField !== undefined) {
                const ct = new Date(this.changeDateFormat());

                this.day = ct.getDate().toString();
                this.month = (ct.getMonth() + 1).toString();
                this.year = ct.getFullYear().toString();

                if (this.day.length === 1) {
                    this.day = '0' + this.day;
                }
                if (this.month.length === 1) {
                    this.month = '0' + this.month;
                }
                while (this.year.length < 4) {
                    this.year = '0' + this.year;
                }
            }
        }
    }

    changeDateFormat(): string {
        const date = this.docField.split('/');
        return date[1] + '/' + date[0] + '/' + date[2];
    }

    sendInput() {
        if (this.isValidDate()) {
            this.error = false;
            const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
            const newDate = new Date(
                parseInt(this.year, 10),
                parseInt(this.month, 10) - 1,
                parseInt(this.day, 10)
            );
            this.sendText.emit(newDate.toLocaleDateString('es-ES', options));
        } else {
            if (this.day && this.month && this.year) {
                this.error = true;
            }
        }
    }

    dayHasChanged() {
        if (this.day.length === 1) {
            this.day = '0' + this.day;
        }
        const regexDay = /^(0[1-9]|[12]\d|3[01])$/;
        if (this.day && regexDay.test(this.day.toString())) {
            this.dayError = false;
            this.sendInput();
        } else if (this.year === '' && this.day === '' && this.month === '') {
            this.yearError = false;
            this.monthError = false;
            this.dayError = false;
            this.sendText.emit(undefined);
        } else {
            this.dayError = true;
        }
    }

    monthHasChanged() {
        if (this.month.length === 1) {
            this.month = '0' + this.month;
        }
        const regexMonth = /^(1[0-2]|0[1-9])$/;
        if (this.month && regexMonth.test(this.month.toString())) {
            this.monthError = false;
            this.sendInput();
        } else if (this.year === '' && this.day === '' && this.month === '') {
            this.yearError = false;
            this.monthError = false;
            this.dayError = false;
            this.sendText.emit(undefined);
        } else {
            this.monthError = true;
        }
    }

    yearHasChanged() {
        while (this.year.length < 4) {
            this.year = '0' + this.year;
        }
        const regexYear = /^[0-9]\d{3}$/;
        if (this.year && regexYear.test(this.year.toString())) {
            this.yearError = false;
            this.sendInput();
        } else if (this.year === '' && this.day === '' && this.month === '') {
            this.yearError = false;
            this.monthError = false;
            this.dayError = false;
            this.sendText.emit(undefined);
        } else {
            this.yearError = true;
        }
    }

    isValidDate() {
        const y = parseInt(this.year, 10);
        const m = parseInt(this.month, 10);
        const d = parseInt(this.day, 10);
        if (this.year !== undefined && this.day !== undefined && this.month !== undefined) {
           // Check the ranges of month and year
           if (y < 0 || y > 9999 || m === 0 || m > 12) {
               return false;
           }

           const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

           // Adjust for leap years
           if (y % 400 === 0 || (y % 100 !== 0 && y % 4 === 0)) {
               monthLength[1] = 29;
           }

           // Check the range of the day
           return d > 0 && d <= monthLength[m - 1];
        } else {
           return false;
        }
    }

}
