import {Injectable} from '@angular/core';
import {Condition, Field, FieldType, LegalDocument, Operation, RepeatableBlock, Section} from 'milcontratos-database';
import {FilledDocument} from 'milcontratos-database';
import {Observable, Subject} from 'rxjs';
import {DocPreviewComponent} from '../../components/doc-preview/doc-preview.component';
import {DocumentsService} from './documents.service';


function assert(predicate: boolean, msg: string) {
    if (!predicate) {
        throw new Error(msg);
    }
}


export enum DocumentTreeNodeKind {
    document = 'document',
    section = 'section',
    field = 'field',
    repeatable = 'repeatable'
}


/**
 * In order to ease rendering of the inputs for the user, the Document model into a tree-like
 * form. This class represents each node of this tree.
 */
export class DocumentTreeNode {
    kind: DocumentTreeNodeKind;
    id: string;
    children: DocumentTreeNode[];
    entity: any;

    constructor(kind: DocumentTreeNodeKind, id: string,
                entity: LegalDocument | Section | RepeatableBlock | Field) {
        this.kind = kind;
        this.id = id;
        this.children = [];
        this.entity = entity;
    }

    isDocument(): boolean {
        return this.kind === DocumentTreeNodeKind.document;
    }

    isSection(): boolean {
        return this.kind === DocumentTreeNodeKind.section;
    }

    isField(): boolean {
        return this.kind === DocumentTreeNodeKind.field;
    }

    isRepeatable(): boolean {
        return this.kind === DocumentTreeNodeKind.repeatable;
    }
}


class FakeDocPreview {
    changeFieldValue(fieldId: string, values: string[]) {}
    showConditionalBlock(blockId: string, repetitionIndex: number) {}
    hideConditionalBlock(blockId: string, repetitionIndex: number) {}
    incrementRepeatableBlock(blockId: string) {}
    decrementRepeatableBlock(blockId: string, index: number) {}
    recomputeAutonumerables(kind?: string) {}
    addSignature(signatureId: string, signature: string) {}
    repaint() {}
    zoomIn() {}
    zoomOut() {}
    zoomAdjust() {}
}


@Injectable({
    providedIn: 'root'
})
export class FillDocumentService {

    private _originalDocument: LegalDocument;
    private _documentTree: DocumentTreeNode;
    private _filledDocument: FilledDocument;
    private _language: string;
    private _currentDocument?: LegalDocument;
    private _filledDocumentObservable: Subject<FilledDocument> = new Subject();
    private _preview: DocPreviewComponent | FakeDocPreview;

    constructor(private documentService: DocumentsService) {
    }

    // region accessors

    get filledDocument(): FilledDocument {
        return this._filledDocument;
    }

    get document(): LegalDocument {
        return this._originalDocument;
    }

    get documentTree(): DocumentTreeNode {
        return this._documentTree;
    }

    // endregion

    /**
     * Every time a new document is to be filled, this method must be called to set up the internal state of
     * the service. NOTICE THAT THIS SERVICE DOES NOT ALLOW FILLING MORE THAN ONE DOCUMENT SIMULTANEOUSLY.
     */
    init(document: LegalDocument, filledDocument: FilledDocument, preview: DocPreviewComponent) {
        this._originalDocument = document;
        this._currentDocument = this._originalDocument;
        this._documentTree = this.generateDocumentTree();
        this._filledDocument = filledDocument;
        this._language = this._originalDocument.documentLanguage;
        this._preview = preview ? preview : new FakeDocPreview();

        // If a field is not initialized, initialize it.
        for (const section of this._currentDocument.sections) {
            for (const field of section.fields) {
                const val = this._filledDocument.getValue(this._language, field.id);
                if (val === undefined) {
                    const defaultVal = [this.defaultValue(field.id)];
                    this._filledDocument.setValue(this.getMainLanguage(), field.id, JSON.stringify(defaultVal));
                    if (this.isTranslatable()) {
                        this._filledDocument.setValue(this.getSecondaryLanguage(), field.id, JSON.stringify(defaultVal));
                    }
                }
            }
        }

        this.setUpPreview();

        this._preview.repaint();
        this._preview.zoomAdjust();

        this._filledDocumentObservable.next(this._filledDocument);
    }

    // region queries

    /**
     * Returns the main language of the current document.
     */
    getMainLanguage(): string {
        return this._originalDocument.documentLanguage;
    }

    /**
     * Returns the secondary language of the current document, or undefined if the current
     * document has no translations.
     */
    getSecondaryLanguage(): string {
        const availableLanguages = Object.keys(this._originalDocument.availableLanguages);
        return availableLanguages.find((l) => l !== this.getMainLanguage());
    }

    /**
     * Returns the current language.
     */
    getCurrentLanguage(): string {
        return this._language;
    }

    /**
     * Returns the language that is not the current language.
     */
    getNotCurrentLanguage(): string {
        const availableLanguages = Object.keys(this._originalDocument.availableLanguages);
        return availableLanguages.find((l) => l !== this.getCurrentLanguage());
    }

    /**
     * Returns true if the current document is translatable and false otherwise.
     */
    isTranslatable(): boolean {
        return !!this.getSecondaryLanguage();
    }

    /**
     * Return the section with the given id.
     */
    getSection(sectionId: string): Section {
        return this._currentDocument.sections.find((s) => s.id === sectionId);
    }

    /**
     * Returns the number of fields (counting repetition) in a given section.
     */
    getFieldCount(sectionId: string) {
        return this.getSection(sectionId).fields.reduce((count, field) => count + this.getFieldRepetitions(field.id), 0);
    }

    /**
     * Return an array with the values of all repetitions of a given field. If a field is not repeatable, then an
     * array with just one value will be returned.
     */
    getFieldValues(fieldId: string, language?: string): any[] {
        return this.findField(fieldId, language);
    }

    /**
     * Return the value of the given field and repetition index. If the field is not repeatable, then repeatIndex
     * must be 0.
     */
    getFieldValue(fieldId: string, repeatIndex: number, language?: string): any {
        return this.findField(fieldId, language)[repeatIndex];
    }

    /**
     * Return the number of repetitions associated with the given field. Returns 0 if the field is not repeatable.
     */
    getFieldRepetitions(fieldId: string): number {
        return this.findField(fieldId).length;
    }

    /**
     * Given the ID of a field, return all conditions whose truth value depends on this field. If none do, return
     * an empty array.
     */
    getFieldConditions(fieldId: string): Condition[] {
        const conditions = [];
        if (!this._currentDocument.conditions) {
            return conditions;
        }
        for (const condition of this._currentDocument.conditions) {
            if (condition.field_id === fieldId) {
                conditions.push(condition);
            }
        }

        return conditions;
    }

    /**
     * Return the actual field model associated with the given fieldId if and only if it belongs to the
     * given section. If sectionId is undefined, then looks for a field with the given id regardless of
     * the section.
     */
    getField(sectionId: string, fieldId: string): Field {
        for (const section of this._currentDocument.sections) {
            if (sectionId !== undefined && section.id !== sectionId) {
                continue;
            }

            for (const field of section.fields) {
                if (field.id === fieldId) {
                    return field;
                }
            }
        }

        return undefined;
    }

    /**
     * Given the ID of a field, return the repeatable block whose master field is the given field. If the given field is not
     * the master field of any repeatable block, returns null.
     */
    getMasterFieldRepeatableBlock(fieldId: string): RepeatableBlock | null {
        if (!this._currentDocument.repeatableBlocks) {
            return null;
        }
        const block = this._currentDocument.repeatableBlocks.find((b) => b.masterField === fieldId);
        return block ? block : null;
    }

    /**
     * Given the ID of any field, return all repeatable blocks that use that field. If no repeatable block uses it,
     * an empty array will be returned.
     */
    getFieldRepeatableBlocks(fieldId: string): RepeatableBlock[] {
        if (!this._currentDocument.repeatableBlocks) {
            return [];
        }
        return this._currentDocument.repeatableBlocks.filter((block) => block.fieldsId.indexOf(fieldId) >= 0);
    }

    /**
     * Given the ID of a field and its repetition index, determine if it is visible.
     */
    isFieldVisible(fieldId: string, repetitionIndex: number): boolean {
        const field = this.getField(undefined, fieldId);
        if (field.condition_ids && field.condition_ids.length > 0) {
            for (const dependentConditionId of field.condition_ids) {
                const truth = this.conditionHolds(dependentConditionId);
                if ((truth.length === 1 && truth[0]) || (truth.length > 1 && truth[repetitionIndex])) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    /**
     * Return the section the given field belongs to.
     */
    getFieldSection(fieldId: string): Section {
        for (const section of this._currentDocument.sections) {
            const pos = section.fields.findIndex((field) => field.id === fieldId);
            if (pos >= 0) {
                return section;
            }
        }
        assert(false, 'There is no field with the given id!');
    }

    /**
     * Given the ID of a field, decide if it in the first part (first two elements) of the section.
     */
    isFieldInFirstPartOfSection(fieldId: string, repeatIndex: number): boolean {
        const section = this.getFieldSection(fieldId);
        return section.fields.findIndex((field) => field.id === fieldId) < 2;
    }

    /**
     * Given the ID of a field, decide if it in the last part (last two elements) of the section.
     */
    isFieldInLastPartOfSection(fieldId: string): boolean {
        const section = this.getFieldSection(fieldId);
        return section.fields.findIndex((field) => field.id === fieldId) >= section.fields.length - 2;
    }

    /**
     * Given the ID of a repeatable block, determine if it is visible.
     */
    isRepeatableBlockVisible(blockId: string) {
        const block = this.getRepeatableBlock(blockId);
        return this.isFieldVisible(block.masterField, 0);
    }

    /**
     * Evaluates the condition with the given id and returns an array with the truth value of each repetition of this condition.
     */
    conditionHolds(conditionId: string): boolean[] {
        const condition = this.findCondition(conditionId);
        if (!condition) {
            throw new Error('There is no condition with id ' + conditionId);
        }

        const numRepetitions = this.getFieldRepetitions(condition.field_id);
        const ret = [];
        for (let repetition = 0; repetition < numRepetitions; repetition++) {
            // Check if the condition field is visible (if not visible, the condition is always false).
            if (!this.isFieldVisible(condition.field_id, repetition)) {
                ret.push(false);
                continue;
            }

            let value = this.getFieldValue(condition.field_id, repetition);
            if (Array.isArray(value)) {
                let result = false;

                for (let v of value) {
                    v = v + 1;
                    if (condition.operation === Operation.in_) {
                        if (!result) {
                            result = condition.value.map((x) => parseInt(x, 10)).indexOf(v) >= 0;
                        }
                    } else {
                        if (!result) {
                            result = condition.value.map((x) => parseInt(x, 10)).indexOf(v) < 0;
                        }
                    }
                }
                ret.push(result);
            } else {
                value += 1;  // Condition values are 1-based.

                if (condition.operation === Operation.in_) {
                    ret.push(condition.value.map((x) => parseInt(x, 10)).indexOf(value) >= 0);
                } else {
                    ret.push(condition.value.map((x) => parseInt(x, 10)).indexOf(value) < 0);
                }
            }
        }
        // console.log('conditionHolds', conditionId, condition.field_id, ret);
        return ret;
    }

    findCondition(conditionId: string): Condition {
        if (!this._currentDocument.conditions) {
            return undefined;
        }
        return this._currentDocument.conditions.find((c) => c.id === conditionId);
    }

    /**
     * Get the repeatable block with the given id.
     */
    getRepeatableBlock(blockId: string): RepeatableBlock {
        const block = this._currentDocument.repeatableBlocks.find((blk) => blk.id === blockId);
        if (!block) {
            throw new Error(`There is no block with id ${blockId}`);
        }
        return block;
    }

    /**
     * Given a repeatable block, return all the fields that are in that block.
     */
    getRepeatableBlockFields(blockId: string): Field[] {
        const block = this.getRepeatableBlock(blockId);
        return block.fieldsId.map((id) => this.getField(undefined, id));
    }

    /**
     * Returns the total number of repetitions of a given repeatable block.
     */
    getBlockRepetitions(blockId: string): number {
        const block = this.getRepeatableBlock(blockId);
        return this.getFieldRepetitions(block.masterField);
    }

    /**
     * Observable that will emit the whole FilledDocument every time a field changes its value.
     */
    filledDocumentObservable(): Observable<FilledDocument> {
        return this._filledDocumentObservable.asObservable();
    }

    // endregion

    // region mutations

    /**
     * Change the displayed fields in the associated preview to the given language values.
     * Notice that calling this function will trigger a repaint of the doc preview.
     * If the language is not passed, then it will pick the currently not selected language.
     */
    async changeLanguage(language?: string, repaint: boolean = true) {
        if (!language) {
            language = this.getNotCurrentLanguage();
        }
        this._language = language;
        this._currentDocument = await this.documentService.getCompletDocumentById(this._originalDocument.availableLanguages[language]);
        this._documentTree = this.generateDocumentTree();
        if (repaint) {
            this.setUpPreview();
            this._preview.repaint();
        }
    }

    /**
     * Change the value of the given field for the given repetition. If the field is not repeatable then
     * repeatIndex must be 0.
     * Notice that calling this function will trigger a repaint of the doc preview.
     */
    changeFieldValue(fieldId: string, repeatIndex: number, value?: any, language?: string) {
        this.changeFieldValueNoPaint(fieldId, repeatIndex, value, language);
        this._preview.repaint();
    }

    /**
     * Remove the value of the given field for the given repetition. If the field is not repeatable or has only
     * one repetition, calling this method will raise an Error.
     * Notice that calling this function will trigger a repaint of the doc preview.
     */
    removeFieldValue(fieldId: string, repeatIndex: number) {
        this.removeFieldValueNoPaint(fieldId, repeatIndex);
        this._preview.repaint();
    }

    /**
     * Adds a new repetition for the given repeatable block. All fields of the new repetition will be initialized to their
     * default values.
     * Notice that calling this function will trigger a repaint of the doc preview.
     */
    incrementRepeatableBlock(blockId: string) {
        const blockFields = this.getRepeatableBlockFields(blockId);
        assert(blockFields && blockFields.length > 0, 'A repeatable block must have at least one field!');
        const numRepetitions = this.getFieldRepetitions(blockFields[0].id);

        this._preview.incrementRepeatableBlock(blockId);
        for (const field of blockFields) {
            this.changeFieldValueNoPaint(field.id, numRepetitions, undefined, this.getMainLanguage());
            this.changeFieldValueNoPaint(field.id, numRepetitions, undefined, this.getSecondaryLanguage());
        }
        this._preview.recomputeAutonumerables();
        this._preview.repaint();
    }

    /**
     * Removes the given repetition from the given repeatable block.
     * Notice that calling this function will trigger a repaint of the doc preview.
     */
    decrementRepeatableBlock(blockId: string, repeatIndex: number) {
        const blockFields = this.getRepeatableBlockFields(blockId);
        assert(blockFields && blockFields.length > 0, 'A repeatable block must have at least one field!');

        this._preview.decrementRepeatableBlock(blockId, repeatIndex);
        for (const field of blockFields) {
            this.removeFieldValueNoPaint(field.id, repeatIndex);
        }
        this._preview.recomputeAutonumerables();
        this._preview.repaint();
    }

    // endregion

    // region private_utils

    /**
     * Change the value of the given field without repainting the preview.
     * The semantics of each parameter are the same than those in changeFieldValue.
     */
    private changeFieldValueNoPaint(fieldId: string, repeatIndex: number, value?: any, language?: string) {
        language = language ? language : this._language;
        const field = this.getField(undefined, fieldId);

        // Give a default value if none is provided.
        if (value === undefined) {
            value = this.defaultValue(fieldId);
        }

        // Append the new value.
        const val = this.findField(fieldId, language);
        if (repeatIndex <= val.length) {
            val[repeatIndex] = value;
        } else {
            throw new Error('Invalid repeat index!');
        }
        this._filledDocument.setValue(language, fieldId, JSON.stringify(val));

        // Show or hide conditionals if necessary.
        if (this.getFieldConditions(fieldId).length > 0) {
            // TODO: Find a better way to update only those conditionals needed.
            this.recomputeConditionals(true);
        }

        // Update the field representation in the preview only if we updated the current language.
        if (language === this._language) {
            this._preview.changeFieldValue(fieldId, this.getFieldRepresentation(field, val));
        }

        // Emit change in the model.
        this._filledDocumentObservable.next(this._filledDocument);
    }

    /**
     * Remove the given value from the field without repainting the preview.
     * The semantics of each parameter are the same as in removeField.
     */
    private removeFieldValueNoPaint(fieldId: string, repeatIndex: number) {
        const availableLanguages = Object.keys(this._originalDocument.availableLanguages);
        for (const language of availableLanguages) {
            const val = this.findField(fieldId, language);
            if (val.length < 2) {
                continue;
            }
            val.splice(repeatIndex, 1);
            this._filledDocument.setValue(language, fieldId, JSON.stringify(val));
        }
        const val = JSON.parse(this._filledDocument.getValue(this._language, fieldId));
        this._preview.changeFieldValue(fieldId, this.getFieldRepresentation(this.getField(undefined, fieldId), val));
        this._filledDocumentObservable.next(this._filledDocument);
    }

    /**
     * Given the currently stored value for a field, return its representation to be sent to the preview.
     */
    private getFieldRepresentation(field: Field, val: any[]): string[] {
        const representation = [];
        if (field.type === FieldType.binary) {
            for (const v of val) {
                representation.push(v ? field.options[1] : field.options[0]);
            }
        } else if (field.type === FieldType.single_list) {
            for (const v of val) {
                representation.push(field.options[v]);
            }
        } else if (field.type === FieldType.multi_list) {
            for (const v of val) {
                for (const w of v) {
                    representation.push(field.options[w]);
                }
            }
        } else {
            representation.push(...val);
        }
        return representation;
    }

    /**
     * Add the correct number of repetitions for each repeatable block.
     */
    private recomputeRepeatables() {
        if (this._currentDocument.repeatableBlocks) {
            for (const block of this._currentDocument.repeatableBlocks) {
                const repetitions = this.getBlockRepetitions(block.id);
                for (let i = 1; i < repetitions; i++) {
                    this._preview.incrementRepeatableBlock(block.id);
                }
            }
        }
    }

    /**
     * Shows or hides all conditionals of the document.
     *
     * @param recomputeAutonumerables If true, autonumerables will be recomputed as well after recomputing conditionals.
     */
    private recomputeConditionals(recomputeAutonumerables: boolean = false) {
        if (!this._currentDocument.conditions) {
            return;
        }

        for (const condition of this._currentDocument.conditions) {
            const conditionValue = this.conditionHolds(condition.id);
            for (let repetition = 0; repetition < conditionValue.length; repetition++) {
                if (conditionValue[repetition]) {
                    this._preview.showConditionalBlock(condition.id, repetition);
                } else {
                    this._preview.hideConditionalBlock(condition.id, repetition);
                }
            }
        }

        if (recomputeAutonumerables) {
            this._preview.recomputeAutonumerables();
        }
    }

    /**
     * Refresh the values to display in the doc preview.
     * Notice that this method DOES NOT REPAINT the preview.
     */
    private refreshFieldValuesInPreview() {
        for (const section of this._currentDocument.sections) {
            for (const field of section.fields) {
                this._preview.changeFieldValue(
                    field.id, this.getFieldRepresentation(field, JSON.parse(this._filledDocument.getValue(this._language, field.id)))
                );
            }
        }
    }

    /**
     * Set up the doc preview when initialized or after changing language.
     */
    private setUpPreview() {
        this.recomputeRepeatables();
        this.recomputeConditionals();
        this.refreshFieldValuesInPreview();
        this._preview.recomputeAutonumerables();
    }

    /**
     * Translates the current Document model into a tree.
     */
    private generateDocumentTree(): DocumentTreeNode {
        const documentNode = new DocumentTreeNode(DocumentTreeNodeKind.document, this._currentDocument.id, this._currentDocument);
        for (const section of this._currentDocument.sections) {
            const sectionNode = new DocumentTreeNode(DocumentTreeNodeKind.section, section.id, section);
            this.generateSectionBlock(sectionNode, section.fields.slice());
            documentNode.children.push(sectionNode);
        }
        return documentNode;
    }

    /**
     * Generates the inner tree of a section.
     */
    private generateSectionBlock(parent: DocumentTreeNode, fields: Field[]) {
        while (fields.length > 0) {
            const field = fields.shift();
            const fieldNode = new DocumentTreeNode(DocumentTreeNodeKind.field, field.id, field);
            if (parent.kind === DocumentTreeNodeKind.repeatable) {
                 if (field.repeatableBlocks && field.repeatableBlocks[0]) {
                     parent.children.push(fieldNode);
                 } else {
                     fields.unshift(field);
                     return;
                 }
            } else {
                if (field.repeatableBlocks && field.repeatableBlocks[0]) {
                    const blockNode = new DocumentTreeNode(DocumentTreeNodeKind.repeatable, field.repeatableBlocks[0],
                        this.getRepeatableBlock(field.repeatableBlocks[0]));
                    blockNode.children.push(fieldNode);
                    this.generateSectionBlock(blockNode, fields);
                    parent.children.push(blockNode);
                } else {
                    parent.children.push(fieldNode);
                }
            }
        }
    }

    // endregion

    private findField(fieldId: string, language?: string): any[] {
        language = language ? language : this._language;
        return JSON.parse(this._filledDocument.getValue(language, fieldId));
    }

    private defaultValue(fieldId: string): any {
        let field: Field;
        for (const s of this._currentDocument.sections) {
            for (const f of s.fields) {
                if (f.id === fieldId) {
                    field = f;
                    break;
                }
            }

            if (field) {
                break;
            }
        }

        if (!field) {
            throw new Error('There is no field with id ' + fieldId);
        }

        if (field.type === FieldType.binary) {
            return false;
        } else if (field.type === FieldType.number) {
            return 0;
        } else if (field.type === FieldType.single_list) {
            return -1;
        } else if (field.type === FieldType.multi_list) {
            return [-1];
        } else {
            return '';
        }
    }
}
