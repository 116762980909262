import { Injectable } from '@angular/core';

import {
    Invoice, InvoiceDetails,
    RenderStatus,
    SearchResults
} from 'milcontratos-database';
import * as firebase from 'firebase';
import {CompletedTransaction} from 'milcontratos-database';
import {InvoiceJob} from 'milcontratos-database/dist/models/invoices/InvoiceJob';
import {Parser} from 'json2csv';

import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {AngularFireDatabase} from '@angular/fire/database';


export interface TransactionsInterface {
    cursor: any;
    transactions: CompletedTransaction[];
}

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
      private cloud: CloudFunctionsService,
      private dbObs: AngularFireDatabase,
      ) { }

    public async getPaginateTransactions(numTransactions: number, cursor?: any): Promise<TransactionsInterface> {
        const ref = firebase.database().ref('all_completed_transactions');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByChild('date').endAt(cursor).limitToLast(numTransactions + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('date').limitToLast(numTransactions).once('value');
        }

        let feed: CompletedTransaction[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = newCursor === cursor ? snpsht.val()['date'] : newCursor;

            feed.push(CompletedTransaction.fromJSON(snpsht.val()));
        });
        feed = cursor ?  feed.slice(0, feed.length - 1) : feed;
        feed.reverse();
        return {
            cursor: newCursor,
            transactions: feed
        };
    }

    public async getPaginateInvoice(numInvoices: number, platformName: string, offset?: any,
                                    fromDate?: string, toDate?: string): Promise<SearchResults> {
        const result  = await this.cloud.adminPaginateBills(numInvoices, platformName, offset, fromDate, toDate);
        const searchResult = new SearchResults();
        searchResult.invoices = result.invoices.map((i) => Invoice.fromJSON(i));
        searchResult.offset = result.offset;
        return searchResult;
    }

    /**
     * Returns Path of the redered invoice in storage
     */
    public async renderAdminInvoice(transactionId: string, userId: string, platformId: string): Promise<string> {

        return new Promise<string>(async (resolve, reject) => {
            let jobId;
            try {
                jobId = await this.cloud.adminRenderInvoice(transactionId, userId, platformId);
            } catch (e) {
                reject(e);
            }
            const itemRef = this.dbObs.object('invoice_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        resolve(InvoiceJob.fromJSON(item).invoicePath);
                        break;

                    case RenderStatus.Error:
                        console.log('Error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        console.log('Server error render', item);
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }

    private getDateFormated(d: Date): string {
        if (!d) {
            return undefined;
        }
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}`;
    }

    public async getAllAdminInvoicesCsv(platformName: string, fromDate?: string, toDate?: string): Promise<string> {
        const allJsons = [];
        let cursor;
        let res = await this.getPaginateInvoice(100, platformName, cursor, fromDate, toDate);
        let invoices = res.invoices;
        cursor = res.offset;
        while (invoices.length > 0) {
            invoices.forEach((invoice) => {
                invoice.items.forEach((item) => {
                    allJsons.push({
                        'UUID': invoice.uuid,
                        'ID': invoice.id,
                        'ID transaccion': invoice.transactionId,
                        'ID cliente': invoice.clientId,
                        'Fecha': this.getDateFormated(invoice.timestamp),
                        'Portal de acceso': invoice.platformName,
                        'Nombre': invoice.name,
                        'Apellidos': invoice.lastName,
                        'Direccion': invoice.address,
                        'Codigo postal': invoice.postalCode,
                        'Ciudad': invoice.city,
                        'Pais': invoice.country,
                        'Provincia': invoice.province,
                        'Email': invoice.email,
                        'Numero de telefono': invoice.phoneNumber,
                        'Es una empresa': invoice.isCompany ? 'Si' : 'No',
                        'Nombre de la empresa': invoice.companyName,
                        'NIF': invoice.nif,
                        'Referencia de la factura': invoice.invoiceReference,
                        'ID pago': invoice.paymentId,
                        'Es simplificada': invoice.isSimplified ? 'Si' : 'No',
                        'UUID producto': item.uuid,
                        'ID producto': item.id,
                        'Descripcion': item.description,
                        'Cantidad': item.quantity,
                        'Precio neto por producto': item.netUnitPrice,
                        'Precio bruto': item.grossPrice,
                        'Impuestos': item.vatTax,
                        'Descuento': item.discount,
                        'Tipo de pago': item.paymentType,
                        'Tipo de producto': item.type,
                    });
                });
            });

            res = await this.getPaginateInvoice(100, cursor, platformName);
            invoices = res.invoices;
            cursor = res.offset;
        }
        const fields = ['UUID', 'ID', 'ID transaccion', 'ID cliente', 'Fecha', 'Portal de acceso', 'Nombre',
            'Apellidos', 'Direccion', 'Codigo postal', 'Ciudad', 'Pais', 'Provincia', 'Email', 'Numero de telefono',
            'Es una empresa', 'Nombre de la empresa', 'NIF', 'Referencia de la factura', 'ID pago', 'Es simplificada',
            'UUID producto', 'ID producto', 'Descripcion', 'Cantidad', 'Precio neto por producto', 'Precio bruto',
            'Impuestos', 'Descuento', 'Tipo de pago', 'Tipo de producto'];
        const json2csvParser = new Parser({ fields , delimiter: ';', flatten: true});
        const csv = json2csvParser.parse(allJsons);

        return csv;
    }

    public async editInvoice(transactionId: string, details: InvoiceDetails, userId: string): Promise<void> {
        await this.cloud.editInvoiceDetails(transactionId, details.toJSON(), userId);
    }
}
