import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {DocumentTreeNode, FillDocumentService} from '../../../../services/client/fill-document.service';
import {Field, FieldType, FilledDocument, LegalDocument, RepeatableBlock, Section} from 'milcontratos-database';
import {Subscription} from 'rxjs';


@Component({
    selector: 'mc-fill-field',
    template: `<div *ngIf="isVisible" class="field-values-box">
    <app-text-field
        style="width: 100%;"
        [field]="field"
        [docField]="value"
        [translatedDocField]="translatedValue"
        [hasTranslation]="hasTranslation"
        (sendText)="onValueChange($event)"
        (sendTranslatedText)="onTranslatedValueChange($event)"
        *ngIf="fieldType === 'text'">
    </app-text-field>
    <app-textarea-field
        style="width: 100%;"
        [field]="field"
        [docField]="value"
        [translatedDocField]="translatedValue"
        [hasTranslation]="hasTranslation"
        (sendText)="onValueChange($event)"
        (sendTranslatedText)="onTranslatedValueChange($event)"
        *ngIf="fieldType === 'text_area'">
    </app-textarea-field>
    <app-number-field
        style="width: 100%;"
        [field]="field"
        [docField]="value"
        (sendText)="onValueChange($event)"
        *ngIf="fieldType === 'number'"></app-number-field>
    <app-binary-field
        style="width: 100%;"
        [field]="field"
        [repeatIndex]="repeatIndex"
        [value]="value"
        (sendText)="onValueChange($event)"
        *ngIf="fieldType === 'binary'"></app-binary-field>
    <app-date-field
        style="width: 100%;"
        [field]="field"
        [docField]="value"
        (sendText)="onValueChange($event)"
        *ngIf="fieldType === 'date'"></app-date-field>
    <app-multiple-options-field
        style="width: 100%;"
        [field]="field"
        [repeatIndex]="repeatIndex"
        [dropdownUp]="dropdownUp"
        [docField]="value"
        (sendText)="onValueChange($event)"
        *ngIf="fieldType === 'multi_list'"></app-multiple-options-field>
    <app-single-options-field
        style="width: 100%;"
        [field]="field"
        [repeatIndex]="repeatIndex"
        [dropdownUp]="dropdownUp"
        [docField]="value"
        (sendText)="onValueChange($event)"
        *ngIf="fieldType === 'single_list'"></app-single-options-field>
    <div *ngIf="isRepeatable && numRepetitions - 1 === repeatIndex" (click)="onIncrementRepetition()">
        <i class="material-icons doc-included-icon grey-icon">add</i>
    </div>
    <div *ngIf="isRepeatable && numRepetitions > 1" (click)="onDecrementRepetition()">
        <i class="material-icons doc-included-icon grey-icon-hover">delete</i>
    </div>
</div>
`,
    styles: [`.field-values-box{display:-webkit-box;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;flex-flow:row nowrap;-webkit-box-pack:justify;justify-content:space-between;align-content:center;-webkit-box-align:center;align-items:center;margin:1.8143vh 0;position:relative;cursor:pointer;font-weight:400;font-size:1.49413vh;letter-spacing:.1vw;line-height:1.70758vh;color:#6a6a6a}.grey-icon,.grey-icon-hover{color:#929699;margin-left:10px;margin-top:15px}`]
})
export class FillFieldComponent implements OnInit, OnChanges, OnDestroy {

    @Input() node: DocumentTreeNode;
    @Input() repeatIndex: number;

    @Output() change: EventEmitter<any> = new EventEmitter();

    field: Field;
    section: Section;
    value: any;
    translatedValue: any;
    hasTranslation: boolean;
    fieldType: string;
    isVisible: boolean;
    repeatableBlock: RepeatableBlock | null;
    isRepeatable: boolean;
    numRepetitions: number;
    dropdownUp: boolean;

    fillDocumentSubscription: Subscription;

    constructor(private fillDocumentService: FillDocumentService) {
        this.fillDocumentSubscription = this.fillDocumentService.filledDocumentObservable().subscribe((filledDocument: FilledDocument) => {
            this.recomputeState();
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('node' in changes) {
            this.field = this.fillDocumentService.getField(undefined, this.node.id);
            this.section = this.fillDocumentService.getFieldSection(this.node.id);
            this.fieldType = this.field.type;
            this.repeatableBlock = this.fillDocumentService.getMasterFieldRepeatableBlock(this.field.id);
            if (this.repeatableBlock) {
                this.isRepeatable = true;
            } else {
                if (this.fillDocumentService.getFieldRepeatableBlocks(this.field.id).length > 0) {
                    this.isRepeatable = false;
                } else {
                    const belongsToRepeatableBlock = this.fillDocumentService.getFieldRepeatableBlocks(this.field.id);
                    if (!belongsToRepeatableBlock) {
                        this.isRepeatable = this.field.repeatable;
                    } else {
                        this.isRepeatable = false;
                    }
                }
            }
            this.recomputeState();
        }
    }

    ngOnDestroy(): void {
        if (this.fillDocumentSubscription) {
            this.fillDocumentSubscription.unsubscribe();
        }
    }

    onValueChange(value: any) {
        this.fillDocumentService.changeFieldValue(this.node.id, this.repeatIndex, value, this.fillDocumentService.getCurrentLanguage());
        if (this.fieldType !== FieldType.text && this.fieldType !== FieldType.text_area) {
            this.fillDocumentService.changeFieldValue(
                this.node.id, this.repeatIndex, value, this.fillDocumentService.getNotCurrentLanguage()
            );
        }
    }

    onTranslatedValueChange(value: any) {
        this.fillDocumentService.changeFieldValue(this.node.id, this.repeatIndex, value, this.fillDocumentService.getNotCurrentLanguage());
    }

    onIncrementRepetition() {
        if (this.repeatableBlock) {
            this.fillDocumentService.incrementRepeatableBlock(this.repeatableBlock.id);
        } else {
            this.fillDocumentService.changeFieldValue(this.field.id, this.numRepetitions);
        }
    }

    onDecrementRepetition() {
        if (this.repeatableBlock) {
            this.fillDocumentService.decrementRepeatableBlock(this.repeatableBlock.id, this.repeatIndex);
        } else {
            this.fillDocumentService.removeFieldValue(this.field.id, this.repeatIndex);
        }
    }

    recomputeState() {
        const currentLanguage = this.fillDocumentService.getCurrentLanguage();
        const notCurrentLanguage = this.fillDocumentService.getNotCurrentLanguage();

        this.value = this.fillDocumentService.getFieldValue(this.field.id, this.repeatIndex, currentLanguage);
        this.translatedValue = this.fillDocumentService.getFieldValue(this.field.id, this.repeatIndex, notCurrentLanguage);
        this.hasTranslation = this.fillDocumentService.isTranslatable();
        this.isVisible = this.fillDocumentService.isFieldVisible(this.field.id, this.repeatIndex);
        this.numRepetitions = this.fillDocumentService.getFieldRepetitions(this.field.id);
        // this.dropdownUp = !this.fillDocumentService.isFieldInFirstPartOfSection(this.field.id) &&
        //     this.fillDocumentService.isFieldInLastPartOfSection(this.field.id);
    }
}
