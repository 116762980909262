import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import {LegalAdviceMeta} from 'milcontratos-database';

@Injectable({
  providedIn: 'root'
})
export class LegalAdviceService {

  constructor() { }

    public async getLegalAdviceDocument(): Promise<LegalAdviceMeta>{
      const snapshot = await  firebase.database().ref('legal_advice/document_advice').once('value');
      return LegalAdviceMeta.fromJSON(snapshot.val());
    }

    public async getLegalAdviceFilledDocument(): Promise<LegalAdviceMeta>{
        const snapshot = await  firebase.database().ref('legal_advice/extra_advice').once('value');
        return LegalAdviceMeta.fromJSON(snapshot.val());
    }

    public async getIVA(): Promise<number> {
        const snapshot = await firebase.database().ref('iva/legal_advice').once('value');
        const iva = snapshot.val();
        return iva;
    }
}
