import {ExpertQuestionNode, ExpertResponseNode} from 'milcontratos-database';
import * as fastXmlParser from 'fast-xml-parser';
import jsontoxml from 'jsontoxml';
import {hasDatabaseForbbidenCharacter} from './utils';

export async function fromSpanishXMLToJSON(xml: string): Promise<ExpertQuestionNode> {
    const options = {
        attributeNamePrefix: '',
        attrNodeName: 'attr', // default is 'false'
        textNodeName: '#text',
        ignoreAttributes: false,
        ignoreNameSpace: false,
        allowBooleanAttributes: false,
        parseNodeValue: true,
        parseAttributeValue: false,
        trimValues: true,
        cdataTagName: '__cdata',
        cdataPositionChar: '\\c',
        localeRange: ''
    };
    const isValid = fastXmlParser.validate(xml);
    if (isValid === true) {
        const errors: string[] = [];
        const objParsed = fastXmlParser.parse(xml, options);
        const result = await recursiveQuestionXMLParsedToObj(objParsed['pregunta'], errors);
        if (errors.length > 0) {
            throw {
                errors: errors
            };
        }
        return result;
    } else {
        throw {
            errors: [isValid.err.msg]
        };
    }
}

export async function fromJSONToSpanishXML(question: ExpertQuestionNode): Promise<string> {
    const toXml = await recursiveQuestionJSONToXMLJSON(await question.toJSON());
    return jsontoxml([toXml], {prettyPrint: true, indent: ' '});
}

async function recursiveQuestionJSONToXMLJSON(json) {
    const newJson = {};
    newJson['name'] = 'pregunta';
    newJson['attrs'] = {'texto': json['text']};
    if (json['responses'] && json['responses'].length > 0) {
        newJson['children'] = {
            'respuestas': await Promise.all(json['responses'].map(async (resp) => {
            return await recursiveResponseJSONToXMLJSON(resp);
        }))
    };
    }
    return newJson;

}

async function recursiveResponseJSONToXMLJSON(json) {
    const newJson = {};
    newJson['name'] = 'respuesta';
    newJson['attrs'] = {'texto': json['text']};
    newJson['children'] = [];
    if (json['documents'] && json['documents'].length > 0) {
        newJson['children'] = [{
            'documentos': await Promise.all(json['documents'].map(async (doc) => {
            return {'name': 'documento', 'text': doc};
        }))
    }];
    }
    if (json['question']) {
        newJson['children'].push(await recursiveQuestionJSONToXMLJSON(json['question']));
    }
    return newJson;
}

async function recursiveQuestionXMLParsedToObj(json, errors: string[]): Promise<ExpertQuestionNode> {
    const question = new ExpertQuestionNode();
    if (hasDatabaseForbbidenCharacter(json['attr']['texto'])) {
        errors.push(`Caracter no permitido ".", "#", "$", "/", "[", or "]" en: ${json['attr']['texto']}`);
    }
    question.question = json['attr']['texto'];
    if (json['respuestas'] && json['respuestas']['respuesta']) {
        if (json['respuestas']['respuesta']['attr']) {
            question.responses = [ await recursiveResponseXMLParsedToObj(json['respuestas']['respuesta'], errors)];
        } else {
            const result: any = await Promise.all( json['respuestas']['respuesta'].map( async (res) => {
                return await recursiveResponseXMLParsedToObj(res, errors);
            }));
            question.responses = result;
        }
    }
    return question;
}

async function recursiveResponseXMLParsedToObj(json, errors: string[]): Promise<ExpertResponseNode> {
    const response = new ExpertResponseNode();
    if (hasDatabaseForbbidenCharacter(json['attr']['texto'])) {
        errors.push(`Caracter no permitido ".", "#", "$", "/", "[", or "]" en: ${json['attr']['texto']}`);
    }
    response.response = json['attr']['texto'];
    if (json['documentos'] && json['documentos']['documento']) {
        if (Array.isArray(json['documentos']['documento'])) {
            response.documents = json['documentos']['documento'];
        } else {
            // TODO TEST MULTIPLE DOCUMENTS
            response.documents = [json['documentos']['documento']];
        }
    }
    if (json['pregunta']) {
        response.question = await recursiveQuestionXMLParsedToObj(json['pregunta'], errors);
    }
    return response;
}

