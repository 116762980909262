import {Injectable} from '@angular/core';
import uuidv4 from 'uuid/v4';
import * as firebase from 'firebase';
import {DocType, DocumentPack, PackInterface} from 'milcontratos-database';
import {validateModel} from '../../utils/validation';
import {Validator} from 'class-validator';
import {PacksService} from '../client/packs.service';




@Injectable({
    providedIn: 'root'
})
export class PacksAdminService {

    constructor(private packsService: PacksService) {
    }


    async createType(name: string, color: string): Promise<DocType> {
        const validator = new Validator();
        if (!validator.isHexColor(color)) {
            throw {
                errors: [`Color "${color}" no valido`]
            };
        }
        color = `#${color.replace('#', '')}`;
        name = name.toLowerCase();
        const allTypes = (await firebase.database().ref('documents_types').once('value')).val();
        if (allTypes) {
            const keys = Object.keys(allTypes);
            for (let i = 0; i < keys.length; i++) {
                if (allTypes[keys[i]]['name'] === name) {
                    throw {error: 'you can\'t repite names'};
                }
            }
        }
        const type = new DocType();
        type.id = uuidv4();
        type.name = name;
        type.color = color;
        await validateModel(type);
        await firebase.database().ref('documents_types/' + type.id).set(type.toJSON());
        return type;
    }

    async editTypeColor(typeId: string, color: string): Promise<DocType> {
        const validator = new Validator();
        if (!validator.isHexColor(color)) {
            throw {
                errors: [`Color "${color}" no valido`]
            };
        }
        color = `#${color.replace('#', '')}`;
        const type = await this.packsService.getType(typeId);
        if (!type) {
            throw {
                errors: ['Type no encontrado']
            };
        }
        await firebase.database().ref('documents_types/' + type.id + '/color').set(color);
        await this.packsService.refreshTypesList();
        return type;
    }

    async createPack(pack: PackInterface): Promise<DocumentPack> {
        const seenTypes = [];
        for (let i = 0; i < pack.types.length; i++) {
            if (seenTypes.indexOf(pack.types[i].id) > -1) {
                throw {error: 'Do not repeat the same types'};
            }
            const type = (await firebase.database().ref('documents_types/' + pack.types[i].id).once('value')).val();
            if (!type) {
                throw {error: 'not valid type'};
            }
            seenTypes.push(pack.types[i].id);
        }
        const packObj = DocumentPack.fromJSON(pack);
        packObj.id = uuidv4();
        await validateModel(packObj);
        await firebase.database().ref('packs/' + packObj.id).set(packObj.toJSON());
        return packObj;
    }

    async editPack(pack: DocumentPack): Promise<DocumentPack> {
        const seenTypes = [];
        for (let i = 0; i < pack.types.length; i++) {
            if (seenTypes.indexOf(pack.types[i].id) > -1) {
                throw {error: 'Do not repeat the same types'};
            }
            const type = (await firebase.database().ref('documents_types/' + pack.types[i].id).once('value')).val();
            if (!type) {
                throw {error: 'not valid type'};
            }
            seenTypes.push(pack.types[i].id);
        }
        await validateModel(pack);


        await firebase.database().ref('packs/' + pack.id).set(pack.toJSON());
        return pack;
    }

    async removePack(packId: string) {
        await firebase.database().ref('packs/' + packId).set(null);
    }
}
