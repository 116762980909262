import {Field, LegalDocument, Section} from 'milcontratos-database';


export function  compareLegalDocuments(originalDoc: LegalDocument, newDoc: LegalDocument): {warnings: string[]} {
    const errors = [];
    const warnings = [];
    const originalDocCopy = LegalDocument.fromJSON(originalDoc.toJSON());
    const newDocCopy = LegalDocument.fromJSON(newDoc.toJSON());
    compareSignature(originalDocCopy, newDocCopy, errors);

    // autonumbered_lists no se compara, propio idioma


    for (const section of originalDocCopy.sections) {
        let notFound = true;
        for (const newSection of newDocCopy.sections) {
            if (section.id === newSection.id) {
                notFound = false;
                checkSection(section, newSection, errors);
            }
        }
        if (notFound) {
            errors.push(`No se encuentra secciÃ³n ${section.document_id}`);
        }
    }
    if (originalDocCopy.sections.length !== newDocCopy.sections.length) {
        errors.push('No mismio numero de secciones que el original');
    }

    if (errors.length > 0) {
        throw {
            errors: errors
        };
    }
    return {
        warnings: warnings
    };

}

function checkSection(originalSection: Section, newSection: Section, errors: string[]) {
    const originalSectionCopy = Section.fromJSON(originalSection.toJSON());
    const newSectionCopy = Section.fromJSON(newSection.toJSON());

    assertIsEquals(originalSectionCopy.id, newSectionCopy.id,
        `Ids no equivalanetes en secciÃ³n ${originalSectionCopy.id}, ${newSectionCopy.id}`,
        errors);
    assertIsEquals(originalSectionCopy.document_id, newSectionCopy.document_id,
        `Ids no equivalanetes en secciÃ³n ${originalSectionCopy.document_id} ${newSectionCopy.document_id}`,
        errors);
    assertIsEquals(originalSectionCopy.fields.length, newSectionCopy.fields.length,
        `NÃºmero de campos no equivalente en secciÃ³n ${newSectionCopy.document_id}`,
        errors);
    for (const field of originalSectionCopy.fields) {
        let notFound = true;
        for (const newField of newSectionCopy.fields) {
            if (field.id === newField.id) {
                notFound = false;
                checkField(field, newField, originalSectionCopy.document_id, errors);
            }
        }
        if (notFound) {
            errors.push(`No se encuentra secciÃ³n ${field.document_id}`);
        }
    }

}

function checkField(originalField: Field, newField: Field, sectionNumber: number, errors: string[]) {
    const originalFieldCopy = Field.fromJSON(originalField.toJSON());
    const newFieldCopy = Field.fromJSON(newField.toJSON());

    assertIsEquals(originalFieldCopy.id, newFieldCopy.id,
        `Ids no equivalanetes en campos ${originalFieldCopy.id}, ${newFieldCopy.id} en secciÃ³n ${sectionNumber}`,
        errors);
    assertIsEquals(originalFieldCopy.document_id, newFieldCopy.document_id,
        `Ids no equivalanetes en secciÃ³n ${originalFieldCopy.document_id} ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber}`,
        errors);
    assertIsEquals(originalFieldCopy.type, newFieldCopy.type,
        `Campo ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber} no tiene mis tipo. Original: ${originalFieldCopy.type}.\
         Nuevo: ${newFieldCopy.type}`,
        errors);
    assertIsEquals(originalFieldCopy.required, newFieldCopy.required,
        `Campo ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber} no es igual el campo requerido. Original: \
        ${originalFieldCopy.required ? 'si' : 'no'}. Nuevo: ${newFieldCopy.required ? 'si' : 'no'}`,
        errors);
    assertIsEquals(originalFieldCopy.repeatable, newFieldCopy.repeatable,
        `Campo ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber} no es igual el campo repetible. Original: \
        ${originalFieldCopy.repeatable ? 'si' : 'no'}. Nuevo: ${newFieldCopy.repeatable ? 'si' : 'no'}`,
        errors);
    if (Array.isArray(originalFieldCopy.options) && Array.isArray(newFieldCopy.options)) {
        assertIsEquals(originalFieldCopy.options.length, newFieldCopy.options.length,
            `Campo ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber} numero de opciones no iguales. Original: \
        ${originalFieldCopy.options ? 'si' : 'no'}. Nuevo: ${newFieldCopy.options ? 'si' : 'no'}`,
            errors);
    } else {
        let comp1, comp2 = false;
        if (Array.isArray(originalFieldCopy.options)){
            if (originalFieldCopy.options.length <= 0) {
                comp1 = true;
            }
        } else {
            comp1 = true;
        }
        if (Array.isArray(newFieldCopy.options)){
            if (newFieldCopy.options.length <= 0) {
                comp2 = true;
            }
        } else {
            comp2 = true;
        }
        if (comp1 !== comp2) {
            errors.push(`Campo ${newFieldCopy.document_id} en secciÃ³n ${sectionNumber} opciones no iguales.`);
        }
    }
}

function compareSignature(originalDoc: LegalDocument, newDoc: LegalDocument, errors: string[]) {

    assertIsEquals(originalDoc.num_signatures, newDoc.num_signatures,
        'La definiciÃ³n de firma no es igual al original',
        errors);

    if (originalDoc.num_signatures < 0) {
        assertIsEquals(originalDoc.base_num_signatures, newDoc.base_num_signatures,
            'No mismo nÃºmero fijo de firmas',
            errors);
        assertArraysContainsSameElementsInAnyOrder(
            originalDoc.signature_num_field_id,
            newDoc.signature_num_field_id,
            'Los campos que definen las firmas variables no son iguales a los originales',
            errors
        );
    }
}


// region utils

function assertIsEquals(originalAttribute: any, newAttribute: any, message: string, errors: string[]) {
    if (typeof originalAttribute !== typeof newAttribute) {
        errors.push(message);
        return false;
    }
    if (Array.isArray(originalAttribute)) {
        if (!Array.isArray(newAttribute)) {
            errors.push(message);
            return false;
        }
        if (originalAttribute.length !== newAttribute.length) {
            errors.push(message);
            return false;
        }
        for (let i = 0; i < originalAttribute.length; i++) {
            if (!assertIsEquals(originalAttribute[i], newAttribute[i], message, errors)) {
                return false;
            }
        }
        return true;
    }
    if (originalAttribute !== newAttribute) {
        errors.push(message);
        return false;
    }
    return true;
}

function assertArraysContainsSameElementsInAnyOrder(originalList: any[], newList: any[], message: string, errors: string[]) {
    if (!Array.isArray(originalList) || !Array.isArray(newList)) {
        errors.push(message);
        return false;
    }
    if (originalList.length !== newList.length) {
        errors.push(message);
        return false;
    }
    const copy = originalList.slice();
    for (const elem of newList) {
        const index = copy.indexOf(elem);
        if (index < 0) {
            errors.push(message);
            return false;
        }
        copy.splice(index, 1);
    }
    if (copy.length > 0) {
        errors.push(message);
        return false;
    }
    return true;
}

// endregion
