import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as firebase from 'firebase';
import {
    Deal, getAllDocumentPlatformPrice,
    getDocumentPurchasePriceWithIva,
    getDocumentPurchasePriceWithoutIva,
    LegalDocument,
    PublicLegalDocument,
    StaticLegalDocument
} from 'milcontratos-database';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import paypal from 'paypal-checkout';
import {UserService} from '../shared/user.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import {AngularFireStorage} from '@angular/fire/storage';


export enum DocumentPurchaseState {
    buy = 'buy',
    owned = 'owned',
    fillableBySubscription = 'fillableBySubscription',
    notFillableBySubscription = 'notFillableBySubscription',
    freeStaticDownload = 'freeStaticDownload'
}


@Injectable({
    providedIn: 'root'
})
export class DocumentsService {

    private platformDocumentPrices = {};


    constructor(private storage: AngularFireStorage,
                private cloud: CloudFunctionsService,
                private auth: UserService,
                private env: EnvironmentBaseService) {

    }

    public async getPermissionToSeeDocument(documentId: string, filledDocumentId?: string) {
        const token = await this.cloud.getDocumentToken(documentId, filledDocumentId);
        await firebase.auth().signInWithCustomToken(token);
        await firebase.auth().currentUser.getIdToken(true);
    }

    public async getPermissionToSeeDocumentSign(documentId: string, signId: string, ownerId?: string, filledDocumentId?: string,
                                                emailCode?: string) {
        const token = await this.cloud.getDocumentTokenSign(documentId, signId, ownerId, filledDocumentId, emailCode);
        await firebase.auth().signInWithCustomToken(token);
        await firebase.auth().currentUser.getIdToken(true);
    }

    public async getPermissionToSeeFilledDocument(filledDocumentId: string, signId?: string, emailCode?: string, ownerId?: string) {
        const token = await this.cloud.getFilledDocumentToken(filledDocumentId, signId, emailCode, ownerId);
        await firebase.auth().signInWithCustomToken(token);
        await firebase.auth().currentUser.getIdToken(true);
    }

    public getUrlResource(path: string): Observable<any> {
        const ref = this.storage.ref(path);
        return ref.getDownloadURL();
    }
    public getImagePreviewUrl(textId: string, languageCode: string): Observable<any>  {
        return this.getUrlResource(`frontend/preview/${textId}-${languageCode}.png`);
    }

    public async getDocumentPurchaseState(documentId: string): Promise<DocumentPurchaseState> {
        const user = this.auth.getCurrentUser();
        const document = await this.getPublicDocumentById(documentId);
        if (document.isStaticDocument) {
            return DocumentPurchaseState.freeStaticDownload;
        }
        if (!user) {
            return DocumentPurchaseState.buy;
        }
        const ownedSnapshot = await firebase.database().ref('documents_owned/' + user.uid + '/' + documentId ).once('value');
        const owned = ownedSnapshot.val();
        if (owned) {
            return DocumentPurchaseState.owned;
        }

        if (!document) {
            throw {
                errors: ['Document not found']
            };
        }
        // TODO Add subscription states
        return DocumentPurchaseState.buy;
    }

    public async getPublicDocumentByIdAdmin(documentId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('public_documents/by_document_id/' + documentId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentByVersionIdAdmin(versionId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('public_documents/by_version_id/' + versionId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentBytextIdAdmin(textId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('public_documents/by_text/' + textId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentById(documentId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId() +
        '/by_document_id/' + documentId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentTranslationById(documentId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('public_documents_with_translations/' + documentId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentByVersionId(versionId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId()  +
                        '/by_version_id/' + versionId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentBytextId(textId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId()  +
            '/by_text/' + textId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentByIdPlatform(documentId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId() +
            '/by_document_id/' + documentId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentByVersionIdPlatform(versionId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId() +
            '/by_version_id/' + versionId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public async getPublicDocumentBytextIdPlatform(textId: string): Promise<PublicLegalDocument> {
        const snapshot = await firebase.database().ref('platform_public_documents/' + this.env.platformId() +
            '/by_text/' + textId).once('value');
        return PublicLegalDocument.fromJSON(snapshot.val());
    }

    public getPublicDocumentImageUrl(textId: string): Observable<string> {
        const ref = this.storage.ref(`frontend/preview/${textId}.png`);
        return ref.getDownloadURL();
    }

    public async getDocumentPriceWithoutIva(versionId: string, withLegalAdvice: boolean,
                                            freeDocument: boolean, deal?: Deal): Promise<number> {
        const doc = await this.getPublicDocumentByVersionId(versionId);
        if (doc) {
            return await getDocumentPurchasePriceWithoutIva(this.env.platformId(), versionId, withLegalAdvice,
                freeDocument, doc.types, deal);
        }
        return -1;
    }

    public async getDocumentPriceWithIva(versionId: string, withLegalAdvice: boolean, freeDocument: boolean, deal?: Deal): Promise<number> {
        const doc = await this.getPublicDocumentByVersionId(versionId);
        if (doc) {
            return await getDocumentPurchasePriceWithIva(this.env.platformId(), versionId, withLegalAdvice, freeDocument, doc.types, deal);
        }
        return -1;
    }

    public async getCompletDocumentById(documentId: string): Promise<LegalDocument> {
        const snapshot = await firebase.database().ref('documents/' + documentId).once('value');
        return LegalDocument.fromJSON(snapshot.val());
    }

    public async getCompletStaticDocumentById(documentId: string): Promise<StaticLegalDocument> {
        const snapshot = await firebase.database().ref('static_documents/' + documentId).once('value');
        return StaticLegalDocument.fromJSON(snapshot.val());
    }


    public async purchaseDocumentWithoutLegalAdviceUsingPack(documentId: string, packPurchaseId): Promise<void> {
        await this.cloud.generalDocumentAdquisition(documentId, false, packPurchaseId);
    }

    public async purchaseFreeDocumentWithoutLegalAdviceUsingPack(documentId: string, packPurchaseId): Promise<void> {
        await this.cloud.generalDocumentAdquisition(documentId, false, packPurchaseId);
    }

    public async purchaseFreeDocumentWithoutLegalAdviceUsingDeal(documentId: string, dealText: string): Promise<void> {
        await this.cloud.generalDocumentAdquisition(documentId, false, undefined, dealText);
    }

    public async purchaseFreeDocumentWithoutLegalAdvice(documentId: string, actualDocumentId?: string): Promise<void> {
        await this.cloud.generalDocumentAdquisition(documentId, false, undefined, undefined, actualDocumentId);
    }

    public async purchaseFreeDocumentWithoutLegalAdviceUsingSubscription(documentId: string): Promise<void> {
        await this.cloud.generalDocumentAdquisition(documentId, true);
    }

    async hasUserExceedMaxDocumentAcquisition(): Promise<boolean> {
        return this.cloud.hasUserExceedMaxDocumentAcquisition();
    }

    async hasUserExceedMaxDocumentPromotion(): Promise<boolean> {
        return this.cloud.hasUserExceedMaxDocumentPromotion();
    }

    async getRealexHPP(documentId: string, useSubscription: boolean, purchasedPackId: string | undefined,
                       legalAdvice: boolean, dealText?: string): Promise<any> {
        return await this.cloud.documentsHPPRealexTransaction(documentId, useSubscription, purchasedPackId, legalAdvice, dealText);
    }

    async makeCardPayment(documentId: string, useSubscription: boolean, purchasedPackId: string | undefined, legalAdvice: boolean,
                          pan: string, expirationYear: number, expirationMonth: number, cvn: string, name: string, cardType: string):
        Promise<any> {
        try {
            const json = await this.cloud.documentsMakeRealexTransaction(documentId, useSubscription, purchasedPackId, legalAdvice, pan,
                expirationYear, expirationMonth, cvn, name, cardType);
            return json;
        } catch (e) {
            throw {
                errors: e
            };
        }
    }

    async getStartPaypal(documentId: string, hasLegalAdvice: boolean, useSubscription: boolean, dealText?: string, purchasedPackId?: string,
                         callback?: (err: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientDocumentsStartPaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function() {

            // Set up a url on your server to create the payment
            const CREATE_URL = url;

            const body = {
                document_id: documentId,
                token: token,
                platform_id: platformId,
                use_subscription: useSubscription,
                legal_advice: hasLegalAdvice
            };
            if (purchasedPackId) {
                body['purchased_pack_id'] = purchasedPackId;
            }

            if (dealText) {
                body['deal_text'] = dealText;
            }
            // Make a call to your server to set up the payment
            return paypal.request.post(CREATE_URL, body)
                .then(function(res) {
                    return res.id;
                }).catch(e => { callback(e); });
        };
    }

    async getStartPaypalPromotion(documentId: string, hasLegalAdvice: boolean, useSubscription: boolean,
                                  dealText?: string, purchasedPackId?: string, callback?: (err: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientDocumentsStartPaypalTransactionPromotion`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function() {

            // Set up a url on your server to create the payment
            const CREATE_URL = url;

            const body = {
                document_id: documentId,
                token: token,
                platform_id: platformId,
                use_subscription: useSubscription,
                legal_advice: hasLegalAdvice
            };
            if (purchasedPackId) {
                body['purchased_pack_id'] = purchasedPackId;
            }

            if (dealText) {
                body['deal_text'] = dealText;
            }
            // Make a call to your server to set up the payment
            return paypal.request.post(CREATE_URL, body)
                .then(function(res) {
                    return res.id;
                }).catch(e => { callback(e); });
        };
    }

    async getCompletePaypal(callback: (err: any, response: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientDocumentsCompletePaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function(data, actions) {
            // Set up a url on your server to create the payment
            const EXECUTE_URL = url;

            // Make a call to your server to set up the payment
            const body: any = {
                paymentID: data.paymentID,
                payerID: data.payerID,
                token: token,
                platform_id: platformId
            };


            // Make a call to your server to execute the payment
            return paypal.request.post(EXECUTE_URL, body)
                .then((res) => {
                    actions.close();
                    callback(undefined, res);
                }).catch((res) => {
                    actions.close();
                    callback(res, undefined);
                });
        };
    }
    async getCompletePaypalPromotion(callback: (err: any, response: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientDocumentsCompletePaypalTransactionPromotion`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function(data, actions) {
            // Set up a url on your server to create the payment
            const EXECUTE_URL = url;

            // Make a call to your server to set up the payment
            const body: any = {
                paymentID: data.paymentID,
                payerID: data.payerID,
                token: token,
                platform_id: platformId
            };


            // Make a call to your server to execute the payment
            return paypal.request.post(EXECUTE_URL, body)
                .then((res) => {
                    actions.close();
                    callback(undefined, res);
                }).catch((res) => {
                    actions.close();
                    callback(res, undefined);
                });
        };
    }

    public async getIVA(): Promise<number> {
        const snapshot = await firebase.database().ref('iva/document').once('value');
        const iva = snapshot.val();
        return iva;
    }

    public async getDocumentPricePlatform(platformId: string, versionId: string): Promise<undefined | number> {
        if (this.platformDocumentPrices[platformId] === undefined) {
            const res = await getAllDocumentPlatformPrice(platformId);
            this.platformDocumentPrices[platformId] = res === undefined ? {} : res;
        }

        return this.platformDocumentPrices[platformId][versionId];
    }

    public async getAllDocumentPricePlatform(platformId: string): Promise< {[versionId: string]: number} > {
        if (this.platformDocumentPrices[platformId] === undefined) {
            const res = await getAllDocumentPlatformPrice(platformId);
            this.platformDocumentPrices[platformId] = res === undefined ? {} : res;
        }
        return this.platformDocumentPrices[platformId];
    }


}
