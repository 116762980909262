import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {FieldErrorService} from '../../../services/client/field-error.service';

@Component({
    selector: 'app-textarea-field',
    template: `<label class="input-label">
    {{field.caption}} <span *ngIf="field.description" class="info-icon"><i
            class="material-icons notranslate">info</i></span>
    <div *ngIf="field.description" class="hover-field">{{field.description}}</div>
</label>
<div class="main-language-input-container">
    <textarea autosize class="new-message" [placeholder]="field.hint ? field.hint : ''" [(ngModel)]="text"
        (blur)="sendInput()"></textarea>
    <i *ngIf="hasTranslation" (click)="showTranslation = !showTranslation"
        class="material-icons translate-icon notranslate">translate</i>
</div>
<textarea *ngIf="showTranslation" autosize class="new-message translation-input"
    [placeholder]="field.hint ? field.hint : ''" [(ngModel)]="translatedText" (blur)="sendInput()"></textarea>
<small class="input-error" *ngIf="error">El campo no pot romandre buit</small>
`,
    styles: [`.info-icon{position:relative;top:8px}.new-message{height:100%;width:100%;padding:1.60085vh 1.42857vw;border:1px solid #c4c4c4;background-color:#fff;border-radius:9px;resize:none;font-weight:400;font-size:1.49413vh;color:#3d3d3d;letter-spacing:.01857vw;line-height:2.2412vh}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}.input-label:hover .hover-field{display:block;min-width:300px}.input-label{position:relative}.input-error{font-weight:700;font-size:10px;letter-spacing:.1vw;line-height:1.70758vh;color:red!important}.main-language-input-container{display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center}.translate-icon{margin-left:10px}.translation-input{width:90%!important;margin-left:10%!important}@media (max-width:767.98px){.input-label{font-size:14px}}`]
})
export class TextareaFieldComponent implements OnInit, OnDestroy, OnChanges {

    @Input() field: any;
    @Input() docField: string;
    @Input() translatedDocField: string;
    @Input() hasTranslation: boolean;
    @Output() sendText: EventEmitter<string> = new EventEmitter();
    @Output() sendTranslatedText: EventEmitter<string> = new EventEmitter();

    text: string = '';
    translatedText: string = '';
    error: boolean = false;
    showTranslation: boolean = false;

    private fieldErrorSubscription: Subscription;

    constructor(private fieldError: FieldErrorService) {
    }

    ngOnInit() {
        this.fieldErrorSubscription = this.fieldError.errorIdsObservable().subscribe(res => {
            this.error = res.indexOf(this.field.id, 0) !== -1;
        });
        this.text = this.docField;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('docField' in changes) {
            this.text = this.docField;
        }
        if ('translatedDocField' in changes) {
            this.translatedText = this.translatedDocField;
        }
    }

    ngOnDestroy() {
        if (this.fieldErrorSubscription) {
            this.fieldErrorSubscription.unsubscribe();
        }
    }

    sendInput() {
        this.text = this.text.trim();
        this.sendText.emit(this.text);

        if (this.hasTranslation) {
            if (!this.translatedText) {
                this.translatedText = this.text;
            } else {
                this.translatedText = this.translatedText.trim();
            }
            this.sendTranslatedText.emit(this.translatedText);
        }
    }

}
