import {Injectable} from '@angular/core';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {UserService} from '../shared/user.service';
import * as firebase from 'firebase';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AssignedInterface, ChatMessage} from 'milcontratos-database';
import {ChatInterface, GroupMessage} from 'milcontratos-database';
import {AngularFireDatabase} from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(private cloud: CloudFunctionsService,
                private auth: UserService,
                private dbObs: AngularFireDatabase) {
    }

    public async sendMessage(message: string) {
        // send message
        return new Promise(async (resolve, reject) => {
            try {
                const user = this.auth.getCurrentUser();
                const msg = new ChatMessage();
                msg.text = message;
                msg.isFromClient = true;
                msg.receivedMessage = false;
                msg.timestamp = new Date();
                msg.senderId = user.uid;
                const msg_json = msg.toJSON();
                const idMessage = await firebase.database().ref('chat/room/' + user.uid + '/messages' ).push(msg_json).key;
                firebase.database().ref('chat/room/' + user.uid + '/lastUpdate' ).set(msg_json['timestamp']);
                await this.cloud.assignMessageChat();
                setTimeout(async () => {
                    try {
                        const latestMessage = (await firebase.database().ref('chat/room/' + user.uid + '/messages/' + idMessage)
                            .once('value')).val();
                        if (latestMessage['receivedMessage']) {
                            resolve();
                        } else {
                            await this.cloud.assignMessageChat(true);
                            resolve();
                        }
                    } catch (e) {
                        reject(e);
                    }
                }, 15000);
            } catch (e) {
              reject(e);
            }


        });

    }
    public getAdminTypingObservable(): Observable<boolean> {
        const user = this.auth.getCurrentUser();
        const res: Observable<any> = this.dbObs.object('chat/room/' + user.uid + '/isAdminTyping' ).snapshotChanges();
        return res;
    }

    public async setIsClientTyping(typing: boolean) {
        const user = this.auth.getCurrentUser();
        await firebase.database().ref('chat/room/' + user.uid + '/isClientTyping' ).set(typing);
    }

    public async getPaginateChat( numMessages: number, cursor?: any): Promise<ChatInterface> {
        const user = this.auth.getCurrentUser();
        const ref = firebase.database().ref('chat/room/' + user.uid + '/messages');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByChild('timestamp').endAt(cursor).limitToLast(numMessages + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('timestamp').limitToLast(numMessages).once('value');
        }

        const feed: GroupMessage[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = newCursor === cursor ? snpsht.val()['timestamp'] : newCursor;
            const msg = ChatMessage.fromJSON(snpsht.val());
            // FIXME in a future do it right
            const grMsg = new GroupMessage();
            grMsg.timestampStart = msg.timestamp;
            grMsg.timestampEnd = msg.timestamp;
            grMsg.isFromClient = msg.isFromClient;
            grMsg.text = [msg.text];
            feed.push(grMsg);
        });


        return {
            cursor: newCursor,
            messages: cursor ?  feed.slice(0, feed.length - 1) : feed
        };
    }

    public chatObservable(): Observable<GroupMessage> {
        const user = this.auth.getCurrentUser();
        return this.dbObs.list('chat/room/' + user.uid + '/messages', ref => ref.limitToLast(1)).stateChanges()
            .pipe(filter( value => value.type === 'child_added'),
                map(value => {
                    const msg = ChatMessage.fromJSON(value.payload.val());
                    // FIXME in a future do it right
                    const grMsg = new GroupMessage();
                    grMsg.timestampStart = msg.timestamp;
                    grMsg.timestampEnd = msg.timestamp;
                    grMsg.isFromClient = msg.isFromClient;
                    grMsg.text = [msg.text];
                    return grMsg;
                })
            );
    }

    public assignedUserObservable(): Observable<AssignedInterface | undefined> {
        const user = this.auth.getCurrentUser();
        return this.dbObs.object('chat/room/' + user.uid + '/assigned').snapshotChanges()
            .pipe(map(value => {
                const val: any = value.payload.val();
                return val;
            }));
    }

    public contactUs(name: string, email: string, textBody: string, subject?: string) {
        this.cloud.contactUs(name, email, textBody, subject);
    }
}
