import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentTreeNode, DocumentTreeNodeKind, FillDocumentService} from '../../../services/client/fill-document.service';
import {Subscription} from 'rxjs';


export interface FieldRepetition {
    node: DocumentTreeNode;
    repeatIndex: number;
}


@Component({
    selector: 'mc-fill-section',
    template: `<div class="fill-section-body">
    <div *ngFor="let fieldRepetition of fieldRepetitions; trackBy:trackByFn">
        <mc-fill-field [node]="fieldRepetition.node" [repeatIndex]="fieldRepetition.repeatIndex"></mc-fill-field>
    </div>

    <div *ngFor="let child of node.children; trackBy:trackByFn">
        <mc-fill-repeatable *ngIf="child.isRepeatable()" [node]="child"></mc-fill-repeatable>
    </div>
</div>
`,
    styles: [`.fill-section-body{padding-bottom:100px}`]
})
export class FillSectionComponent implements OnInit, OnChanges {

    @Input() node: DocumentTreeNode;

    fieldRepetitions: FieldRepetition[];

    fillDocumentServiceSubscription: Subscription;

    constructor(private fillDocumentService: FillDocumentService) {
        this.fillDocumentServiceSubscription = this.fillDocumentService.filledDocumentObservable().subscribe(() => {
            this.recomputeFieldRepetitions();
        });
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('node' in changes) {
            this.recomputeFieldRepetitions();
        }
    }

    trackByFn(index: number, node: DocumentTreeNode): string {
        return node.id;
    }

    private recomputeFieldRepetitions() {
        this.fieldRepetitions = [];
        for (const child of this.node.children) {
            if (child.kind !== DocumentTreeNodeKind.field) {
                continue;
            }

            const numRepetitions = this.fillDocumentService.getFieldRepetitions(child.id);
            for (let i = 0; i < numRepetitions; i++) {
                this.fieldRepetitions.push({
                    node: child,
                    repeatIndex: i
                });
            }
        }
    }
}
