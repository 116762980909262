import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {
    AutocompleteInterface,
    Deal,
    DocType,
    DocTypeInterface,
    DocumentPack, getDocumentPurchasePriceWithIva,
    getDocumentPurchasePriceWithoutIva,
    PackInterface,
    PurchasedPack
} from 'milcontratos-database';
import matchSorter from 'match-sorter';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../shared/user.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import paypal from 'paypal-checkout';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {PublicLegalDocument} from 'milcontratos-database';
import {getPackPurchasePriceWithIva, getPackPurchasePriceWithoutIva} from 'milcontratos-database/dist/Database/Functionality/Pack';
import {AngularFireDatabase} from '@angular/fire/database';


export interface ValidPacks {
    hasValidType: boolean;
    pack: PurchasedPack;
}

@Injectable({
    providedIn: 'root'
})
export class PacksService {

    private types: DocType[];
    private packs: DocumentPack[];

    constructor(private dbObs: AngularFireDatabase,
                private auth: UserService,
                private env: EnvironmentBaseService,
                private cloud: CloudFunctionsService) {
    }

    public async refreshTypesList() {
        const snapshot = await firebase.database().ref('documents_types').once('value');
        const types = snapshot.val();
        if (types) {
            this.types = Object.keys(types).map((id) => {
                return DocType.fromJSON(types[id]);
            });
        } else {
            this.types = [];
        }
        this.dbObs.object('documents_types').snapshotChanges().subscribe((snap) => {
            const t = snap.payload.val();
            if (!t) {
                this.types = [];
                return;
            }
            this.types = Object.keys(t).map((id) => {
                return DocType.fromJSON(t[id]);
            });
        });

    }
    public async packDocuments(packId: string): Promise<PublicLegalDocument[]> {
        const pack = await this.getIndividualPack(packId);
        if (!pack.types) {
            return [];
        }
        const documentList: any[] = await this.cloud.getPackDocumentsIncluded(pack.types.map((t) => t.name));
        return documentList.map((d) => PublicLegalDocument.fromJSON(d));
    }


    public async getIVA(): Promise<number> {
        const snapshot = await firebase.database().ref('iva/pack').once('value');
        const iva = snapshot.val();
        return iva;
    }

    public async refreshPackList() {
        const snapshot = await firebase.database().ref('packs').orderByChild('order_key').once('value');
        const packs = snapshot.val();
        if (packs) {
            this.packs = Object.keys(packs).map((id) => {
                return DocumentPack.fromJSON(packs[id]);
            });
        } else {
            this.packs = [];
        }
        this.dbObs.object('packs').snapshotChanges().subscribe((snap) => {
            const p = snap.payload.val();
            if (!p) {
                this.packs = [];
                return;
            }
            this.packs = Object.keys(p).map((id) => {
                return DocumentPack.fromJSON(p[id]);
            }).sort((a: DocumentPack, b: DocumentPack) => (a.orderKey > b.orderKey) ? 1 : ((a.orderKey < b.orderKey) ? -1 : 0));
        });

    }

    public async getAllPacks(): Promise<DocumentPack[]> {
        if (!this.packs) {
            await this.refreshPackList();
        }
        return this.packs;
    }

    public async getIndividualPack(packId: string): Promise<DocumentPack> {
        const snapshot = await firebase.database().ref('packs/' + packId).once('value');
        const pack = snapshot.val();
        if (!pack) {
            throw  {
              errors : ['Pack not found']
            };
        }
        return DocumentPack.fromJSON(pack);
    }

    public getAllPacksObs(): Observable<DocumentPack[]> {
        return this.dbObs.object('packs').snapshotChanges().pipe(map((snap) => {
            const p = snap.payload.val();
            if (!p) {
                return [];
            }
            const packs = Object.keys(p).map((id) => {
                return DocumentPack.fromJSON(p[id]);
            });
            return packs.sort((a: DocumentPack, b: DocumentPack) => (a.orderKey > b.orderKey) ? 1 : ((a.orderKey < b.orderKey) ? -1 : 0));
        }));
    }

    public async getAllTypes(): Promise<DocType[]> {
        if (!this.types) {
            await this.refreshTypesList();
        }
        return this.types;
    }

    public async getType(typeId: string): Promise<DocType> {
        const snapshot = await firebase.database().ref('documents_types/' + typeId).once('value');
        const type = snapshot.val();
        return DocType.fromJSON(type);
    }



    public getAllTypesObs(): Observable<DocType[]> {
        return this.dbObs.object('documents_types').snapshotChanges().pipe(map((snap) => {
            const t = snap.payload.val();
            if (!t) {
                return [];
            }
            const types = Object.keys(t).map((id) => {
                return DocType.fromJSON(t[id]);
            });
            return types;
        }));
    }

    public async autocompleteType(value: string): Promise<AutocompleteInterface[]> {
        if (!value) {
            return [];
        }
        if (!this.types) {
            await this.refreshTypesList();
        }
        return matchSorter(this.types, value, {keys: ['name']});
    }


    public async getAllPurchasedPacks(): Promise<PurchasedPack[]> {
        const user = this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref('available_packs/' + user.uid).once('value');
        const packs = snapshot.val();
        const now = new Date();
        if (packs) {
            return Object.keys(packs).map((id) => {
                return PurchasedPack.fromJSON(packs[id]);
            }).filter((pack) => pack.expirationDate.getTime() > now.getTime());
        } else {
            return [];
        }
    }


    public async getPackPriceWithoutIva(packId: string, deal?: Deal): Promise<number> {
       return await getPackPurchasePriceWithoutIva(packId, deal);
    }

    public async getPackPriceWithIva(packId: string, deal?: Deal): Promise<number> {
        return await getPackPurchasePriceWithIva(packId, deal);
    }

    public async getPurchasedPacksByTypes(typesToFilter: DocType[]): Promise<ValidPacks[]> {
        const user = this.auth.getCurrentUser();
        const snapshot = await firebase.database().ref('available_packs/' + user.uid).once('value');
        const packs = snapshot.val();
        const now = new Date();
        if (packs) {
            return Object.keys(packs).map((id) => {
                return PurchasedPack.fromJSON(packs[id]);
            }).filter((pack) => pack.expirationDate.getTime() > now.getTime())
                .map((pack => {
                    for (let i = 0; i < typesToFilter.length; i++) {
                        if (pack.types) {
                            for (let j = 0; j < pack.types.length; j++) {
                                if (pack.types[j].id === typesToFilter[i].id) {

                                    return {
                                        hasValidType: true,
                                        pack: pack
                                    };
                                }
                            }
                        }
                    }
                    return {
                        hasValidType: false,
                        pack: pack
                    };
                }));
        } else {
            return [];
        }
    }

    async getStartPaypalInfo(packId: string, dealText: string | undefined, callback: (err: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientPacksStartPaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function () {

            // Set up a url on your server to create the payment
            const CREATE_URL = url;

            const body = {
                pack_id: packId,
                token: token,
                platform_id: platformId
            };
            if (dealText) {
                body['deal_text'] = dealText;
            }
            // Make a call to your server to set up the payment
            return paypal.request.post(CREATE_URL, body)
                .then(function (res) {
                    return res.id;
                }).catch(e => callback(e));
        };
    }
    async getRealexHpp(packId: string, dealText?: string): Promise<any> {
        return await this.cloud.packsHPPRealexTransaction(packId, dealText);

    }

    async freePackPurchaseWithDeal(packId: string, dealText: string): Promise<any> {
        return await this.cloud.packsFreePurchase(packId, dealText);

    }

    async getCompletePaypal(callback: (err: any, response: any) => any): Promise<any> {
        const url = `${this.env.urlFunctions()}/clientPacksCompletePaypalTransaction`;
        const token = await this.auth.getCurrentUser().getIdToken();
        const platformId = this.env.platformId();
        return function (data, actions) {
            // Set up a url on your server to create the payment
            const EXECUTE_URL = url;

            // Make a call to your server to set up the payment
            const body: any = {
                paymentID: data.paymentID,
                payerID: data.payerID,
                token: token,
                platform_id: platformId
            };


            // Make a call to your server to execute the payment
            return paypal.request.post(EXECUTE_URL, body)
                .then((res) => {
                    callback(undefined, res);
                }).catch((res) => {
                    callback(res, undefined);
                });
        };
    }

    async makeCardPayment(packId: string, pan: string,
                          expirationYear: number, expirationMonth: number, cvn: string, name: string, cardType: string,
                          dealText?: string) {
        try {
            const json = await this.cloud.packsMakeRealexTransaction(packId, pan, expirationYear, expirationMonth, cvn, name, cardType,
                dealText);
            return json;
        } catch (e) {
            throw {
                errors: e
            };
        }
    }
}
