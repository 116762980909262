import { Component, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FieldErrorService } from '../../../services/client/field-error.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-multiple-options-field',
    template: `<div (mouseup)="onMouseUp($event)">

    <label class="input-label">{{field.caption}} <span *ngIf="field.description" class="info-icon"><i
                class="material-icons">info</i></span>
        <div class="hover-field" *ngIf="field.description">{{field.description}}</div>
    </label>
    <!-- <select [ngModel]="docField" (ngModelChange)="sendInput($event)"
        materialize="FormSelect"
        [materializeSelectOptions]="field.options" multiple>
    <option [ngValue]="-1" disabled selected>Seleccione las que correspondan ...</option>
    <option *ngFor="let option of field.options; let oi = index" [ngValue]="oi">{{option}}</option>
</select> -->
    <div class="field-values-box">
        <!-- <input type="text" class="input-text"
                [value]="selectedOption"
                autocomplete="off"
                (focus)="openOptions()"/> -->
        <div style="border-bottom: 1px solid #9e9e9e; width: 100%;">
            <p type="text" class="input-text" style="margin-top: 16px;  width: 100%;" (click)="toggleOptions()">
                {{selectedOption}}
                <!--<span class="info-icon" style="position: relative; top: 0px; left: calc(100% - 115px);">
                        <i class="material-icons notranslate">arrow_drop_down</i></span>-->
            </p>
        </div>
        <div *ngIf="opened" style="margin-top: 5px;">
            <!-- <div>
                <label class="checkbox">
                    <input class="with-gap styled-checkbox" type="checkbox" [name]="field.id" [value]="-1"
                        [ngModel]="firstValueBoolean" (click)="clickIn(-1)" />
                    <span>Ninguna opciÃ³n</span>
                </label>
            </div> -->
            <div *ngFor="let option of field.options; let oi = index">
                <label class="checkbox">
                    <input autocomplete="off" class="with-gap styled-checkbox" type="checkbox" [name]="field.id" [value]="oi"
                        [ngModel]="valuesBoolean[oi]" (click)="clickIn(oi)" />
                    <span style="width: 100%;">{{option}}</span>
                </label>
            </div>
        </div>
    </div>

    <small class="error-message" *ngIf="error">Ãs necessari omplir aquest camp</small>
</div>
`,
    styles: [`.info-icon{position:relative;top:8px}.hover-field{display:none;position:absolute;top:2.13447vh;left:0;width:21.42857vw;z-index:999;padding:2.13447vh 1.42857vw;background-color:#d3d3d3;box-shadow:5px 5px 8px #888;min-width:300px}.input-label:hover .hover-field{display:block;min-width:300px}.input-label{position:relative}.error-message{color:red;font-size:10px}@media (max-width:767.98px){.input-label{font-size:14px}}`]
})
export class MultipleOptionsFieldComponent implements OnInit, OnChanges, OnDestroy {

    @Input() field: any;
    @Input() repeatIndex: number;
    @Input() docField: number[];
    @Input() dropdownUp: boolean;
    @Output() sendText: EventEmitter<number[]> = new EventEmitter();

    number: number[] = [undefined];

    valuesBoolean: boolean[] = [];
    firstValueBoolean: boolean;

    selectedOption: string = 'Selecciona las opciones...';

    error: boolean = false;
    displayDropdown: boolean = false;
    opened: boolean = false;

    private fieldErrorSubscription: Subscription;

    constructor(private fieldError: FieldErrorService, private zone: NgZone) {
    }

    ngOnInit() {
        this.fieldErrorSubscription = this.fieldError.errorIdsObservable().subscribe(res => {
            this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
        });

        if (this.docField === undefined || this.docField === null || this.docField.length <= 0) {
            this.docField = [-1];
            this.selectedOption = 'Selecciona las opciones...';
        }
        this.calcBoolean();
    }

    ngOnChanges() {
        this.error = this.fieldError.hasError(this.field.id, this.repeatIndex);
    }

    ngOnDestroy() {
        if (this.fieldErrorSubscription) {
            this.fieldErrorSubscription.unsubscribe();
        }
    }

    onMouseUp(e) {
        if ('dropdown-content select-dropdown active' === e.path[2].className) {
            const id = e.path[2].id;
            const elementId = document.getElementById(id);

            const top = elementId.getBoundingClientRect().top;
            if (top > 3 * window.innerHeight / 5) {
                setTimeout(() => {
                    elementId.style.top = '-150px';
                });
            }
        }
    }

    clickIn(e) {
        this.sendInput(e);
        //this.closeOptions();
    }

    calcBoolean() {
        this.valuesBoolean = [];
        this.valuesBoolean.push(false);
        for (var i = 0; i < this.field.options.length; i++) {
            this.valuesBoolean.push(false);
        }
        for (var i = 0; i < this.docField.length; i++) {
            this.valuesBoolean[this.docField[i] + 1] = true;
        }
        this.firstValueBoolean = this.valuesBoolean[0];
        this.valuesBoolean.shift();
        // console.log("ValuesBoolean");
        // console.log(this.valuesBoolean);
        // console.log("docField");
        // console.log(this.docField);
    }

    toggleOptions() {
        this.opened = !this.opened;
    }

    openOptions() {
        this.opened = true;
    }

    closeOptions() {
        this.opened = false;
    }


    sendInput(selected) {
        // console.log(selected);
        // this.docField.push[selected];
        var alreadyExists = false;
        for (var i = 0; i < this.docField.length; i++) {
            if (this.docField[i] == selected) {
                //console.log("Already exists");
                alreadyExists = true;
                break;
            }
        }
        // console.log("Doc field");
        // console.log(this.docField);
        if (!alreadyExists) {
            this.docField.push(selected);
        } else {
            this.docField = this.docField.filter((x) => x != selected);
        }
        this.docField = this.docField.filter((x) => x != -1);
        //this.docField.includes(selected) ? console.log("This item already exists") : this.docField.push(selected);
        if (this.docField.length === 0) {
            this.docField = [-1];
        }
        // this.zone.run(() => {
        //     this.docField = selected.filter((x) => x >= 0);
        //     if (this.docField.length === 0) {
        //         this.docField = [-1];
        //     }
        // });
        this.fieldError.deleteErrorField(this.field.id, this.repeatIndex);
        this.docField.sort();
        // console.log(this.docField);
        this.sendText.emit(this.docField);
        this.calcBoolean();
    }

}
