import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {
    AutocompleteInterface,
    DocType,
    DocTypeInterface,
    DocumentPack, getSettingsPlatform,
    PackInterface,
    PlatformSettings,
    PurchasedPack
} from 'milcontratos-database';
import matchSorter from 'match-sorter';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../shared/user.service';
import {EnvironmentBaseService} from '../shared/environment-base.service';
import paypal from 'paypal-checkout';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {BasicPlatform} from 'milcontratos-database';
import {AngularFireDatabase} from '@angular/fire/database';


@Injectable({
    providedIn: 'root'
})
export class PlatformService {

    private platforms: BasicPlatform[];

    constructor(private dbObs: AngularFireDatabase,
                private auth: UserService,
                private env: EnvironmentBaseService,
                private cloud: CloudFunctionsService) {
    }

    public async getPlataforms(): Promise<BasicPlatform[]> {
        const allPlatforms = (await firebase.database().ref('platforms_ids').once('value')).val();
        if (!allPlatforms) {
            return [];
        }
        const keys = Object.keys(allPlatforms);
        return keys.map((key) => BasicPlatform.fromJSON(allPlatforms[key]));
    }

    public async refreshPlatformsList() {
        const allPlatforms = (await firebase.database().ref('platforms_ids').once('value')).val();
        if (!allPlatforms) {
            this.platforms = [];
        } else {
            this.platforms = await this.getPlataforms();
        }

    }

    public async getAllPlataforms(): Promise<BasicPlatform[]> {
        if (!this.platforms) {
            await this.refreshPlatformsList();
        }
        return this.platforms;
    }

    public async getSettingsPlatform(platformId: string): Promise<PlatformSettings> {
        return getSettingsPlatform(platformId);
    }


    public getAllPlatformsObs(): Observable<BasicPlatform[]> {
        return this.dbObs.object('platforms_ids').snapshotChanges().pipe(map((snap) => {
            const t = snap.payload.val();
            if (!t) {
                return [];
            }
            const platforms = Object.keys(t).map((id) => {
                return BasicPlatform.fromJSON(t[id]);
            });
            return platforms;
        }));
    }

    public async autocompletePlatform(value: string): Promise<AutocompleteInterface[]> {
        if (!value) {
            return [];
        }
        if (!this.platforms) {
            await this.refreshPlatformsList();
        }
        return matchSorter(this.platforms, value, {keys: ['name']});
    }
}
