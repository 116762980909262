import {Injectable} from '@angular/core';
import uuidv4 from 'uuid/v4';
import * as firebase from 'firebase';
import {
    DocType,
    DocumentPack,
    getDefaultBasicPlatformObj,
    PackInterface,
    PlatformSettings,
    PlatformEmailConfig,
    setPlatform,
    getSettingsPlatform,
    getEmailConfigurationPlatform,
    setEmailConfigurationPlatform,
    MetricCounters, getBasicPlatform
} from 'milcontratos-database';
import {validateModel} from '../../utils/validation';
import {BasicPlatform} from 'milcontratos-database';
import {CloudFunctionsService} from '../shared/cloud-functions.service';



@Injectable({
    providedIn: 'root'
})
export class PlatformsAdminService {

    constructor(private cloud: CloudFunctionsService) {
    }

    public async getPlatforms(): Promise<BasicPlatform[]> {
        const allPlatforms = (await firebase.database().ref('platforms_ids').once('value')).val();
        if (!allPlatforms) {
            return [];
        }
        const keys = Object.keys(allPlatforms);
        return keys.map((key) => BasicPlatform.fromJSON(allPlatforms[key]));
    }

    public async getSettingsPlatforms(): Promise<PlatformSettings[]> {
        const allPlatforms = (await firebase.database().ref('settings_platforms_ids').once('value')).val();
        if (!allPlatforms) {
            return [];
        }
        const keys = Object.keys(allPlatforms);
        return keys.map((key) => PlatformSettings.fromJSON(allPlatforms[key]));
    }

    public async existsPlatform(name: string, throwError?: boolean): Promise<boolean> {
        const allPlatforms = await this.getPlatforms();
        let exists = false;
        if (allPlatforms) {
            for (let i = 0; i < allPlatforms.length; i++) {
                if (allPlatforms[i].name === name) {
                    exists = true;
                    break;
                }
            }
        }
        if (!!throwError && exists) {
            throw {errors: ['you can\'t repite names']};
        }
        return exists;
    }

    public async createPlatform(name: string): Promise<BasicPlatform> {
        await this.existsPlatform(name, true);
        const platform = new BasicPlatform();
        platform.id = uuidv4();
        platform.name = name;
        await validateModel(platform);
        await firebase.database().ref('platforms_ids/' + platform.id).set(platform.toJSON());
        return platform;
    }

    public async createPlatformInvoiceTable(platformId: string): Promise<void> {
        await this.cloud.createPlatformInvoiceTable(platformId);
    }

    public async addDefaultPlatform(platform: BasicPlatform): Promise<void> {
        await validateModel(platform);
        await firebase.database().ref('platforms_default').set(platform.toJSON());
    }


    public async addPlatformEmailConfig(platformEmail: PlatformEmailConfig): Promise<void> {
        await validateModel(platformEmail);
        await setEmailConfigurationPlatform(platformEmail);
    }

    public async getPlatformEmailConfig(platformId: string): Promise<PlatformEmailConfig> {
        return await getEmailConfigurationPlatform(platformId);
    }

    public async getDefaultPlatform(): Promise<BasicPlatform> {
        return await getDefaultBasicPlatformObj();
    }

    public async addSettingsPlatform(platformSettings: PlatformSettings): Promise<void> {
        await validateModel(platformSettings);
        await setPlatform(platformSettings);
    }

    public async getSettingsPlatform(platformId: string): Promise<PlatformSettings> {
        return getSettingsPlatform(platformId);
    }
    public async getBasicPlatformById(platformId: string): Promise<BasicPlatform> {
        return await getBasicPlatform(platformId);
    }

    public async getEmailSettings(platformId: string): Promise<PlatformEmailConfig> {
        return await getEmailConfigurationPlatform(platformId);
    }

    public async setEmailSettings(platformEmailConfig: PlatformEmailConfig) {
        await setEmailConfigurationPlatform(platformEmailConfig);
    }

    public async getMetricValueIndividualDay(counter: MetricCounters, platformId: string, date: Date) {
        return await this.cloud.getMetricValue(counter, platformId, date);
    }

    public async getMetricValueDayRange(counter: MetricCounters, platformId: string, from: Date, to: Date) {
        return await this.cloud.getMetricValue(counter, platformId, from, to);
    }

    public async createApiKey(platformId: string): Promise<string> {
        return await this.cloud.createApiKey(platformId);
    }

    public async listApiKey(platformId: string): Promise<string[]> {
        return await this.cloud.listApiKeys(platformId);
    }

    public async removeApiKey(platformId: string, key: string) {
        return await this.cloud.removeApiKey(platformId, key);
    }

}
