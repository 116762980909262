import {Injectable} from '@angular/core';
import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {PermissionGroup, Permissions} from 'milcontratos-database';
import uuidv4 from 'uuid/v4';
import * as firebase from 'firebase';

@Injectable({
    providedIn: 'root'
})
export class RootService {

    constructor(
        private cloud: CloudFunctionsService
    ) {
    }

    public async initSearcher(offset: number) {
        await this.cloud.initSearcher(offset);
    }

    public async addPermissionGroup(name: string, permissions: Permissions[]) {
        const permissionGroup = new PermissionGroup();
        permissionGroup.name = name;
        permissionGroup.permissions = permissions;
        permissionGroup.id = uuidv4();
        firebase.database().ref(`permission_groups/${permissionGroup.id}`).set(permissionGroup.toJSON());
    }

    public async removePermissionGroup(groupId: string) {
        firebase.database().ref(`permission_groups/${groupId}`).set(null);
    }

    public async uploadTemporalModel(json: any, id: string): Promise<string> {
        await firebase.database().ref(`temporal_model/${id}`).set(json);
        await firebase.database().ref(`temporal_model/${id}/temporal_timestamp`).set((new Date()).getTime() / 1000);
        return `temporal_model/${id}`;
    }

    public async sendAllEmails(email: string, baseUrl?: string, platformName?: string) {
        await this.cloud.sendAllEmails(email, baseUrl, platformName);
    }

    public async createInvoiceTables(pool: boolean, addInvoices?: boolean) {
        await this.cloud.createInvoiceTables(pool, !!addInvoices);
    }

    public async refactorFunction(offset: number) {
        await this.cloud.refactorDocument(offset);
    }

    public async generateImagePreview(documentId: string) {
        await this.cloud.generateImagePreviewDocument(documentId);
    }


}
