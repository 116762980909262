import {Injectable} from '@angular/core';


import * as firebase from 'firebase';
import uuidv4 from 'uuid/v4';

import {
    FeedInterface,
    FeedReviewInterface,
    FileFormat,
    FileType, getAllDocumentPlatformPrice, ParsingJob,
    PublicLegalDocument,
    Review, setDocumentPlatformPrice,
    StaticLegalDocument
} from 'milcontratos-database';
import {removeEmpty} from '../../utils/utils';
import {validateModel} from '../../utils/validation';
import {parseXMLDocument, ResultParseXML} from '../../utils/ParserSpanishXMLDocument';

import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {PacksService} from '../client/packs.service';
import {DocumentsService} from '../client/documents.service';
import {RootService} from '../admin/root.service';
import {PlatformsAdminService} from './platforms-admin.service';
import {
    EditablePublicDocument,
    FieldType,
    FillDocumentState,
    FilledDocument, Languages, languageToString,
    LegalDocument,
    ParsingJobStatus, Render, RenderJob, RenderStatus,
    Section
} from 'milcontratos-database';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireStorage} from '@angular/fire/storage';
import {compareLegalDocuments} from '../../utils/CompareDcoumentTranslationModel';
// import {ResultEquate} from '../../utils/VerifyAndEquateTranslationModel';

export interface DocumentJob {
    jobId: string;
    document: LegalDocument | StaticLegalDocument;
    warnings?: string[];

}



@Injectable({
    providedIn: 'root'
})
export class DocumentAdministrationService {

    private temporalFilledDcoument: {[id: string]: FilledDocument} = {};

    private platformDocumentPrices = {};

    constructor(
        private db: AngularFireDatabase,
        private dbObs: AngularFireDatabase,
        private docsService: DocumentsService,
        private cloud: CloudFunctionsService,
        private pack: PacksService,
        private root: RootService,
        private storage: AngularFireStorage,
        private platform: PlatformsAdminService) {
    }

    /**
     * Takes a file for each document translation, parses it and generates the corresponding parsed document.
     */
    public async parseDocument(language: string, file: any): Promise<DocumentJob> {

        return new Promise<any>(async (resolve, reject) => {
            const jobId = uuidv4();
            let uploadedFile: string;
            try {
                uploadedFile = await this.uploadDocxFile(jobId, language, file);
            } catch (error) {
                reject(error);
                return;
            }

            try {
                await this.createJobDB(jobId, language, uploadedFile);
            } catch (e) {
                reject(e);
                return;
            }
            await this.cloud.startJob(jobId, false);
            const itemRef = this.db.object('parsingJobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case ParsingJobStatus.done:
                        const lDocument = LegalDocument.fromJSON(item['document']);
                        const warnings = item['warnings'] ? item['warnings'] : [];
                        lDocument.documentLanguage = language;
                        lDocument.availableLanguages = {};
                        lDocument.availableLanguages[language] = lDocument.id;
                        this.prepareDocumentForFrontend(lDocument);
                        resolve({
                            jobId: jobId,
                            document: lDocument,
                            warnings: warnings
                        });
                        subscribedItem.unsubscribe();
                        // Add 24 hours to complete the form
                        this.addTimeToJob(jobId, 24 * 60 * 60);
                        break;

                    case ParsingJobStatus.error:
                        reject({
                            code : 400,
                            errors: item['errors']
                        });
                        subscribedItem.unsubscribe();
                        break;

                    case ParsingJobStatus.server_error:
                        reject({
                            code: 500,
                            errors: item['errors']
                        });
                        subscribedItem.unsubscribe();
                        break;
                }
            });
        });
    }
    public async parseTranslateDocument(language: string, file: any, documentParentId: string): Promise<DocumentJob> {

        return new Promise<any>(async (resolve, reject) => {
            const jobId = uuidv4();
            let uploadedFile: string;
            try {
                uploadedFile = await this.uploadDocxFile(jobId, language, file);
            } catch (error) {
                reject(error);
                return;
            }

            try {
                await this.createJobDB(jobId, language, uploadedFile, documentParentId);
            } catch (e) {
                reject(e);
                return;
            }
            await this.cloud.startJob(jobId, true);
            const itemRef = this.db.object('parsingJobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case ParsingJobStatus.done:
                        const lDocument = LegalDocument.fromJSON(item['document']);
                        lDocument.documentLanguage = language;
                        lDocument.availableLanguages = {};
                        lDocument.availableLanguages[language] = lDocument.id;
                        const warnings = item['warnings'] ? item['warnings'] : [];
                        this.prepareDocumentForFrontend(lDocument);
                        resolve({
                            jobId: jobId,
                            document: lDocument,
                            warnings: warnings
                        });
                        subscribedItem.unsubscribe();
                        // Add 24 hours to complete the form
                        this.addTimeToJob(jobId, 24 * 60 * 60);
                        break;

                    case ParsingJobStatus.error:
                        reject({
                            code : 400,
                            errors: item['errors']
                        });
                        subscribedItem.unsubscribe();
                        break;

                    case ParsingJobStatus.server_error:
                        reject({
                            code: 500,
                            errors: item['errors']
                        });
                        subscribedItem.unsubscribe();
                        break;
                }
            });
        });
    }

    public async uploadStaticDocument(language: string, file: any): Promise<DocumentJob> {

        return new Promise<any>(async (resolve, reject) => {
            const jobId = uuidv4();
            let uploadedFile: string;
            try {
                uploadedFile = await this.uploadGenericFile(jobId, language, file);
            } catch (error) {
                reject(error);
                return;
            }
            try {
                await this.createJobDB(jobId, language, uploadedFile);
            } catch (e) {
                reject(e);
                return;
            }

            const document = new StaticLegalDocument();
            if (!document.version_id) {
                document.version_id = uuidv4();
                document.version = 1;
            }
            document.name = '';
            document.id = uuidv4();
            document.isPublished = false;
            document.isStaticDocument = true;
            document.short_description = '';
            document.long_description = '';
            document.price = 10;
            document.tags = [];
            document.types = [];

            document.document = {};
            document.document[language] = uploadedFile;
            document.documentLanguage = language;
            document.availableLanguages = {};
            document.availableLanguages[language] = document.id;

            resolve({
                jobId: jobId,
                document: document
            });
        });
    }

    private prepareDocumentForFrontend(document: LegalDocument) {
        if (!document.version_id) {
            document.version_id = uuidv4();
            document.version = 1;
        }
        document.name = '';
        document.isStaticDocument = false;
        document.short_description = '';
        document.long_description = '';
        document.num_signatures = 0;
        document.price = 10;
        document.tags = [];
        document.types = [];

        let section: Section;
        for (let i = 0; i < document.sections.length; i++) {
            section = document.sections[i];
            section.name = '';
            for (let j = 0; j < section.fields.length; j++) {
                section.fields[j].caption = '';
                section.fields[j].repeatable = false;
                section.fields[j].required = true;
                section.fields[j].type = FieldType.binary;
                section.fields[j].options = [];
            }
        }
    }

    public parseXmlModel(file: File, document: PublicLegalDocument): Promise<ResultParseXML> {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async (e) => {
                try {
                    resolve(await parseXMLDocument(fileReader.result, document, await this.pack.getAllTypes(),
                        await this.platform.getPlatforms()));
                } catch (e) {
                    reject(e);
                }

            };
            fileReader.readAsText(file, 'utf-8');
        });
    }


    private async addTimeToJob(jobId: string, timeInSeconds: number) {
        const snapshot = await firebase.database().ref('parsingJobs/' + jobId + '/expiration_timestamp').once('value');
        const actualTime = snapshot.val();
        firebase.database().ref('parsingJobs/' + jobId + '/expiration_timestamp').set(actualTime + timeInSeconds);

    }

    private async createJobDB(jobId: string, language: string, filePath: string, parentDocumentId?: string) {
        const body: ParsingJob = {
            id: jobId,
            filePath: filePath,
            status: ParsingJobStatus.waiting,
            language: language,
            user_id: firebase.auth().currentUser.uid,
            expiration_timestamp: (new Date).getTime() / 1000 + (3600 * 4)
        };
        if (!!parentDocumentId) {
            body['parentDocumentId'] = parentDocumentId;
        }
        firebase.database().ref('parsingJobs/' + jobId).set(body);

    }

    public async createNewDocumentFromMerge(jobId: string, legalDocument: LegalDocument | StaticLegalDocument, toMergeDocumentsId: string[],
                                            xmlFile?: File, freeUpdate?: boolean): Promise<void> {
        // IF its the first version of the document
        legalDocument.version_id = uuidv4();
        await validateModel(legalDocument);
        if (xmlFile) {
            await this.uploadXmlFile(jobId, xmlFile);
        }
        await this.cloud.newDocumentFromMarge(jobId, legalDocument, toMergeDocumentsId, freeUpdate);
    }

    /**
     * Publishes the new document
     * @param jobId
     * @param legalDocument
     */
    public async createNewDocument(jobId: string, legalDocument: LegalDocument | StaticLegalDocument, xmlFile?: File,
                                   last_version_id?: string, free_update?: boolean): Promise<void> {
        // IF its the first version of the document
        legalDocument.version_id = uuidv4();
        await validateModel(legalDocument);
        if (xmlFile) {
            await this.uploadXmlFile(jobId, xmlFile);
        }
        await this.cloud.newDocument(jobId, legalDocument, last_version_id, free_update);
    }



    public async verifyNewLanguageLegalDocument(legalDocument: LegalDocument, parentDocumentId: string) {

        const originalDoc = await this.docsService.getCompletDocumentById(parentDocumentId);
        const {warnings} = compareLegalDocuments(originalDoc, legalDocument);
        return {
            document: legalDocument,
            warnings: warnings
        };
    }

    public async addLanguage(jobId: string, legalDocument: LegalDocument, parentDocumentId: string, xmlFile?: File): Promise<void> {
        // IF its the first version of the document
        legalDocument.version_id = uuidv4();
        await validateModel(legalDocument);
        if (xmlFile) {
            await this.uploadXmlFile(jobId, xmlFile);
        }
        await this.verifyNewLanguageLegalDocument(legalDocument, parentDocumentId);
        await this.cloud.addLanguageDocument(jobId, legalDocument, parentDocumentId);
    }

    public async createTemporalFilledDocuement(documentId: string): Promise<FilledDocument> {
        const document = await this.docsService.getPublicDocumentById(documentId);
        const filledDocument = new FilledDocument();
        filledDocument.subtitle = document.name;
        filledDocument.customTitle = 'Mi ' + document.name;
        filledDocument.id = uuidv4();
        filledDocument.document_id = document.id;
        filledDocument.user_id = uuidv4();
        filledDocument.locked = false;
        filledDocument.state = FillDocumentState.filling;
        filledDocument.hasLegalAdvice = true;
        filledDocument.remainingDocumentReviews = -1;
        this.temporalFilledDcoument[filledDocument.id] = filledDocument;
        return filledDocument;
    }

    public async createTemporalFilledDocuementByLegalDocument(document: LegalDocument): Promise<FilledDocument> {
        const filledDocument = new FilledDocument();
        filledDocument.subtitle = document.name;
        filledDocument.customTitle = 'Mi ' + document.name;
        filledDocument.id = uuidv4();
        filledDocument.document_id = document.id;
        filledDocument.user_id = uuidv4();
        filledDocument.locked = false;
        filledDocument.state = FillDocumentState.filling;
        filledDocument.hasLegalAdvice = true;
        filledDocument.remainingDocumentReviews = -1;
        this.temporalFilledDcoument[filledDocument.id] = filledDocument;
        return filledDocument;
    }

    public async setTemporalFilledDocuement(filledDocumentId: string, filledDocument: FilledDocument) {
        this.temporalFilledDcoument[filledDocumentId] = filledDocument;
    }
    public async getTemporalFilledDocuement(filledDocumentId: string): Promise<FilledDocument> {
        return this.temporalFilledDcoument[filledDocumentId];
    }

    public async publishDocument(documentId: string) {
        await this.cloud.documentsPublish(documentId);

    }

    public async removeUnpublishedDocument(documentId: string) {
        await this.cloud.removeUnpublished(documentId);
    }

    public async removePublishedDocument(documentId: string) {
        await this.cloud.removePublished(documentId);
    }


    public async editDocument(documentId: string, documentContent: EditablePublicDocument) {
        await this.cloud.editPublicDocument(documentId, documentContent, false);
    }

    public async editDocumentSecondaryLanguage(secondaryLanguageDocumentId: string, documentContent: EditablePublicDocument) {
        await this.cloud.editPublicDocument(secondaryLanguageDocumentId, documentContent, true);
    }

    public async uploadDocxFile(jobId, name, file) {
        // TODO Correguir hardcodeo
        if (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ) {
            throw {
                code: 400,
                errors: ['No valid document type. Only valid .docx']
            };
        }
        const filePath = `/backend/uploadedDocuments/${jobId}/${name}-${uuidv4()}.docx`;
        const ref = this.storage.ref(filePath);
        const task = await ref.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        return filePath;
    }

    public async uploadGenericFile(jobId, name, file) {
        let format = '';
        if (file.type !== FileType.pdf && file.type !== FileType.pptx && file.type !== FileType.docx) {
            throw {
                code: 400,
                errors: [`No valid document type. Only valid ${FileFormat.pptx}, ${FileFormat.pdf} and ${FileFormat.docx}`]
            };
        }
        if (file.type === FileType.docx) {
            format = `.${FileFormat.docx}`;
        } else if (file.type === FileType.pptx) {
            format = `.${FileFormat.pptx}`;
        } else if (file.type === FileType.pdf) {
            format = `.${FileFormat.pdf}`;
        }
        const filePath = `/backend/uploadedDocuments/${jobId}/${name}-${uuidv4()}${format}`;
        const ref = this.storage.ref(filePath);
        const task = await ref.put(file, {contentType: file.type, customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        return filePath;
    }

    public async getBackupPath(documentId) {
        const path = await this.cloud.zipBackup(documentId);
        return path;
    }

    private async uploadXmlFile(jobId, file) {
        const filePath = `/backend/uploadedDocuments/${jobId}/template.xml`;
        const ref = this.storage.ref(filePath);
        const task = await ref.put(file, {customMetadata: {'uploader': firebase.auth().currentUser.uid}});
        return filePath;
    }

    public async renderAdminInProgressFilledDocuments(filledDocument: FilledDocument,
                                                      document: LegalDocument, language: string): Promise<Render> {

        return new Promise<Render>(async (resolve, reject) => {
            const filledDocumentPath = await this.root.uploadTemporalModel(removeEmpty(filledDocument.toJSON()), filledDocument.id);
            const documentPath = await this.root.uploadTemporalModel(removeEmpty(document.toJSON()), document.id);
            const jobId = await this.cloud.renderAdminFillDocument(filledDocumentPath, documentPath, language);
            const itemRef = this.dbObs.object('rendering_jobs/' + jobId);
            const subscribedItem = itemRef.snapshotChanges().subscribe(action => {

                const item = action.payload.val();
                switch (item['status']) {
                    case RenderStatus.Done:
                        subscribedItem.unsubscribe();
                        const render = RenderJob.fromJSON(item).render;
                        resolve(render);
                        break;

                    case RenderStatus.Error:
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;

                    case RenderStatus.ServerError:
                        subscribedItem.unsubscribe();
                        reject(item['errors']);
                        break;
                }
            });
        });
    }

    public async getListDocumentsToReview(numReviews: number, cursor?: any): Promise<FeedReviewInterface> {
        const ref = firebase.database().ref('to_review');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numReviews + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numReviews).once('value');
        }

        const feed: Review[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(Review.fromJSON(snpsht.val()));
        });
        return {
            cursor: newCursor,
            reviews: cursor ?  feed.slice(1, numReviews + 1) : feed
        };
    }

    async getListPublishedDocuments(numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const ref = firebase.database().ref('public_documents/by_version_id');
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }

        const feed: PublicLegalDocument[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.key;
            feed.push(PublicLegalDocument.fromJSON(snpsht.val()));
        });
        return {
            cursor: newCursor,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    public async reviewDocument(filledDocumentId: string, body: string) {
        await this.cloud.completeReview(filledDocumentId, body);
    }

    public async addDocumentToPlatform(platformId: string, documentId: string) {
        await this.cloud.addPublicDocument(platformId, documentId, true);
    }

    public async removeDocumentToPlatform(platformId: string, documentId: string) {

        await this.cloud.addPublicDocument(platformId, documentId, false);
    }

    public async getDocumentPricePlatform(platformId: string, versionId: string): Promise<undefined | number> {
        if (this.platformDocumentPrices[platformId] === undefined) {
            const res = await getAllDocumentPlatformPrice(platformId);
            this.platformDocumentPrices[platformId] = res === undefined ? {} : res;
        }

        return this.platformDocumentPrices[platformId][versionId];
    }

    public async getAllDocumentPricePlatform(platformId: string): Promise< {[versionId: string]: number} > {
        if (this.platformDocumentPrices[platformId] === undefined) {
            const res = await getAllDocumentPlatformPrice(platformId);
            this.platformDocumentPrices[platformId] = res === undefined ? {} : res;
        }
        return this.platformDocumentPrices[platformId];
    }

    public async setDocumentPricePlatform(platformId: string, versionId: string, price: number) {
        await setDocumentPlatformPrice(platformId, versionId, price);
        if (this.platformDocumentPrices[platformId] === undefined) {
            const res = await getAllDocumentPlatformPrice(platformId);
            this.platformDocumentPrices[platformId] = res === undefined ? {} : res;
        }
        this.platformDocumentPrices[platformId][versionId] = price;
    }

}
