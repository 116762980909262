import {
    Condition,
    Field,
    FieldType,
    LegalDocument,
    Operation,
    PublicLegalDocument,
    Section,
    StaticLegalDocument
} from 'milcontratos-database';
import * as fastXmlParser from 'fast-xml-parser';
import {DocType} from 'milcontratos-database';
import {BasicPlatform} from 'milcontratos-database';

interface ResultParsed {
    errors: string[];
    warnings: string[];
}
export interface ResultParseXML {
    document: LegalDocument | StaticLegalDocument;
    warnings: string[];
}

interface Campo {
    attr: { id: string };
    titulo: string;
    descripcion: string;
    ayuda: string;
    tipo: string;
    obligatorio?: string;
    repetible?: string;
    opciones?: {
        opcion: string[];
    };
}

interface Seccion {
    attr: {
        id: string;
    };
    titulo: string;
    descripcion: string;
    campos: {
        campo: Campo[];
    };
}

interface Firma {
    numero_fijo?: string;
    variables?: {
        campo: string[]
    };
}

interface ParseXML {
    titulo: string;
    descripcion_corta: string;
    descripcion_larga: string;
    milcontratos_id: string;
    milcontratos_id2: string;
    precio: string;
    tipos: {
        tipo: string[]
    };
    keywords: {
        keyword: string[]
    };
    secciones?: {
        seccion: Seccion[];
    };
    firma?: Firma;
}

export async function parseXMLDocument(xml: string, documentParsed: PublicLegalDocument, existentTypes: DocType[],
                                       existentPlatforms: BasicPlatform[]): Promise<ResultParseXML> {
    const parsedJSON: ParseXML = generateJSONFromXML(xml);
    let resp;
    let finalDocument;
    if (!documentParsed.isStaticDocument) {
        finalDocument = LegalDocument.fromJSON(documentParsed.toJSON());
        resp = fillDocument(parsedJSON['documento'], finalDocument, existentTypes, existentPlatforms);
    } else {
        finalDocument = StaticLegalDocument.fromJSON(documentParsed.toJSON());
        resp = fillStaticDocument(parsedJSON['documento'], finalDocument, existentTypes, existentPlatforms);
    }

    const {errors, warnings} = resp;
    if (errors.length > 0) {
        throw {
            errors: errors
        };
    } else {
        return {
            document: finalDocument,
            warnings: warnings
        };
    }
}

function generateJSONFromXML(xml: string) {
    const options = {
        attributeNamePrefix: '',
        attrNodeName: 'attr',
        textNodeName: '#text',
        ignoreAttributes: false,
        ignoreNameSpace: false,
        allowBooleanAttributes: false,
        parseNodeValue: true,
        parseAttributeValue: false,
        trimValues: true,
        cdataTagName: '__cdata',
        cdataPositionChar: '\\c',
        localeRange: ''
    };
    const isValid = fastXmlParser.validate(xml);
    if (isValid === true) {
        const objParsed = fastXmlParser.parse(xml, options);
        return objParsed;
    } else {
        throw {
            errors: [isValid.err.msg]
        };
    }
}

function fillStaticDocument(parsedJSON: ParseXML, finalDocument: StaticLegalDocument, existentTypes: DocType[],
                      existentPlatforms: BasicPlatform[]): ResultParsed {
    const errors = [];
    const warnings = [];
    parseBasicRootValues(parsedJSON, finalDocument, existentTypes, existentPlatforms, errors);
    return {
        errors: errors,
        warnings: warnings
    };
}

function fillDocument(parsedJSON: ParseXML, finalDocument: LegalDocument, existentTypes: DocType[],
                      existentPlatforms: BasicPlatform[]): ResultParsed {
    const errors = [];
    const warnings = [];
    parseBasicRootValues(parsedJSON, finalDocument, existentTypes, existentPlatforms, errors);
    parseSections(parsedJSON.secciones, finalDocument, errors);
    parseSignature(parsedJSON.firma, finalDocument, errors);
    parseListsTexts(parsedJSON, finalDocument, errors, warnings);
    return {
        errors: errors,
        warnings: warnings
    };
}

// region root docuemtn values
function parseBasicRootValues(parsedJSON: ParseXML, finalDocument: PublicLegalDocument, existentTypes: DocType[],
                              existentPlatforms: BasicPlatform[], errors: string[]) {
    finalDocument.name = parseGenericText(parsedJSON.titulo, errors, 'titulo');
    finalDocument.short_description = parseGenericText(parsedJSON.descripcion_corta, errors, 'descripcion_corta');
    finalDocument.long_description = parseGenericText(parsedJSON.descripcion_larga, errors, 'descripcion_larga');
    finalDocument.clientId = parseGenericText(parsedJSON.milcontratos_id, errors, 'milcontratos_id');
    finalDocument.clientSecondaryId = parseGenericText(parsedJSON.milcontratos_id2, errors, 'milcontratos_id2');
    finalDocument.price = checkPrice(parsedJSON.precio, errors);
    finalDocument.tags = parseKeywords(parsedJSON.keywords, errors);
    finalDocument.types = parseTypes(parsedJSON.tipos, existentTypes, errors);
    // finalDocument.platforms = parsePlatforms(parsedJSON.plataformas, existentPlatforms, errors);
}

function getDocTypeByName(name: string, types: DocType[]): DocType {
    for (let i = 0; i < types.length; i++) {
        if (types[i].name === name) {
            return types[i];
        }
    }
    return undefined;
}


function parseTypes(values: {tipo: any}, existentTypes: DocType[], errors: string[]): DocType[] {
    if (!values) {
        errors.push('Falta campo tipos.');
        return [];
    }
    const array = getArrayXML(values.tipo);
    if (array.length < 1) {
        errors.push('Faltan campos tipo en tipos.');
        return [];
    }
    const result: DocType[] = [];
    const unique = [];
    let text;
    for (let i = 0; i < array.length; i++) {
        text = parseGenericText(array[i], errors, 'tipo en tipos');
        if (text) {
            text = text.toLowerCase();
            if (unique.indexOf(text) > -1) {
                errors.push(`Tipo ${text} repetido.`);
            }
            unique.push(text);
            const type = getDocTypeByName(text, existentTypes);
            if (type) {
                result.push(type);
            } else {
                errors.push(`Tipo ${text} existe entre los tipos definidos. Escoge uno Rojo, Verde Oscuro, Negro o Naranja de los ya existentes.`);
            }
        }
    }
    return result;

}
// HOW TO PARSE A PLATAFORM. For future use.
// function parsePlatforms(values: {plataforma: any}, existentPlatforms: BasicPlatform[], errors: string[]): DocType[] {
//     if (!values) {
//         errors.push('Falta campo plataformas.');
//         return [];
//     }
//     const array = getArrayXML(values.plataforma);
//     if (array.length < 1) {
//         errors.push('Faltan campos plataforma en plataformas.');
//         return [];
//     }
//     const result: DocType[] = [];
//     const unique = [];
//     let text;
//     for (let i = 0; i < array.length; i++) {
//         text = parseGenericText(array[i], errors, 'plataforma en plataformas');
//         if (text) {
//             if (unique.indexOf(text) > -1) {
//                 errors.push(`Plataforma ${text} repetido.`);
//             }
//             unique.push(text);
//             const type = getDocTypeByName(text, existentPlatforms);
//             if (type) {
//                 result.push(type);
//             } else {
//                 errors.push(`Plataforma ${text} no existe entre los plataformas definidos. AÃ±adelo primero a las posibles plataformas.`);
//             }
//         }
//     }
//     return result;
//
// }

function parseKeywords(values: {keyword: any}, errors: string[]): string[] {
    if (!values) {
        errors.push('Falta campo keywords.');
        return [];
    }
    const array = getArrayXML(values.keyword);
    if (array.length < 1) {
        errors.push('Faltan campos keyword en keywords.');
        return [];
    }
    const result = [];
    let text;
    for (let i = 0; i < array.length; i++) {
        text = parseGenericText(array[i], errors, 'keyword en keywords');
        if (text) {
            if (result.indexOf(text) > -1) {
                errors.push(`keyword ${text} repetido.`);
            } else {
                result.push(text);
            }
        }
    }
    return result;

}

function checkPrice(value: any, errors: string[]): number {
    if (assertParser(!value && value !== 0, errors, `Campo precio no encontrado.`)) {
        return;
    }
    if (assertParser(Array.isArray(value), errors, `Campo precio repetido.`)) {
        return;
    }
    if (typeof value === 'string') {
        const cleaned = value.replace(/ /g, '').replace(/,/g, '.');
        return parseFloat2Decimal(cleaned, errors, 'precio');
    } else if (isFloat(value) || isInt(value)) {
        return value;
    } else {
        assertParser(true, errors, `Campo precio no valido.`);
        return;
    }


}


function parseListsTexts(parsedJSON: ParseXML, finalDocument: LegalDocument, errors: string[], warnings: string[]) {
    if (!finalDocument.autonumbered_lists || Object.keys(finalDocument.autonumbered_lists).length <= 0) {
        return;
    }
    const keys = Object.keys(finalDocument.autonumbered_lists);
    const result = {};
    for (let i = 0; i < keys.length; i++) {
        if (finalDocument.autonumbered_lists[keys[i]] <= 0) {
            continue;
        }
        if (!parsedJSON[`lista_${keys[i]}`]) {
            errors.push(`Falta campo lista_${keys[i]}.`);
            continue;
        }
        let array;
        if (parsedJSON[`lista_${keys[i]}`]['textos']) {
            array = getArrayXML(parsedJSON[`lista_${keys[i]}`]['textos']['texto']);
        } else {
            array = getArrayXML(parsedJSON[`lista_${keys[i]}`]['texto']);
        }

        if (array.length < 1) {
            errors.push(`Faltan campos texto en lista_${keys[i]}.`);
            continue;
        }
        const list = [];
        let text;
        for (let j = 0; j < array.length; j++) {
            text = parseGenericText(array[j], errors, `texto en lista_${keys[i]}.`);
            if (text) {
                list.push(text);
            }
        }
        if (list.length < finalDocument.autonumbered_lists[keys[i]]) {
            warnings.push(`lista_${keys[i]} tiene menos elementos que las apariciones en el documento. AÃ±ada mÃ¡s elementos o revise ` +
            `los "@if" y "@repeat" para asegurarse que  no se puede quedar sin elementos`);
        }
        result[keys[i]] = list;
    }
    finalDocument.texts_lists = result;
}

// endregion


// region sections
function parseSections(sections: { seccion: Seccion[]}, finalDocument: LegalDocument, errors: string[]) {
    if (!sections) {
        errors.push('Falta campo secciones.');
        return [];
    }
    const array: Seccion[] = getArrayXML(sections.seccion);
    if (array.length < 1) {
        errors.push('Faltan campos seccion en secciones.');
        return [];
    }
    const result: DocType[] = [];
    const documentSectionsIds: number[] = finalDocument.sections.map((sec) => sec.document_id);
    for (let i = 0; i < array.length; i++) {

        const numberId: number = parseIndividualSection(array[i], finalDocument, errors);

        if (numberId) {
            const index = documentSectionsIds.indexOf(numberId);
            if (index > -1) {
                documentSectionsIds.splice(index, 1);
            }
        }
    }

    assertParser(documentSectionsIds.length > 0, errors, `Secciones ${documentSectionsIds.join(', ')} no definidas`);
}



function parseIndividualSection(section: Seccion, finalDocument: LegalDocument, errors: string[]): number {
    if (assertParser(!section.attr || !section.attr.id, errors, 'Las secciones deben tener campo id numerico')) {
        return undefined;
    }
    const id: number = parsePositiveInt(section.attr.id, errors, 'seccion id');
    if (id < 0) {
        return undefined;
    }
    const docSection: Section = getSectionOrFieldById(finalDocument.sections, id);
    if (assertParser(!docSection, errors, `No existe seccion con id ${id} en el documento`)) {
        return undefined;
    }
    docSection.name = parseGenericText(section.titulo, errors, `titulo en seccion ${id}`);

    if (section.descripcion) {
        docSection.description = parseGenericText(section.descripcion, errors, `descripcion en seccion ${id}`);
    }
    parseFields(section.campos, docSection, id, finalDocument.conditions, errors);
    return id;
}

// endregion

// region Fields

function parseFields(fields: { campo: Campo[]; },  section: Section, sectionId: number, conditions: Condition[],  errors: string[]) {
    if (!fields) {
        errors.push(`Faltan campos en seccion ${sectionId}.`);
        return [];
    }
    const array: Campo[] = getArrayXML(fields.campo);
    if (array.length < 1) {
        errors.push(`Faltan campos en seccion ${sectionId}.`);
        return [];
    }
    const result: DocType[] = [];
    const documentFieldsIds: number[] = section.fields.map((fil) => fil.document_id);
    for (let i = 0; i < array.length; i++) {
        const numberId: number = parseIndividualField(array[i], section, conditions,  errors);
        if (numberId) {
            const index = documentFieldsIds.indexOf(numberId);
            if (index > -1) {
                documentFieldsIds.splice(index, 1);
            }
        }
    }

    assertParser(documentFieldsIds.length > 0, errors,
        `En seccion ${section.document_id} los campos ${documentFieldsIds.join(', ')} no estan definidos.`);

}

function parseIndividualField(field: Campo, section: Section, conditions: Condition[], errors: string[]): number {
    if (assertParser(!field, errors,
        `En seccion ${section.document_id} faltan campos.`)) {
        return undefined;
    }
    if (assertParser(!field.attr || !field.attr.id, errors,
        `En seccion ${section.document_id}: Los campos deben tener una id numerica.`)) {
        return undefined;
    }
    const id: number = parsePositiveInt(field.attr.id, errors, `campo id en seccion ${section.document_id}`);
    if (id < 0) {
        return undefined;
    }
    const docField: Field = getSectionOrFieldById(section.fields, id);
    if (assertParser(!docField, errors, `No existe campo con id ${id} en la seccion ${section.document_id} en el documento.`)) {
        return undefined;
    }
    docField.caption = parseGenericText(field.titulo, errors,
        `titulo en campo ${id} en seccion ${section.document_id}`);
    if (field.descripcion) {
        docField.description = parseGenericText(field.descripcion, errors,
            `descripcion en campo ${id} en seccion ${section.document_id}`);
    }
    docField.repeatable = parseGenericBoolean(field.repetible, errors,
        `repetible en campo ${id} en seccion ${section.document_id}`, false);
    docField.required = parseGenericBoolean(field.obligatorio, errors,
        `obligatorio en campo ${id} en seccion ${section.document_id}`, false);
    docField.type = parseFieldType(field.tipo, errors, `tipo en campo ${id} en seccion ${section.document_id}`);

    // opciones ----
    if (docField.type === FieldType.binary || docField.type === FieldType.single_list || docField.type === FieldType.multi_list) {
        docField.options = parseFieldOptions(field.opciones, errors, section.document_id, id);
        assertParser(docField.type === FieldType.binary && docField.options && docField.options.length !== 2, errors,
            `Tipo binario en campo ${id} en seccion ${section.document_id} debe que tener 2 opciones.`);

    } else if (field.tipo) {
        assertParser(!!field.opciones, errors,
            `Campo ${id} en seccion ${section.document_id} de tipo ${field.tipo} no` +
            ` puede tener campo opciones si no es de tipo binario, lista o lista multiple.`);
    }

    // Ayuda
    if (field.ayuda && (docField.type === FieldType.text_area || docField.type === FieldType.text)) {
        docField.hint = parseGenericText(field.ayuda, errors,
            `ayuda en campo ${id} en seccion ${section.document_id}`);
    } else if (field.tipo) {
        assertParser(!!field.ayuda, errors,
            `Campo ${id} en seccion ${section.document_id} de tipo ${field.tipo} no` +
            ` puede tener campo ayuda si no Ã©s de tipo texto o area texto.`);
    }

    const numConditions = getNumberConditions(docField.id, conditions);
    if (numConditions > 0 ) {
        // Condition fields are always required
        docField.required = true;
    }
    if (docField.repeatableBlocks && docField.repeatableBlocks.length > 0) {
        docField.repeatable = true;
    }

    assertParser(numConditions > 0 && docField.type !== FieldType.single_list && docField.type !== FieldType.multi_list, errors,
        `Campo ${id} en seccion ${section.document_id} debe ser de tipo Lista si en el documento esta definido como un campo condicional.`);
    assertParser(numConditions > 0 && docField.type === FieldType.single_list
        && docField.options && docField.options.length < numConditions, errors,
        `Opciones en campo ${id} en seccion ${section.document_id} debe contener al menos ${numConditions} opciones.` +
         `El numero de campos condicionales definidos en el documentos`);


    return id;
}


function getNumberConditions(fieldId: string, conditions: Condition[]): number {
    if (!conditions) {
        return 0;
    }
    const filteredConditions = conditions.filter((cond) => cond.field_id === fieldId);
    let concatList = [];
    let hasElse = false;
    for (let i = 0; i < filteredConditions.length; i++) {
        concatList = concatList.concat(filteredConditions[i].value);
        if (filteredConditions[i].operation === Operation.not_in) {
            hasElse = true;
        }
    }
    const len: number = removeDuplicates(concatList).length;
    return len + (hasElse ? 1 : 0);
}

function removeDuplicates(array: any[]): any[] {
    const a = [];
    for (let i = 0; i < array.length; i++) {
        if (a.indexOf(array[i]) < 0) {
            a.push(array[i]);
        }
    }
    return a;
}

function parseFieldType(text: string, errors: string[], variableName: string): FieldType {
    if (assertParser(!text, errors, `Campo ${variableName} no encontrado.`)) {
        return;
    }
    if (assertParser(Array.isArray(text), errors, `Campo ${variableName} repetido.`)) {
        return;
    }
    if (assertParser(!text, errors, `Campo ${variableName} no encontrado.`)) {
        return;
    }
    if (assertParser(typeof text !== 'string', errors, `Campo ${variableName} no valido.`)) {
        return;
    }

    const clean = text.toLowerCase().replace(/ /g, '');
    switch (clean) {
        case 'binario':
            return FieldType.binary;
        case 'texto':
            return FieldType.text;
        case 'areatexto':
        case 'Ã¡reatexto':
            return FieldType.text_area;
        case 'numero':
        case 'nÃºmero':
            return FieldType.number;
        case 'fecha':
            return FieldType.date;
        case 'lista':
            return FieldType.single_list;
        case 'listamultiple':
        case 'listamÃºltiple':
            return FieldType.multi_list;
        default:
    }
    assertParser(true, errors,
        `Campo ${variableName} debe contener binario, texto, area texto, numero, fecha, listao lista multiple.`);
    return undefined;
}




function parseFieldOptions(values: {opcion: any}, errors: string[], sectionId: number, fieldId: number): string[] {
    if (!values) {
        errors.push(`Faltan opciones en campo ${fieldId} en seccion ${sectionId}.`);
        return undefined;
    }
    const array = getArrayXML(values.opcion);
    if (array.length < 2) {
        errors.push(`Faltan opciones en campo ${fieldId} en seccion ${sectionId}. Minimo 2 opciones.`);
        return undefined;
    }
    const result = [];
    let text;
    for (let i = 0; i < array.length; i++) {
        text = parseGenericText(array[i], errors, `opcion en campo ${fieldId} en seccion ${sectionId}`);
        if (text) {
            if (result.indexOf(text) > -1) {
                errors.push(`opcion ${text} repetida en campo ${fieldId} en seccion ${sectionId}.`);
            } else {
                result.push(text);
            }
        }
    }
    return result;

}


// endregion

// region Sign

function parseSignature(signatures: Firma, finalDocument: LegalDocument, errors: string[]) {
    if (!signatures || Object.keys(signatures).length < 1) {
        finalDocument.num_signatures = 0;
        return;
    } else if (signatures.numero_fijo && (!signatures.variables || !signatures.variables.campo)) {
        const numSignatures = parsePositiveInt(signatures.numero_fijo, errors, ` numero fijo en firma`);
        finalDocument.num_signatures = numSignatures;
        return;
    }
    finalDocument.num_signatures = -1;
    const numBaseSignatures = parsePositiveInt(signatures.numero_fijo, errors, ` numero fijo en firma`);
    finalDocument.base_num_signatures = numBaseSignatures;
    finalDocument.signature_num_field_id = getSignatureFields(signatures.variables, finalDocument, errors);
}


function getSignatureFields(fieldsSigns: {campo: string[]}, finalDocument: LegalDocument, errors: string[]): string[] {
    const array = getArrayXML(fieldsSigns.campo);
    if (array.length < 1) {
        errors.push('Campo en variables firma no encontrado.');
    }
    const results = [];
    let id;
    for (let i = 0; i < array.length; i++) {
        id = getFieldIdSignature(array[i], finalDocument, errors);
        if (id && results.indexOf(id) < 0) {
            results.push(id);
        } else if (id) {
            errors.push(`Campo variable ${array[i]} en firma repetido.`);
        }
    }
    return results;
}

function getFieldIdSignature(value: string, finalDocument: LegalDocument, errors: string[]): string {
    if (assertParser(!value || typeof value !== 'string', errors,
        `Campo variable ${value} en firma no valido tiene que tener formato '{id seccion: id campo}'.`)) {
        return;
    }

    const cleaned =  value.replace(/ /g, '');
    if (!/^{[0-9]+:[0-9]+}?$/.test(cleaned)) {
        errors.push(`Campo variable ${value} en firma no valido tiene que tener formato '{id seccion: id campo}'.`);
    }
    const splited: string[] = cleaned.replace(/{/g, '').replace(/}/g, '').split(':');
    const sectionId: number = parsePositiveInt(splited[0], errors, `seccion de firma variable ${value}`);
    const fieldId: number = parsePositiveInt(splited[1], errors, `campo de firma variable ${value}`);
    if (sectionId < 0 || fieldId < 0) {
        return;
    }
    const section: Section = getSectionOrFieldById(finalDocument.sections, sectionId);
    if (!section) {
        errors.push(`${sectionId} seccion en campo variable ${value} firma no existe.`);
    }
    const field: Field = getSectionOrFieldById(section.fields, fieldId);
    if (!field) {
        errors.push(`Campo  ${fieldId} en campo variable ${value} firma no existe.`);
        return;
    }
    //TODO remove to enable if fields to be in 
    if (!field.repeatable) {
        errors.push(`Campo  ${fieldId} en campo variable ${value} debe ser repetible para firma variable. Si no incluirlo en firma base`);
        return;
    }
    return field.id;
}

// endregion

// region general utilities


function getArrayXML(value: any) {
    if (!value) {
        return [];
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
}

function parseFloat2Decimal(value: string, errors: string[], variableName: string): number {
    const patt = new RegExp('^[0-9]+(\\.[0-9][0-9]|\\.[0-9])?$');
    if (patt.test(value)) {
        return parseFloat(value);
    } else {
        const patt2 = new RegExp('^[0-9]+(\\.[0-9]*)?$');
        if (patt2) {
            errors.push(`Campo ${variableName} debe ser un numero de maximo 2 decimales`);
        } else {
            if (value.startsWith('-')) {
                errors.push(`Campo ${variableName} debe ser un numero positivo`);
            } else {
                errors.push(`Campo ${variableName} debe ser un numero vÃ¡lido`);
            }

        }
    }
    return -1;
}
function parsePositiveInt(value: string, errors: string[], variableName: string): number {
    const patt = new RegExp('^[0-9]*$');
    if (patt.test(value)) {
        return parseInt(value, 10);
    } else {

        if (value.startsWith('-')) {
            errors.push(`Campo ${variableName} debe ser un numero positivo`);
        } else {
            errors.push(`Campo ${variableName} debe ser un numero vÃ¡lido`);
        }
    }
    return -1;
}

function parseGenericText(text: string, errors: string[], variableName: string): string {
    if (assertParser(!text, errors, `Campo ${variableName} no encontrado.`)) {
        return;
    }
    if (assertParser(Array.isArray(text), errors, `Campo ${variableName} repetido.`)) {
        return;
    }
    text = text + '';
    assertParser(text.length < 1, errors, `Campo ${variableName} vacio.`);
    const cleaned = text.replace(/\t/g, '');
    return cleaned;
}

function parseGenericBoolean(text: string, errors: string[], variableName: string, defaultValue?: boolean): boolean {

    if (assertParser(!text && defaultValue === undefined, errors, `Campo ${variableName} no encontrado.`)) {
        return;
    } else if (!text) {
        return defaultValue;
    }
    if (assertParser(Array.isArray(text), errors, `Campo ${variableName} repetido.`)) {
        return;
    }
    if (assertParser(typeof text !== 'string', errors, `Campo ${variableName} no valido.`)) {
        return;
    }
    const clean = text.toLowerCase().replace(/ /g, '');
    switch (clean) {
        case 'si':
        case 'sÃ­':
            return true;
        case 'no':
            return false;
        default:
    }
    assertParser(true, errors, `Campo ${variableName} debe contener SI o NO.`);
    return undefined;
}

function assertParser(condition: boolean, errors: string[], message: string): boolean {
    if (condition) {
        errors.push(message);
    }
    return condition;
}

function getSectionOrFieldById(array: Section[] | Field[], id: number): any {
    for (let i = 0; i < array.length; i++) {
        if (array[i].document_id === id) {
            return array[i];
        }
    }
    return undefined;
}

function isInt(n) {
    return Number(n) === n && n % 1 === 0;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

// endregion
