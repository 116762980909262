import {Injectable} from '@angular/core';
import * as firebase from 'firebase';
import {Parser} from 'json2csv';
import {
    CompletedTransaction,
    CompletedTransactionFeed,
    FeedInterface,
    PublicLegalDocument,
    PermissionGroup,
    Permissions,
    Profile,
    ProfileFeed,
    BasicPlatform,
    PurchasedPack,
    BasicUserInterface,
    setAsPlatformManager,
    removeAsPlatformManager,
    getManagerPlatformsList,
    getAllManagersIds
} from 'milcontratos-database';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {CloudFunctionsService} from '../shared/cloud-functions.service';
import {DocumentsService} from '../client/documents.service';
import {AngularFireDatabase} from '@angular/fire/database';
import {isBoolean, isObject} from 'util';
import {validate, Validator} from 'class-validator';
import {PlatformsAdminService} from './platforms-admin.service';

@Injectable({
    providedIn: 'root'
})
export class UserProfileAdminService {

    constructor(private cloud: CloudFunctionsService,
                private docService: DocumentsService,
                private platformAdminService: PlatformsAdminService,
                private dbObs: AngularFireDatabase) { }

    public async listUsers( numUsers: number, cursor?: any, onlyRoot?: boolean, onlyRegularUsers?: boolean,
                            onlyHasPermissions?: boolean, byPermissionId?: string): Promise<ProfileFeed> {
        const result = await this.cloud.usersPaginationList(numUsers, cursor, onlyRoot,
            onlyRegularUsers, onlyHasPermissions, byPermissionId);
        return {
            users: result.users,
            cursor: result.cursor
        };
    }

    public listUserByPlatformObs( platformId: string): Observable<BasicUserInterface[]> {
        return new Observable((observer) => {
            this.listUserByPlatform(observer, platformId);
        });
    }

    private async listUserByPlatform(observer, platformId: string) {
        const defaultPlatform = BasicPlatform.fromJSON((await firebase.database().ref('platforms_default').once('value')).val());
        platformId = defaultPlatform.id === platformId ? null : platformId;
        const list: any[] = [];
        const snapshot = await firebase.database().ref('users').orderByChild('platform_id').equalTo(platformId).once('value');
        snapshot.forEach( (snpt) => {
            const body = {
                uid: snpt.key
            };
            const val = snpt.val();
            if (val['info']) {
                body['username'] = val['info']['username'];
                body['email'] = val['info']['email'];

            }
            body['disabled'] = !!val['is_disabled'];
            list.push(body);
            if ( list.length % 100 === 0 && list.length !== 0) {
                observer.next(list);
            }
        });
        observer.next(list);
        observer.complete();
    }

    public async  getPlatformFromUser(userId: string): Promise<BasicPlatform> {
        const snapshot = await firebase.database().ref(`users/${userId}/platform_id`).once('value');
        const platformId = snapshot.val();
        if (!platformId) {
            return await this.platformAdminService.getDefaultPlatform();
        }
        return await this.platformAdminService.getBasicPlatformById(platformId);
    }


    public async getPermissionsGroups(): Promise<PermissionGroup[]> {
        const snapshot = await firebase.database().ref('permission_groups').once('value');
        const permiss = snapshot.val();
        if (permiss) {
            return Object.keys(permiss).map((id) => {
                return PermissionGroup.fromJSON(permiss[id]);
            });
        } else {
            return [];
        }
    }


    public async setUserPermissions(permissionGroupId: string, userId: string) {
        const snapshot = await firebase.database().ref(`permission_groups/${permissionGroupId}`).once('value');
        if (!snapshot.val()) {
            throw {
                errors: ['Permission group not found']
            };
        }
        const permiss = PermissionGroup.fromJSON(snapshot.val());
        await firebase.database().ref(`users/${userId}/id_permission_group`).set(permiss.id);
        await firebase.database().ref(`users/${userId}/name_permission_group`).set(permiss.name);
        await firebase.database().ref(`users/${userId}/groups`).set(permiss.permissionToJson());
    }

    public async removeUserPermissions(userId: string) {
        await firebase.database().ref(`users/${userId}/id_permission_group`).set(null);
        await firebase.database().ref(`users/${userId}/name_permission_group`).set(null);
        await firebase.database().ref(`users/${userId}/groups`).set(null);
    }

    public async makeSuperuser(userId: string) {
        await firebase.database().ref(`users/${userId}/is_root`).set(true);
    }

    public async quitSuperuser(userId: string) {
        await firebase.database().ref(`users/${userId}/is_root`).set(null);
    }

    public async getUserPermissions(userId: string): Promise<{
        name?: string;
        id?: string;
        isRoot?: boolean,
        permissions?: Permissions[];
    }> {
        const snapshot_root = await firebase.database().ref(`users/${userId}/is_root`).once('value');

        if (snapshot_root.val()) {
            return {
                isRoot: true,
                name: 'Superuser',
                permissions: []
            };
        }
        const snapshot_name = await firebase.database().ref(`users/${userId}/name_permission_group`).once('value');
        const snapshot_id = await firebase.database().ref(`users/${userId}/id_permission_group`).once('value');
        const snapshot_groups = await firebase.database().ref(`users/${userId}/groups`).once('value');
        if (!snapshot_name.val()) {
            return undefined;
        }
        return {
            isRoot: false,
            id: snapshot_id.val(),
            name: snapshot_name.val(),
            permissions: PermissionGroup.permissionFromJson(snapshot_groups.val())
        };
    }

    public getUserPermissionsObs(userId: string): Observable<{
        isRoot?: boolean;
        id?: string;
        name?: string;
        permissions?: Permissions[];
    }> {
        return this.dbObs.object(`users/${userId}`).snapshotChanges()
            .pipe(map(value => {
                const val: any = value.payload.val();
                if (!val) {
                    return {};
                }
                if (val['is_root']) {
                    return {
                        isRoot: true,
                        id: 'Superuser',
                        name: 'Superuser',
                        permissions: []
                    };
                }
                const name = val['name_permission_group'];
                if (!name) {
                    return {};
                }
                return {
                    isRoot: false,
                    id: val['id_permission_group'],
                    name:  name,
                    permissions: PermissionGroup.permissionFromJson(val['groups'])
                };
            }));
    }


    public async freeDocumentPurchase(userId: string, documentId: string, legalAdvice?: boolean) {
        await this.cloud.giftDocument(documentId, userId, legalAdvice);
    }

    public async freePackPurchase(userId: string, packId: string) {
        await this.cloud.giftPack(packId, userId);
    }

    public async freeAllDocumentPurchase(userId: string) {
        await this.cloud.giftAllDocuments(userId);
    }

    public async getBasicProfile(userId: string): Promise<Profile> {
        const result = await this.cloud.getIndividualUser(userId);
        return Profile.fromJSON(result);
    }

    public async getUserOwnedDocuments(userId: string, numDocuments: number, cursor?: any): Promise<FeedInterface> {
        const ref = firebase.database().ref('public_documents_owned/' + userId );
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByKey().startAt(cursor).limitToFirst(numDocuments + 1).once('value');
        } else {
            snapshot = await ref.orderByKey().limitToFirst(numDocuments).once('value');
        }
        const waitingCalls: Promise<PublicLegalDocument>[] = [];
        snapshot.forEach(snpsht => {
            if (snpsht.val()) {
                waitingCalls.push(this.docService.getPublicDocumentById(snpsht.val()['id']));
            }
        });
        const feed: PublicLegalDocument[] = (await Promise.all(waitingCalls)).filter((f) => !!f);
        return {
            cursor: feed[feed.length - 1] ? feed[feed.length - 1].id : undefined,
            documents: cursor ?  feed.slice(1, numDocuments + 1) : feed
        };
    }

    public async getUserAvailablePacks(userId: string): Promise<PurchasedPack[]> {
        const snapshot = await firebase.database().ref('available_packs/' + userId).once('value');
        const packs = snapshot.val();
        const now = new Date();
        if (packs) {
            return Object.keys(packs).map((id) => {
                return PurchasedPack.fromJSON(packs[id]);
            }).filter((pack) => pack.expirationDate.getTime() > now.getTime());
        } else {
            return [];
        }
    }

    public async getUserTransactions(userId: string, numTransaction: number, cursor?: any): Promise<CompletedTransactionFeed> {
        const ref = firebase.database().ref('completed_transactions/' + userId );
        let snapshot;
        if (cursor) {
            snapshot = await ref.orderByChild('date').startAt(cursor).limitToFirst(numTransaction + 1).once('value');
        } else {
            snapshot = await ref.orderByChild('date').limitToFirst(numTransaction).once('value');
        }

        const feed: CompletedTransaction[] = [];
        let newCursor = cursor;
        snapshot.forEach(snpsht => {
            newCursor = snpsht.val()['date'];
            feed.push(CompletedTransaction.fromJSON(snpsht.val()));
        });

        return {
            cursor: newCursor,
            transactions: cursor ?  feed.slice(1, numTransaction + 1) : feed
        };
    }

    public async toggleActiveUser(userId: string, disabled: boolean): Promise<void> {
        await this.cloud.usersToggleActiveUser(userId, disabled);
    }

    public async addUser(name: string, email: string, password: string, platformId: string, emailVerified?: boolean): Promise<void> {
        await this.cloud.usersAddUser(name, email, password, platformId, emailVerified);
    }

    public async addUsersFromFile(platformId: string, file: File): Promise<void> {
        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (res: any) => {
                fetch(res.target.result)
                    .then(response => {
                        return response.json();
                    })
                    .then(async data => {
                        try {
                            console.log(data);

                            if (!Array.isArray(data)) {
                                reject('It\'s not an array of json');
                                return;
                            }
                            for (let i = 0; i < data.length; i++) {
                                const elem = data[i];
                                if (!isObject(elem)) {
                                    reject(`Not valid JSON in index ${i}`);
                                    return;
                                }
                                if (!elem['name'] || !elem['email'] || !elem['password'] || !isBoolean(elem['verified'])) {
                                    reject(`Not valid JSON in index  ${i}: \n ${JSON.stringify(elem)}`);
                                    return;
                                }
                                try {
                                    const validator = new Validator();
                                    if (!validator.isEmail(elem['email'])) {
                                        reject(`Not valid email ${elem['email']} in index  ${i}: \\n ${JSON.stringify(elem)}`);
                                        return;
                                    }
                                    await this.addUser(elem['name'], elem['email'], elem['password'], platformId, elem['verified']);
                                } catch (e) {
                                    reject(`Not valid JSON in index ${i}:\n${JSON.stringify(elem)} ${isObject(e) ? JSON.stringify(e) : e}`);
                                    return;
                                }
                            }
                            console.log(data);
                            resolve();
                        } catch (e) {
                            reject(e);
                            return;
                        }
                    })
                    .catch(err => {
                        reject(err);
                    });

            };

            reader.readAsDataURL(file);
        });
    }

    public async getPlatformManagerPermision(userId: string): Promise<BasicPlatform[]> {
        return await getManagerPlatformsList(userId);
    }

    public async getPlatformManagers(platformId: string): Promise<BasicUserInterface[]> {
        const ids = await getAllManagersIds(platformId);
        const fun = async (id) => {
            const snapshot = await firebase.database().ref(`users/${id}`).once('value');
            const val = snapshot.val();
            const body = {
                uid: id
            };
            if (val['info']) {
                body['username'] = val['info']['username'];
                body['email'] = val['info']['email'];
            }
            body['disabled'] = !!val['is_disabled'];
            return body;
        };
        const promises = [];
        ids.forEach( (id) => {
            promises.push(fun(id));
        });
        return await Promise.all(promises);
    }

    public async addPlatformManagerPermision(userId: string, platform: BasicPlatform): Promise<void> {
        await setAsPlatformManager(platform, userId);
    }

    public async removePlatformManagerPermision(userId: string, platformId: string): Promise<void> {
        await removeAsPlatformManager(platformId, userId);
    }

    public async getUsersCsv(platformId: string): Promise<string> {
        const allJsons = [];
        const users = await this.listUserInfoByPlatform(platformId);
        users.forEach((user) => {
            allJsons.push({
                'id_usuario': user.uid,
                'nombre': user.username,
                'email': user.email,
                'plataforma': user.platform,
                'permisos': user.permissionGroup,
                'acepta_comunicaciones_comerciales': user.acceptsAdvertise ? 'Si' : 'No',
                'deshabilitado': user.disabled ? 'Si' : 'No',
            });
        });

        const fields = ['id_usuario', 'nombre', 'email', 'plataforma', 'permisos', 'acepta_comunicaciones_comerciales', 'deshabilitado'];
        const json2csvParser = new Parser({ fields , delimiter: ';', flatten: true});
        const csv = json2csvParser.parse(allJsons);

        return csv;
    }

    private async listUserInfoByPlatform(platformId: string) {
        const defaultPlatform = BasicPlatform.fromJSON((await firebase.database().ref('platforms_default').once('value')).val());
        platformId = defaultPlatform.id === platformId ? null : platformId;
        const snapshot = await firebase.database().ref('users').orderByChild('platform_id').equalTo(platformId).once('value');
        const promises = [];
        snapshot.forEach((snpt) => {
            promises.push(new Promise(async (resolve, reject) => {
                try {
                    const body = {
                        uid: snpt.key
                    };
                    const val = snpt.val();
                    if (val['info']) {
                        body['username'] = val['info']['username'];
                        body['email'] = val['info']['email'];
                    }
                    body['disabled'] = !!val['is_disabled'];
                    body['acceptsAdvertise'] = !!val['accepts_advertise'];
                    body['permissionGroup'] = val['name_permission_group'];
                    let userPlatform;
                    try {
                        userPlatform = await this.getPlatformFromUser(snpt.key);
                    } catch (e) {
                    }
                    if (userPlatform) {
                        body['platform'] = userPlatform.name;
                    }
                    resolve(body);
                } catch (e) {
                    reject(e);
                }
            }));
        });

        return await Promise.all(promises);
    }
}
