import {Injectable} from '@angular/core';
import {Deal, getDealPlatfrom, paginatedDealsPlatform, setDealPlatfrom} from 'milcontratos-database';




@Injectable({
    providedIn: 'root'
})
export class DealAdminService {

    constructor() {
    }


    async getDeal(platformId: string, text: string): Promise<Deal> {
        return await getDealPlatfrom(platformId, text);
    }

    async editDeal(platformId: string, deal: Deal) {
        await setDealPlatfrom(platformId, deal);
    }

    async createDeal(platformId: string, deal: Deal) {
        const existenDeal = await getDealPlatfrom(platformId, deal.textId);
        if (existenDeal) {
            throw {
              errors: ['Already exists']
            };
        }
        await setDealPlatfrom(platformId, deal);
    }

    async paginatedDealsByPlatform(platformId: string, numDeals: number, cursor?: any): Promise<{deals: Deal[]; cursor: any;}> {
        return await paginatedDealsPlatform(platformId, numDeals, cursor);
    }

}
