import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FieldErrorService {

    private _errorIds: Subject<{fieldId: string, repeatIndex: number}[]> = new Subject();
    listErrors: {fieldId: string, repeatIndex: number}[] = [];

    constructor() { }

    /**
     * Observable that will emit the field ids with error every time it changes.
     *
     */
    errorIdsObservable(): Observable<{fieldId: string, repeatIndex: number}[]> {
        return this._errorIds.asObservable();
    }

    /**
     * Function used to add fields that have an error.
     */
    addErrorField(fieldId: string, repeatIndex: number) {
        console.log('addErrorField', fieldId, repeatIndex);
        if (!this.hasError(fieldId, repeatIndex)) {
            this.listErrors.push({
                fieldId: fieldId,
                repeatIndex: repeatIndex
            });
            this._errorIds.next(this.listErrors.slice());
        }
    }

    /**
     * Returns true if the given repetition of the given field has an error and false otherwise.
     */
    hasError(fieldId: string, repeatIndex: number): boolean {
        return this.listErrors.findIndex((err) => err.fieldId === fieldId && err.repeatIndex === repeatIndex) >= 0;
    }

    /**
     * Function used to delete fields that have an error.
     */
    deleteErrorField(fieldId: string, repeatIndex: number) {
        const index = this.listErrors.findIndex((err) => err.fieldId === fieldId && err.repeatIndex === repeatIndex);
        if (index > -1) {
            this.listErrors.splice(index, 1);
        }
        this._errorIds.next(this.listErrors.slice());
    }

}
