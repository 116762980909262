export const removeEmpty = (obj) => {
    Object.keys(obj).forEach(key =>
        (obj[key] && typeof obj[key] === 'object') && removeEmpty(obj[key]) ||
        (obj[key] === undefined) && delete obj[key]
    );
    return obj;
};



export function throwNotImplemented() {
    throw {
        error: 'Not implemented'
    };
}


export function hasDatabaseForbbidenCharacter(text: string) {
    return text.indexOf('/') > -1
        || text.indexOf('.') > -1
        || text.indexOf('#') > -1
        || text.indexOf('$') > -1
        || text.indexOf('[') > -1
        || text.indexOf(']') > -1;
}
