export const Languages = {
    'es': {
        language: 'EspaÃ±ol',
        flag: 'es'
    },
    'ca-ES': {
        language: 'CatalÃ¡n',
        flag: 'es-ct'
    },
    'zh-Hans': {
        language: 'Chino',
        flag: 'cn'
    },
    'en-GB': {
        language: 'InglÃ©s',
        flag: 'gb'
    },
    'de-DE': {
        language: 'AlemÃ¡n',
        flag: 'de'
    },
    'fr-FR': {
        language: 'FrancÃ©s',
        flag: 'fr'
    },
    'pt-PT': {
        language: 'PortuguÃ©s',
        flag: 'pt'
    },
    'vi-VN': {
        language: 'Vietnamita',
        flag: 'vn'
    },
    'ru-RU': {
        language: 'Ruso',
        flag: 'ru'
    },
    'ar-MA': {
        language: 'Ãrabe',
        flag: 'sa'
    },
    'eu-ES': {
        language: 'Euskera',
        flag: 'es-eu'
    },
    'gl-ES': {
        language: 'Gallego',
        flag: 'es-gl'
    },
    'it-IT': {
        language: 'Italiano',
        flag: 'it'
    },
    'et-EE': {
        language: 'Estonio',
        flag: 'ee'
    },
    'bg-BG': {
        language: 'BÃºlgaro',
        flag: 'bg'
    },
    'cs-CZ': {
        language: 'Checo',
        flag: 'cz'
    },
    'da-DK': {
        language: 'DanÃ©s',
        flag: 'dk'
    },
    'sk-SK': {
        language: 'Eslovaco',
        flag: 'sk'
    },
    'sl-Sl': {
        language: 'Esloveno',
        flag: 'si'
    },
    'fi-FI': {
        language: 'FinlandÃ©s',
        flag: 'fi'
    },
    'el-GR': {
        language: 'Griego',
        flag: 'gr'
    },
    'hu-HU': {
        language: 'HÃºngaro',
        flag: 'hu'
    },
    'ga-IE': {
        language: 'IrlandÃ©s',
        flag: 'ie'
    },
    'lv-LV': {
        language: 'LetÃ³n',
        flag: 'lv'
    },
    'lt-LT': {
        language: 'Lituano',
        flag: 'lt'
    },
    'mt-MT': {
        language: 'MaltÃ©s',
        flag: 'mt'
    },
    'nl-NL': {
        language: 'NeerlandÃ©s',
        flag: 'nl'
    },
    'pl-pl': {
        language: 'Polaco',
        flag: 'pl'
    },
    'ro-RO': {
        language: 'Rumano',
        flag: 'ro'
    },
    'hr-HR': {
        language: 'Croata',
        flag: 'hr'
    },
    'sv-SE': {
        language: 'Sueco',
        flag: 'se'
    },
    'hi-IN': {
        language: 'Hindi',
        flag: 'in'
    },
    'ja-JP': {
        language: 'JaponÃ©s',
        flag: 'jp'
    },
    'ko-KR': {
        language: 'Coreano',
        flag: 'kr'
    },
    'fil-PH': {
        language: 'Tagalog',
        flag: 'ph'
    },
    'he-IL': {
        language: 'Hebreo',
        flag: 'il'
    },
    'kk-KZ': {
        language: 'Kazajo',
        flag: 'kz'
    },
    'hy-AM': {
        language: 'Armenio',
        flag: 'am'
    },
};
